// .core
import React from 'react'
// components
import { getTranslation } from 'components'
import { IInputDefaultProps } from './InputDefault'
// formik
import { Field, useField, useFormikContext } from 'formik'
import { get } from 'lodash'
import { Input } from '../Input'

// styles
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

interface IInputDatepickerProps extends Omit<IInputDefaultProps<[Date, Date]>, 'name'> {
  name: string
  /**
   * Name of the "start date" input
   */
  startInputName: string
  /**
   * Name of the "end date" input
   */
  endInputName: string
}

export const InputDatepickerRange = ({
  colorScheme,
  endInputName,
  label,
  name,
  startInputName,
  ...props
}: IInputDatepickerProps) => {
  const [fieldStart] = useField(startInputName)
  const [fieldEnd] = useField(endInputName)
  const { errors, setFieldValue } = useFormikContext()

  return (
    <Field name={name} {...props}>
      {() => (
        <div className="space-y-2">
          <Input.Date
            colorScheme={colorScheme}
            error={get(errors, startInputName)}
            label={getTranslation('general.label.date_from')}
            name={startInputName}
            onChange={date => {
              setFieldValue(name, [date, fieldEnd.value])
            }}
          />

          <Input.Date
            colorScheme={colorScheme}
            error={get(errors, endInputName)}
            label={getTranslation('general.label.date_to')}
            name={endInputName}
            onChange={date => {
              setFieldValue(name, [fieldStart.value, date])
            }}
          />
        </div>
      )}
    </Field>
  )
}

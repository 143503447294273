// core
import React from 'react'
// components
import { IDefaultWrapperProps, Loader } from 'components'
// libraries
import cx from 'classnames'

export interface ICardProps extends IDefaultWrapperProps {
  /**
   * Wheather the `Card` has dark background - used in create/edit forms within `Columns`
   */
  isDark?: boolean
  /**
   * Whether the `Card` content is loading
   */
  isLoading?: boolean
}

/**
 * Wrappper component for rendering card-like containers with shadow, round corners & NO padding
 */
export const Card = ({ children, className, isDark, isLoading }: ICardProps) => (
  <Loader.Wrapper className="min-h-20" isLoading={isLoading}>
    <div className={cx('relative', isDark ? 'cardDark' : 'card', className)}>{children}</div>
  </Loader.Wrapper>
)

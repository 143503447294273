// core
import React, { ReactNode } from 'react'
// components
import { Button, Icon, IDefaultProps, TIconName } from 'components'
import { IButtonDefaultProps } from 'components/Button/variants/ButtonDefault'
// libraries
import cx from 'classnames'

export interface ICalloutDefaultProps extends IDefaultProps {
  /**
   * If provided, will be rendered below message
   */
  buttons?: IButtonDefaultProps[]
  /**
   * Icon to be rendered above header
   *
   * @default 'times'
   */
  icon?: TIconName
  /**
   * Title of callout
   */
  title?: ReactNode
  /**
   * Message below title
   */
  message?: ReactNode
}

export const CalloutDefault = ({
  buttons,
  className,
  icon = 'times',
  title,
  message,
}: ICalloutDefaultProps) => {
  return (
    <div
      className={cx(
        'flex flex-col space-y-1.5 items-center justify-center w-full h-full text-black',
        className
      )}>
      <Icon className="text-gray-300" name={icon} size="3x" />
      <p className="font-semibold text-xl text-center text-gray-600">{title}</p>
      {message && <span className="block w-10 h-0.5 bg-primary rounded" />}
      <p className="text-base text-center text-gray-400">{message}</p>
      {buttons?.map((btnProps, index) => (
        <Button key={index} {...btnProps} />
      ))}
    </div>
  )
}

// core
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SettingsQueries } from 'api/Settings/SettingsQueries'
import { GetSetting, GetSettingVariables } from 'api/Settings/types/GetSetting'
// API
import { UserQueries } from 'api/User/UserQueries'
import { GetUserNotifications } from 'api/User/types/GetUserNotifications'
import { SideMenuQueries } from 'api/SideMenu/SideMenuQueries'
import { GetSysMenuItems } from 'api/SideMenu/types/GetSysMenuItems'
import {
  AddSysUserFirebaseToken,
  AddSysUserFirebaseTokenVariables,
} from 'api/User/types/AddSysUserFirebaseToken'
import { UserMutations } from 'api/User/UserMutations'
// components
import {
  Content,
  Header,
  Loader,
  MenuIcon,
  SideMenu,
  Toast,
  TranslationDialog,
  TUserMenuTabValue,
  UserMenu,
} from 'components'
import { Router } from './Router'
// libraries
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import cx from 'classnames'
// utils
import {
  EKeyCodes,
  IViewport,
  PageTitleContext,
  SearchContext,
  TranslationKeys,
  useSearchProvider,
  useWindowWidth,
} from 'utils'
// styles
import css from './Layout.module.scss'
// index
import { LoggedInUserContext } from '../index'

export type TSidePanelTypes = 'mainMenu' | 'userMenu'

export const SidePanelContext = createContext<{
  currentlyOpenedSidePanel: TSidePanelTypes | null
  openSidePanel: (menu: TSidePanelTypes | null) => any
}>({
  currentlyOpenedSidePanel: null,
  openSidePanel: () => {
    // do nothing
  },
})

export interface ITranslationContext {
  keyValue?: TranslationKeys | ''
  openTranslationDialog: (keyValue: TranslationKeys) => void
  closeTranslationDialog: () => void
}
export const TranslationContext = createContext<ITranslationContext>({
  keyValue: undefined,
  openTranslationDialog: () => {
    // do nothing
  },
  closeTranslationDialog: () => {
    // do nothing
  },
})

export const ViewportContext = createContext<IViewport>({
  windowWidth: 0,
  isSmallerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
  isLargerThan: {
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  },
})

export function Layout() {
  const { loggedInUser } = useContext(LoggedInUserContext)
  const searchContextOptions = useSearchProvider()

  // ==================== State ====================
  const [tab, setTab] = useState<TUserMenuTabValue>('user')
  const [pageTitle, setPageTitle] = useState<string>()
  const [openedSidePanel, setOpenedSidePanel] = useState<TSidePanelTypes | null>(null)
  const [keyValueForTranslationDialog, setKeyValueForTranslationDialog] = useState<
    TranslationKeys | ''
  >()

  const viewportValues = useWindowWidth()

  // ==================== Queries ====================
  const { data } = useQuery<GetSysMenuItems>(SideMenuQueries.GET_SIDEMENU_ITEMS, {
    variables: { showAll: false },
  })

  const { data: dataPageTitle } = useQuery<GetSetting, GetSettingVariables>(
    SettingsQueries.GET_SETTING,
    { variables: { key: 'system_settings' } }
  )

  const { data: dataSetting } = useQuery<GetSetting, GetSettingVariables>(
    SettingsQueries.GET_SETTING,
    { variables: { key: 'notifications' } }
  )

  const notificationInterval =
    Number(
      dataSetting?.sysSetting.variables.find(setting => setting.key === 'notifications_interval')
        ?.value
    ) * 60000 || 300000

  const { data: dataNotifications } = useQuery<GetUserNotifications>(
    UserQueries.GET_USER_NOTIFICATIONS,
    {
      pollInterval: Number(notificationInterval),
      variables: { id: loggedInUser?.id },
    }
  )

  const unseenNotificationsCount = dataNotifications?.sysUser.notifications.filter(
    ({ seen }) => !seen
  ).length

  const [addFirebaseToken] = useMutation<AddSysUserFirebaseToken, AddSysUserFirebaseTokenVariables>(
    UserMutations.ADD_FIREBASE_TOKEN
  )

  const client = useApolloClient()
  const menuItems = data?.sysMenuItems || []

  // "componentDidMount"
  useEffect(() => {
    document.addEventListener('keydown', onRunKeyboardShortcut, false)

    return () => {
      document.removeEventListener('keydown', onRunKeyboardShortcut, false)
    }
  }, [openedSidePanel])

  useEffect(() => {
    if (dataPageTitle?.sysSetting) {
      setPageTitle(
        dataPageTitle.sysSetting.variables.find(setting => setting.key === 'page_title_suffix')
          ?.value
      )
    }
  }, [dataPageTitle?.sysSetting])

  useEffect(() => {
    if (loggedInUser) {
      client.writeQuery({
        data: { loggedInUserId: loggedInUser.id },
        query: gql`
          query {
            loggedInUserId
          }
        `,
      })
    }
  }, [client, loggedInUser])

  useEffect(() => {
    if (dataPageTitle?.sysSetting) {
      setPageTitle(
        dataPageTitle.sysSetting.variables.find(setting => setting.key === 'page_title_suffix')
          ?.value
      )
    }
  }, [dataPageTitle?.sysSetting])

  const sidePanelContextValues = useMemo(
    () => ({
      currentlyOpenedSidePanel: openedSidePanel,
      openSidePanel: setOpenedSidePanel,
    }),
    [openedSidePanel]
  )

  const translationContextValues: ITranslationContext = useMemo(
    () => ({
      keyValue: keyValueForTranslationDialog,
      openTranslationDialog: (keyValue: TranslationKeys) =>
        setKeyValueForTranslationDialog(keyValue),
      closeTranslationDialog: () => setKeyValueForTranslationDialog(undefined),
    }),
    [keyValueForTranslationDialog]
  )

  const closeAllMenus = useCallback(() => {
    setOpenedSidePanel(null)
  }, [])

  const isMenuOpened = openedSidePanel === 'mainMenu'
  const isUserMenuOpened = openedSidePanel === 'userMenu'

  const onRunKeyboardShortcut = useCallback(
    (e: KeyboardEvent) => {
      // Togggle TranslationDialog
      if (e.ctrlKey && e.key === EKeyCodes.T) {
        e.preventDefault()
        setKeyValueForTranslationDialog('')
      }

      // Toggle SideMenu
      if (e.key === EKeyCodes.SQUARE_BRACKET_LEFT) {
        setOpenedSidePanel(isMenuOpened ? null : 'mainMenu')
      }

      // Toggle UserMenu
      else if (e.key === EKeyCodes.SQUARE_BRACKET_RIGHT) {
        setOpenedSidePanel(isUserMenuOpened ? null : 'userMenu')
      }
    },
    [isMenuOpened, isUserMenuOpened]
  )

  const onTabSelect = useCallback((tab: TUserMenuTabValue) => {
    setTab(tab)
  }, [])

  return (
    <PageTitleContext.Provider
      value={{
        title: pageTitle,
      }}>
      <TranslationContext.Provider value={translationContextValues}>
        <SidePanelContext.Provider value={sidePanelContextValues}>
          <ViewportContext.Provider value={viewportValues}>
            <SearchContext.Provider value={searchContextOptions}>
              <Header notificationsCount={unseenNotificationsCount} tabSelect={onTabSelect} />

              <MenuIcon
                className={cx(
                  isMenuOpened ? 'text-white' : 'text-black',
                  '2xl:hidden dark:text-white'
                )}
                isMenuOpened={isMenuOpened}
                onClick={() => setOpenedSidePanel(isMenuOpened ? null : 'mainMenu')}
              />

              <Content>
                <div className={css.headerWrap}>
                  <SideMenu
                    isOpen={isMenuOpened}
                    menuItems={menuItems}
                    onRequestClose={closeAllMenus}
                  />

                  <UserMenu
                    dataNotifications={dataNotifications}
                    isOpen={isUserMenuOpened}
                    activeTab={tab}
                    onClose={closeAllMenus}
                    onTabChange={onTabSelect}
                  />
                </div>
                <React.Suspense fallback={<Loader.Fullscreen />}>
                  <Router routes={menuItems} />
                </React.Suspense>
              </Content>

              <Toast />

              {keyValueForTranslationDialog !== undefined && (
                <TranslationDialog
                  isShown
                  keyValue={keyValueForTranslationDialog as TranslationKeys}
                  onToggle={() => setKeyValueForTranslationDialog(undefined)}
                />
              )}

              {/* NOTE: this is used for select menu to apply query selector for createPortal  */}
              <div id="select-menu" className="z-55" />
            </SearchContext.Provider>
          </ViewportContext.Provider>
        </SidePanelContext.Provider>
      </TranslationContext.Provider>
    </PageTitleContext.Provider>
  )
}

// core
import React from 'react'
// components
import { InputCheckbox } from './variants/InputCheckbox'
import { InputDatepicker } from './variants/InputDatepicker'
import { InputDatepickerRange } from './variants/InputDatepickerRange'
import { InputDateTimePicker } from './variants/InputDateTime'
import { IInputDefaultProps, InputDefault } from './variants/InputDefault'
import { InputField } from './variants/InputField'
import { InputFile } from './variants/InputFile'
import { InputFileInline } from './variants/InputFileInline'
import { InputNumber } from './variants/InputNumber'
import { InputSearch } from './variants/InputSearch'
import { InputSearchHeader } from './variants/InputSearchHeader'
import { InputSelect } from './variants/InputSelect'
import { InputSwitch } from './variants/InputSwitch'
import { InputTextArea } from './variants/InputTextArea'
import { InputTimePicker } from './variants/InputTimePicker'
import { InputWithOptions } from './variants/InputWithOptions'

export class Input<T> extends React.Component<IInputDefaultProps<T>> {
  static Checkbox = InputCheckbox
  static Date = InputDatepicker
  static DateTime = InputDateTimePicker
  static DateRange = InputDatepickerRange
  static Field = InputField
  static File = InputFile
  static FileInline = InputFileInline
  static Number = InputNumber
  static Search = InputSearch
  static SearchHeader = InputSearchHeader
  static Select = InputSelect
  static Switch = InputSwitch
  static TextArea = InputTextArea
  static Time = InputTimePicker
  static WithOptions = InputWithOptions

  render() {
    return <InputDefault<T> {...this.props} />
  }
}

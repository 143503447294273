// core
import React, { useCallback, useMemo, useState } from 'react'
// API
import { DateFilter, FilterCategoryOps, FilterOps } from 'api/global-types'
// components
import { IDefaultProps, TKey } from 'components'
import { Backdrop } from 'components/Backdrop/Backdrop'
import { Badge } from 'components/Badge/Badge'
import { Button } from 'components/Button/Button'
import {
  ContextMenu,
  IContextMenuItemProps,
  IContextMenuProps,
} from 'components/ContextMenu/ContextMenu'
import { Icon } from 'components/Icon/Icon'
import { Input } from 'components/Input/Input'
import { Tag } from 'components/Tag/Tag'
import { getTranslation, Translation } from 'components/Translation/Translation'
import { SetActiveFilters } from './useFilter'
// libraries
import cx from 'classnames'
import { Form, Formik, FormikHelpers } from 'formik'
import moment from 'moment'
// utils
import { EDateFormats, TranslationKeys } from 'utils'

// @RN: maybe next time
export type TFilterValue = any // null | string | boolean | number | string[] | Date | DateFilterCategory[] // F[keyof F]

/**
 * Core config for table filters - every filter has to have these props
 */
interface ITableFilterCore<F> {
  /**
   * Key of the query variable representing the filter
   */
  id: keyof F
  /**
   * Whether the filter is disabled
   */
  isDisabled?: boolean
  /**
   * Label of the filter - shown in the 1st ContextMenu list and in the filter detail
   */
  label: TranslationKeys
  /**
   * Actual filter data which is also saved in the local storage
   */
  filter: TFilterValue
  /**
   * Usefull tooltip message to give more information about the filter (e.g.: why it's disabled)
   */
  tooltip?: TranslationKeys
}

/**
 * Interface for filter options when using filters of type `checkboxes || select`
 */
interface IFilterOption {
  id: string
  title: string
}

/**
 * Config for table filters that use `filter.options` prop
 */
interface ITableFilterWithOptions {
  type: 'checkboxes' | 'select'
  options: IFilterOption[]
}
/**
 * Config for table filters that use options prop
 */
interface ITableFilterWithoutOptions {
  type: 'date' | 'date-options' | 'switch'
}

/**
 * TableFilter props that are dependant on the `filter.type`
 */
type ITypeDependantTableFilters =
  // filters config when using checkboxes or selects - makes `options` madatory
  | ITableFilterWithOptions
  // any other filter config - doesn't support `options`
  | ITableFilterWithoutOptions

export type ITableFilter<F> = ITableFilterCore<F> & ITypeDependantTableFilters

interface ITableFilterProps<F> extends IDefaultProps {
  filters: ITableFilter<F>[]
  maxWidth?: number
  setFilters: SetActiveFilters<F>
}

export const TableFilters = <F,>({
  className,
  filters,
  maxWidth,
  setFilters,
}: ITableFilterProps<F>) => {
  // Whether the ContexMenu containing filters and their details is opened or not
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false)
  // Currently selected filter for editing (when a filter is already a applied and it's tag is clicked, this value gets assigned that filter's config)
  const [activeFilterDetail, setActiveFilterDetail] = useState<ITableFilter<F> | null>(null)

  const appliedFilters = useMemo(
    () => filters.filter(i => i.filter !== undefined && i.filter !== null),
    [filters]
  )

  const onAddFilter = useCallback(
    (filterKey: keyof F, filterData: TFilterValue) => {
      if (filterData.op) {
        setFilters({
          type: 'add',
          filterKey,
          filterData: [{ filters: [filterData], op: FilterCategoryOps.AND }],
        })
      } else {
        setFilters({ type: 'add', filterKey, filterData })
      }

      setActiveFilterDetail(null)
      onToggleFilterContextMenu(false)
    },
    [filters]
  )

  const onDeleteFilter = useCallback(
    (filterKey: keyof F) => () => {
      setActiveFilterDetail(null)
      setFilters({ type: 'remove', filterKey })
    },
    [filters]
  )

  const onResetAllFilters = useCallback(() => {
    setActiveFilterDetail(null)
    setFilters({ type: 'reset' })
  }, [filters])

  const onToggleFilterContextMenu = useCallback(
    (value: boolean) => () => {
      setIsContextMenuOpen(value)
    },
    [isContextMenuOpen, setIsContextMenuOpen]
  )

  const tagValue = (filter: ITableFilter<F>) => {
    if (filter.type === 'date') return moment(filter.filter).format(EDateFormats.DEFAULT)

    if (filter.type === 'date-options') {
      const dateFilter = filter.filter?.[0].filters?.[0]

      if (!dateFilter) return getTranslation('general.label.none')

      const op: FilterOps = dateFilter.op
      const parsedDate = moment(dateFilter.value).format(EDateFormats.DEFAULT)

      return `${op === FilterOps.GTE ? '>=' : op === FilterOps.LTE ? '<=' : '='} ${parsedDate}`
    }

    if (filter.type === 'select' || filter.type === 'checkboxes') {
      // If filter values in LS are an array
      if (Array.isArray(filter.filter)) {
        const filterTagValues: string[] = []

        filter.filter.map(filterValue => {
          const filterOptionIndexBasedOnSavedFilterValue = filter.options.findIndex(
            option => option.id === filterValue
          )
          if (filterOptionIndexBasedOnSavedFilterValue !== -1)
            filterTagValues.push(filter.options[filterOptionIndexBasedOnSavedFilterValue].title)
        })

        return filterTagValues.join(', ')
      }

      // If filter value in LS is a single value
      const filterOptionIndexBasedOnSavedFilterValue = filter.options.findIndex(
        option => option.id === filter.filter
      )

      if (filterOptionIndexBasedOnSavedFilterValue !== -1)
        return filter.options[filterOptionIndexBasedOnSavedFilterValue].title
    }

    if (filter.type === 'switch') {
      return filter.filter
        ? getTranslation('general.label.yes')
        : getTranslation('general.label.no')
    }

    return getTranslation('general.label.none')
  }

  return (
    <div style={{ width: maxWidth }} className={cx('flex ml-4', className)}>
      {/* FILTER ICON */}
      <span className={cx('group text-lg relative mt-1')}>
        {Boolean(appliedFilters.length) && (
          <Badge
            className="absolute -top-1 -right-1 ring ring-0.5 ring-inner ring-white z-5 cursor-pointer"
            label={appliedFilters.length}
            onClick={onToggleFilterContextMenu(true)}
          />
        )}

        <Icon
          className={cx(
            appliedFilters.length && 'text-primary',
            'text-gray-500 transform transition dark:text-gray-200  scale-90'
          )}
          name="filter"
          size="lg"
          type={appliedFilters.length ? 'solid' : 'regular'}
        />

        <FilterContextMenu
          activeFilterDetail={activeFilterDetail}
          className="text-sm"
          filters={filters}
          iconProps={null}
          isOpen={isContextMenuOpen}
          onClick={onToggleFilterContextMenu(true)}
          onClose={onToggleFilterContextMenu(false)}
          onSelectFilterDetail={setActiveFilterDetail}
          onApplyFilter={onAddFilter}
        />
      </span>

      <div className="ml-2 flex">
        {appliedFilters.map((filter, index) => (
          <Tag
            key={`filter-tag-${index}-${filter.id}`}
            //   ref={filterRef}
            className="z-10"
            label={`${getTranslation(filter.label)}: ${tagValue(filter)}`}
            onClick={() => {
              setActiveFilterDetail(filter)
              onToggleFilterContextMenu(true)
            }}
            onDeleteTag={onDeleteFilter(filter.id)}
          />
        ))}

        {/* CLEAR ALL FILTERS BTN */}
        {Boolean(appliedFilters.length) && (
          <Tag
            color="danger"
            label={getTranslation('general.action.close_all_filters')}
            onClick={onResetAllFilters}
          />
        )}
      </div>
    </div>
  )
}

interface IFilterMenuProps<F> extends Omit<IContextMenuProps, 'items'> {
  /**
   * Currently selected filter from the `ContextMenu` list
   */
  activeFilterDetail: ITableFilter<F> | null
  /**
   * List of all filters defined in a module and passed to the `Table`
   */
  filters: ITableFilter<F>[]
  /**
   * Event called whenever a filter is selected from the `ContextMenu` list
   * @param filterKey ID of a filter - ID defined in filters config in a module passed ot the `Table`
   */
  onSelectFilterDetail(filterKey: ITableFilter<F> | null): void
  /**
   * Event called when the filter's data are set from its detail
   * (checkboxes are ticked, options selected from selects or dates are set)
   * @param filterKey ID of a filter - ID defined in filters config in a module passed ot the `Table`
   * @param filterData data/values of the filter - whatever was entered in the inputs
   */
  onApplyFilter(filterKey: keyof F, filterData: TFilterValue): void
  /**
   * Callback to run after click on submit button
   */
  onSubmit?: (filterKey: keyof F, filterData: TFilterValue) => void
}

const FilterContextMenu = <F,>({
  activeFilterDetail,
  className,
  filters,
  onApplyFilter,
  onClose,
  onSelectFilterDetail,
  ...props
}: IFilterMenuProps<F>) => {
  const [checkedIds] = useState<TKey[]>([]) //TODO

  const initialValues = useMemo(() => {
    switch (activeFilterDetail?.type) {
      case 'date':
        return {
          value: activeFilterDetail.filter || new Date(),
        }

      case 'date-options':
        return {
          value: {
            value: new Date(),
            op: FilterOps.GTE,
          },
        } as DateFilter

      case 'checkboxes':
        return {
          value: activeFilterDetail.filter || [],
        }

      case 'switch':
        return {
          value: activeFilterDetail.filter || false,
        }

      case 'select':
      default:
        return {
          value: activeFilterDetail?.filter || '',
        }
    }
  }, [activeFilterDetail?.filter, activeFilterDetail?.type])

  const onCheck = useCallback(
    (id: TKey, value: boolean) => {
      const checkedRowIndex = checkedIds.indexOf(id)

      if (value && checkedRowIndex === -1) {
        checkedIds.push(id)
      } else {
        checkedIds.splice(checkedRowIndex, 1)
      }
    },
    [checkedIds]
  )

  const _onApplyFilter = useCallback(
    ({ value }: any, helpers?: FormikHelpers<any>) => {
      if (!activeFilterDetail) return

      onSelectFilterDetail(null)
      helpers?.resetForm()
      helpers?.setSubmitting(false)
      onApplyFilter(activeFilterDetail.id, value)
    },
    [activeFilterDetail, onApplyFilter]
  )

  const renderMenuChildren = useCallback(
    (values, setFieldValue) => {
      if (!activeFilterDetail) {
        return null
      }
      const { id, type } = activeFilterDetail

      switch (type) {
        case 'date':
          return <Input.Date id={id as string} name="value" />

        case 'date-options':
          return (
            <Input.Date
              hasOptions
              id={id as string}
              name="value.value"
              onChangeDateOption={val => {
                setFieldValue('value.op', val)
              }}
            />
          )

        case 'checkboxes':
          return Array.isArray((activeFilterDetail as ITableFilterWithOptions).options) ? (
            <div className="space-y-2">
              {(activeFilterDetail as ITableFilterWithOptions).options.map(
                (option: IFilterOption) => (
                  <Input.Checkbox
                    key={option.id}
                    id={option.id}
                    label={option.title}
                    value={checkedIds.includes(option.id)}
                    onChange={val => {
                      onCheck(option.id, val)
                      setFieldValue('value', [...checkedIds])
                    }}
                  />
                )
              )}
            </div>
          ) : null

        case 'select':
          return (
            <Input.Select
              label={getTranslation(activeFilterDetail.label)}
              name="value"
              options={(activeFilterDetail as ITableFilterWithOptions).options}
            />
          )

        case 'switch':
          return (
            <Input.Switch
              colorScheme="black"
              label={getTranslation(activeFilterDetail.label)}
              value={values.value}
              onClick={val => setFieldValue('value', val)}
            />
          )

        default:
          return null
      }
    },
    [checkedIds, activeFilterDetail, onCheck]
  )

  return (
    <ContextMenu
      isDark
      width={300}
      className={cx('normal-case text-sm', className)}
      direction="right"
      header={getTranslation('general.label.filters')}
      items={filters.map(
        filter =>
          ({
            ...filter,
            children: getTranslation(filter.label),
            onClick: () => onSelectFilterDetail(filter),
          } as IContextMenuItemProps)
      )}
      type="click"
      onClose={onClose}
      {...props}>
      {/* FILTER DETAIL */}
      <Formik enableReinitialize initialValues={initialValues} onSubmit={_onApplyFilter}>
        {({ values, resetForm, setFieldValue }) => (
          <>
            <Backdrop
              className="absolute bg-custom-bg-dark rounded-md"
              visible={!!activeFilterDetail}
              zIndex="z-10"
              onClick={() => {
                onSelectFilterDetail(null)
                resetForm()
              }}
            />
            <Form
              className={cx(
                activeFilterDetail
                  ? 'left-4 opacity-100'
                  : 'left-full overflow-hidden opacity-0 z-0',
                'absolute bg-black text-gray-200 text-sm inset-y-0 right-0 px-4 py-3 z-15 transition-all duration-300 rounded-md overflow-auto'
              )}>
              {/* FILTER DETAIL HEADER */}
              <div className="flex justify-between items-center mb-4">
                <Translation
                  className="font-semibold whitespace-normal"
                  keyValue={activeFilterDetail?.label || 'general.label.filters'}
                />

                <Icon
                  className="text-gray-400"
                  name="times"
                  size="md"
                  type="regular"
                  onClick={() => {
                    onSelectFilterDetail(null)
                    resetForm()
                  }}
                />
              </div>

              {/* FILTER DETAIL INPUTS */}
              {renderMenuChildren(values, setFieldValue)}

              <Button.Submit className="mt-4" label={getTranslation('general.action.select')} />
            </Form>

            {filters.length < 4 && activeFilterDetail
              ? Array(4 - filters.length)
                  .fill('')
                  .map((_, index) => <div key={index} className="p-6" />)
              : null}
          </>
        )}
      </Formik>
    </ContextMenu>
  )
}

// core
import React from 'react'
// components
import { Icon, IDefaultProps, TIconName } from 'components'
// libraries
import cx from 'classnames'

export interface IMessageDefaultProps extends IDefaultProps {
  /**
   * Icon to be displayed on the left side
   *
   * @default 'exclamation-triangle'
   */
  icon?: TIconName
  /**
   * Text to display providing additional information
   */
  message: string
}

export const MessageDefault = ({
  className,
  icon = 'exclamation-triangle',
  message,
}: IMessageDefaultProps) => {
  return (
    <div
      className={cx(
        'flex rounded opacity-75 bg-gray-400 divide-x divide-gray-300 text-white font-semibold',
        className
      )}>
      <div className="px-4 flex-shrink-0 self-center">
        <Icon name={icon} size="lg" />
      </div>

      <p className="p-4 text-xs">{message}</p>
    </div>
  )
}

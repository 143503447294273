// core
import { gql } from '@apollo/client'
import { SideMenuFragments } from './SideMenuFragments'

export const SideMenuMutations = {
  CREATE_ITEM: gql`
    mutation CreateMenuItem($data: CreateSysMenuItemInput!) {
      createSysMenuItem(data: $data) {
        allItems {
          ...SideMenuItem
        }
        newItem {
          ...SideMenuItem
        }
      }
    }

    ${SideMenuFragments.SIDEMENU_ITEM}
  `,

  UPDATE_ITEM: gql`
    mutation UpdateMenuItem($id: ID!, $data: UpdateSysMenuItemInput!) {
      updateSysMenuItem(id: $id, data: $data) {
        ...SideMenuItem
      }
    }

    ${SideMenuFragments.SIDEMENU_ITEM}
  `,

  DELETE_ITEM: gql`
    mutation DeleteMenuItem(
      $id: ID!
      $deleteChildSysMenuItems: Boolean
      $newParentSysMenuItemId: ID
    ) {
      deleteSysMenuItem(
        id: $id
        deleteChildSysMenuItems: $deleteChildSysMenuItems
        newParentSysMenuItemId: $newParentSysMenuItemId
      ) {
        ...SideMenuItem
      }
    }

    ${SideMenuFragments.SIDEMENU_ITEM}
  `,

  MOVE_ITEM: gql`
    mutation MoveMenuItem($id: ID!, $order: [ID!]!, $parentSysMenuItemId: ID) {
      moveSysMenuItem(id: $id, order: $order, parentSysMenuItemId: $parentSysMenuItemId) {
        ...SideMenuItem
      }
    }

    ${SideMenuFragments.SIDEMENU_ITEM}
  `,
}

// core
import React from 'react'
// components
import { CalloutDefault, ICalloutDefaultProps } from './variants/CalloutDefault'
import { CalloutPermissions } from './variants/CalloutPermissions'

export class Callout extends React.Component<ICalloutDefaultProps> {
  static Default = CalloutDefault
  static Permissions = CalloutPermissions

  render() {
    return <CalloutDefault {...this.props} />
  }
}

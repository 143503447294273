// core
import React, { Fragment, ReactNode, useCallback, useState } from 'react'
// components
import {
  Icon,
  IDefaultProps,
  IDefaultWrapperProps,
  ITooltipProps,
  Scrollable,
  Tooltip,
} from 'components'
// libraries
import cx from 'classnames'
// utils
import { treeMarginLeftClasses } from 'utils'

export interface ITreeItemProps extends Omit<IDefaultWrapperProps, 'children'> {
  /**
   * Item that should be highlighted
   *
   * @note prop inside component, dont use from outside
   */
  activeItemId?: string | null
  children?: ITreeItemProps[]
  endRender?: ReactNode
  id: string
  /**
   * prop inside component to check if child is last (style purposes)
   */
  isLast?: boolean
  /**
   * prop inside component to check nested level (style purposes)
   */
  level?: number
  title: string
  /**
   * Props for `Tooltip` component (e.g.: `title`, `message` and `color`)
   *
   * @default undefined
   */
  tooltip?: Omit<ITooltipProps, 'children'>
  onClick?: (id: string) => void
}

export interface ITreeProps extends Omit<IDefaultProps, 'style'> {
  /**
   * Item that should be highlighted
   */
  activeItemId?: string | null
  data: ITreeItemProps[]
  level?: number
}

const TreeItem = ({
  activeItemId,
  children,
  title,
  endRender,
  id,
  isLast,
  tooltip,
  level = 0,
  onClick,
}: ITreeItemProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const onToggleChildrenVisibility = useCallback(() => {
    setIsVisible(prev => !prev)
  }, [])

  const isActive = activeItemId === id

  const twCSS = (): string => {
    const defaultClasses =
      'flex flex-1 space-x-3 items-center py-4 pl-6 rounded text-txt-light-2 group-hover:text-white dark:text-gray-200'
    const marginLeft = treeMarginLeftClasses[level]

    return cx(defaultClasses, marginLeft)
  }

  return (
    <li>
      <div
        className={cx(
          'rounded relative flex items-center justify-between',
          onClick && 'cursor-pointer hover:bg-primary group dark:hover:bg-primary',
          isActive && 'bg-primary',
          level && 'bg-gray-100 dark:bg-black'
        )}
        onClick={() => onClick?.(id)}>
        {level ? (
          <span className="absolute top-0 left-9 -ml-px flex items-center h-full">
            <span
              className={cx(
                'block bg-gray-200 dark:bg-gray-800 w-px',
                isLast && !children?.length ? 'h-1/2 self-start' : 'h-full'
              )}
            />
            {Array(level)
              .fill('')
              .map((_, index) => (
                <span
                  key={index}
                  className={cx(
                    'block bg-gray-200 dark:bg-gray-800',
                    children?.length || level === 1 ? 'w-5' : 'w-8',
                    'h-px'
                  )}
                />
              ))}
          </span>
        ) : null}
        <div className={twCSS()}>
          {children?.length ? (
            <Icon
              className={cx(
                'text-txt-light flex items-center justify-center bg-gray-50 dark:bg-transparent rounded ring-1 ring-gray-300 transition group-hover:bg-gray-300 w-6 h-6',
                isActive && 'bg-gray-300'
              )}
              size="md"
              name={isVisible ? 'minus' : 'chevron-down'}
              onClick={onToggleChildrenVisibility}
            />
          ) : null}

          <span
            className={cx(
              'text-sm',
              isActive && 'text-white',
              (children?.length || level > 0) && 'ml-3'
            )}>
            {title}
          </span>

          {tooltip && (
            <Tooltip className="relative pl-0" icon="question-circle" {...tooltip}>
              <Icon className="text-primary" name="question-circle" type="regular" />
            </Tooltip>
          )}
        </div>

        {endRender && (
          <div className="py-4 pr-6 flex space-y-0 flex-row space-x-2 sm:space-x-4">
            {endRender}
          </div>
        )}
      </div>

      {children?.length && isVisible ? (
        <div>
          <ul className="transition-all divide-y divide-light dark:divide-gray-800">
            <Tree activeItemId={activeItemId} level={level + 1} data={children} />
          </ul>
        </div>
      ) : null}
    </li>
  )
}

export const Tree = ({ activeItemId, data = [], level, ...passingProps }: ITreeProps) => {
  const Wrapper = level ? Fragment : Scrollable

  return (
    <Wrapper>
      <div>
        <ul className="divide-y divide-light dark:divide-gray-800">
          {data.map((tree, index) => (
            <TreeItem
              key={tree.id}
              activeItemId={activeItemId}
              level={level}
              isLast={data.length === index + 1}
              {...passingProps}
              {...tree}
            />
          ))}
        </ul>
      </div>
    </Wrapper>
  )
}

// core
import React, { useRef, ReactNode, forwardRef } from 'react'
// components
import { Icon, IDefaultProps } from 'components'
// libraries
import cx from 'classnames'

export interface ITagProps extends IDefaultProps {
  /**
   * Children
   */
  children?: ReactNode
  /**
   * Tag color - other colors can be added later
   *
   * @default 'primary'
   */
  color?: 'danger' | 'primary' | 'success' | 'warning'
  /**
   * Tag label
   */
  label: string | ReactNode
  /**
   * Callback to run after click on component
   */
  onClick?: () => void
  /**
   * If provided, close icon will be displayed
   */
  onDeleteTag?: () => void
}

export const Tag = forwardRef(
  (
    { children, className, color = 'primary', label, style, onClick, onDeleteTag }: ITagProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const colorScheme = useRef({
      danger: 'text-danger bg-red-100',
      primary: 'text-primary bg-primary-light',
      success: 'text-success bg-green-100',
      warning: 'text-warning bg-yellow-100',
    })

    const tagColor = colorScheme.current[color]

    const twCSS = (): string => {
      const defaultClasses =
        'relative flex w-min py-1.5 px-3 mr-2 rounded text-xs font-medium items-center normal-case space-x-3'
      return cx(onClick ? 'cursor-pointer' : 'cursor-default', defaultClasses, tagColor, className)
    }

    return (
      <div ref={ref} className={twCSS()} style={style} onClick={onClick}>
        <span className="min-w-max">{label}</span>

        {onDeleteTag && (
          <Icon
            className="icon cursor-pointer text-current"
            name="times"
            size="sm"
            type="regular"
            onClick={onDeleteTag}
          />
        )}

        {children}
      </div>
    )
  }
)

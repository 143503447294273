// core
import React from 'react'
// components
import { Icon } from 'components'
import { IInputDefaultProps, InputDefault } from './InputDefault'
// libraries
import cx from 'classnames'
import { Field, FieldProps, useFormikContext } from 'formik'
import { get } from 'lodash'
// styles
import css from './InputNumber.module.scss'

interface IInputNumberCoreProps
  extends Omit<IInputDefaultProps<number>, 'type' | 'endRender' | 'name'> {
  /**
   * Round to the provided number of decimal digits
   *
   *  @default '0'
   */
  roundTo?: number
  /**
   * If provided, renders arrow keys for (de-)increasing the input's value
   *
   * @default '1'
   */
  step?: number
  /**
   *  Callbacks to execute when user clicks on arrows on the left side
   *  NOTE: ONLY USE WITHOUT FORMIK, OTHERWISE WILL BE OVERWRITTEN
   */
  onClickDown?: () => void
  onClickUp?: () => void
}

interface IInputNumberFormikProps extends IInputNumberCoreProps {
  name: string
}

type IInputNumberProps =
  | (IInputNumberCoreProps & {
      name: undefined
    })
  | IInputNumberFormikProps

export const InputNumber = (props: IInputNumberProps) =>
  props.name ? <InputNumberFormik {...props} /> : <InputNumberCore {...props} />

//  ==========  =====================  ==========

//       P A R T I A L   C O M P O N E N T S

//  ==========  =====================  ==========
const InputNumberFormik = ({ step = 1, roundTo = 0, ...props }: IInputNumberFormikProps) => {
  const { errors } = useFormikContext()

  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <InputNumberCore
          {...props}
          {...field}
          error={get(errors, props.name)}
          roundTo={roundTo}
          step={step}
          value={Number(field.value)}
          onClickDown={() =>
            form.setFieldValue(
              props.name,
              ((Number(form.values[props.name]) || 0) - step).toFixed(roundTo),
              true
            )
          }
          onClickUp={() =>
            form.setFieldValue(
              props.name,
              ((Number(form.values[props.name]) || 0) + step).toFixed(roundTo),
              true
            )
          }
        />
      )}
    </Field>
  )
}

//

export const InputNumberCore = ({
  error,
  isDisabled,
  step = 1,
  tooltip,
  onClickDown,
  onClickUp,
  ...passingProps
}: IInputNumberCoreProps) => {
  return (
    <InputDefault
      {...passingProps}
      classNameInput={css.inputNumber}
      endRender={
        step ? (
          <div
            className={cx(
              'absolute flex flex-col top-1/2 transform -translate-y-1/2',
              error && tooltip ? 'right-20' : error || tooltip ? 'right-14' : 'right-7'
            )}>
            {(passingProps.inputAttributes?.max || Number.MAX_VALUE) >
              (passingProps.value || Number.MIN_VALUE) && (
              <Icon
                className={cx(
                  'text-txt-light-2',
                  isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                )}
                name="chevron-up"
                size="sm"
                type="solid"
                onClick={isDisabled ? undefined : onClickUp}
              />
            )}

            {(passingProps.inputAttributes?.min || Number.MIN_VALUE) <
              (passingProps.value || Number.MAX_VALUE) && (
              <Icon
                className={cx(
                  'text-txt-light-2',
                  isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                )}
                name="chevron-down"
                size="sm"
                type="solid"
                onClick={isDisabled ? undefined : onClickDown}
              />
            )}
          </div>
        ) : (
          undefined
        )
      }
      error={error}
      isDisabled={isDisabled}
      tooltip={tooltip}
      type="number"
    />
  )
}

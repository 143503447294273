// core
import React from 'react'
// components
import { IListVariantProps, ListDefault } from './ListDefault'
import { ItemDetailed, IItemDetailedProps } from 'components/Item/variants/ItemDetailed'

interface IItemDetailed extends Omit<IItemDetailedProps, 'onEdit'> {}

interface IListItemProps extends IListVariantProps<IItemDetailed> {
  /**
   * Collection of data that will be rendered via `<ItemDetailed />`
   */
  items: IItemDetailed[]
}

export const ListDetailed = ({
  className,
  classNameItem,
  items,
  ...otherProps
}: IListItemProps) => (
  <ListDefault<IItemDetailed>
    className={className}
    items={items}
    renderItem={item => (
      <ItemDetailed {...item} key={item.id || item.title} className={classNameItem} />
    )}
    {...otherProps}
  />
)

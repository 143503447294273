// core
import React from 'react'
import { IRoutes } from './index'

const AccessRights = React.lazy(async () => import('pages/AccessRights/AccessRightsPage'))
const Blogs = React.lazy(async () => import('pages/Blog/BlogsPage'))
const ContactTypes = React.lazy(async () => import('pages/ContactTypes/ContactTypesPage'))
const Templates = React.lazy(async () => import('pages/Templates/TemplatesPage'))
const Language = React.lazy(async () => import('pages/Languages/LanguagesPage'))
const MenuPage = React.lazy(async () => import('pages/Menu/MenuPage'))
const Settings = React.lazy(async () => import('pages/Settings/SettingsPage'))
const SchedulerPage = React.lazy(async () => import('pages/Scheduler/SchedulerPage'))
const TowerPage = React.lazy(async () => import('pages/Tower/TowerPage'))
const TranslationsPage = React.lazy(async () => import('pages/Translations/TranslationsPage'))
const UsersPage = React.lazy(async () => import('pages/Users/UsersPage'))
const WidgetsPage = React.lazy(async () => import('pages/Widgets/WidgetsPage'))

const routes: IRoutes[] = [
  { component: AccessRights, path: '/access-rights' },
  { component: Blogs, path: '/blogs' },
  { component: ContactTypes, path: '/contact-types' },
  { component: Language, path: '/languages' },
  { component: MenuPage, path: '/menu' },
  { component: Settings, path: '/settings' },
  { component: SchedulerPage, disableLocalSearch: true, path: '/action-scheduler' },
  { component: Templates, disableLocalSearch: true, path: '/templates' },
  { component: TowerPage, disableLocalSearch: true, path: '/tower' },
  { component: TranslationsPage, path: '/translations' },
  { component: UsersPage, path: '/users' },
  { component: WidgetsPage, path: '/widgets' },
]

export const LoggedRoutes = routes

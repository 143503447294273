// core
import React from 'react'
// components
import { LoaderDefault, ILoaderDefaultProps } from './variants/LoaderDefault'
import { LoaderFullscreen } from './variants/LoaderFullscreen'
import { LoaderGif } from './variants/LoaderGif'
import { LoaderLine } from './variants/Line/LoaderLine'
import { LoaderWrapper } from './variants/LoaderWrapper'

export class Loader extends React.Component<ILoaderDefaultProps> {
  static Default = LoaderDefault
  static Fullscreen = LoaderFullscreen
  static Gif = LoaderGif
  static Line = LoaderLine
  static Wrapper = LoaderWrapper

  render() {
    return <LoaderDefault {...this.props} />
  }
}

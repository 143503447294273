// TODO: FILE MANAGER
// core
import React, { FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
// components
import {
  Button,
  Dialog,
  getTranslation,
  Icon,
  IDefaultProps,
  Input,
  List,
  TIconName,
  Translation,
} from 'components'
import { IValue } from 'components/Input/variants/InputSelect'
// entities
import { IDraftJsData, WysiwygAction, WysiwygLink, WysiwygTag } from './Entities'
// forms
import {
  IInsertActionFormValues,
  IInsertLinkFormValues,
  IInsertParamFormValues,
  InsertActionForm,
  insertActionFormValidationSchema,
  InsertLinkForm,
  insertLinkFormValidationSchema,
  InsertParamForm,
  insertParamFormValidationSchema,
} from './Forms'
// functions
import { findEntities, mediaBlockRenderer, WysiwygEntities } from './functions'
// import { FileManagerDialog } from 'components/complex/FileManager/FileManagerDialog'
// libraries
import cx from 'classnames'
import {
  CompositeDecorator,
  ContentBlock,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  Entity,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { Form, Formik, FormikHelpers } from 'formik'
import { IObject } from 'utils'
// styles
import css from './WysiwygEditor.module.scss'

import 'draft-js/dist/Draft.css'

export interface IWysiwygEditorProps extends IDefaultProps {
  /**
   * Editor default value object in JSON
   */
  defaultValue?: string | null
  /**
   * Whether wysiwyg should enable action
   */
  enableAction?: boolean
  /**
   * Whether wysiwyg should have fixed height of textarea
   * @default true
   */
  fixedHeight?: boolean
  /**
   * Whether it shoud be disabeld
   */
  isDisabled?: boolean
  /**
   * List of tags which can be inserted to editor
   */
  tags?: { title: string; value: string }[]
  /**
   * Blur event
   */
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  /**
   * Callback to run on value change
   */
  onChange: (value: string) => void
  /**
   * Focus event
   */
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
}

export const WysiwygEditor = ({
  className,
  defaultValue,
  enableAction,
  fixedHeight = true,
  isDisabled,
  tags = [],
  onBlur,
  onChange,
  onFocus,
  ...passingProps
}: IWysiwygEditorProps) => {
  const editorRef = useRef<Editor>(null)
  const popupMenuContainerRef = useRef<HTMLDivElement>(null)

  // const [isFileManagerOpen, setIsFileManagerOpen] = useState(false)
  const [url, setUrl] = useState<string>('')
  const [showLinkSettings, setShowLinkSettings] = useState<boolean>(false)
  const [showAction, setShowAction] = useState<boolean>(false)
  const [tagEntityKey, setTagEntityKey] = useState<string | null>(null)

  const onSetEntityKey = useCallback((key: string) => {
    setTagEntityKey(key)
  }, [])

  const onClearEntityKey = useCallback(() => {
    setTagEntityKey(null)
  }, [])

  const compositeDecorator = new CompositeDecorator([
    {
      component: WysiwygLink,
      strategy: findEntities(WysiwygEntities.LINK),
    },
    {
      component: (data: IDraftJsData) => <WysiwygTag data={data} onSetEntityKey={onSetEntityKey} />,
      strategy: findEntities(WysiwygEntities.TAG),
    },
    {
      component: (data: IDraftJsData) => <WysiwygAction data={data} />,
      strategy: findEntities(WysiwygEntities.ACTION),
    },
  ])

  const [editorState, setEditorState] = useState<EditorState>(
    defaultValue && defaultValue.startsWith('{')
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue)), compositeDecorator)
      : EditorState.createEmpty(compositeDecorator)
  )

  const [searchForTag, setSearchForTag] = useState<boolean>(false)

  useEffect(() => {
    setEditorState(
      defaultValue?.startsWith('{')
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(defaultValue)),
            compositeDecorator
          )
        : EditorState.createEmpty(compositeDecorator)
    )
  }, [defaultValue])

  const updateEditorState = useCallback(
    (editorState: EditorState) => {
      setEditorState(editorState)
      onChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    },
    [onChange]
  )

  const onSelectTag = useCallback(
    (value: string) => {
      const selection = editorState.getSelection()
      const contentState = editorState.getCurrentContent()

      const contentStateWithEntity = contentState.createEntity(WysiwygEntities.TAG, 'IMMUTABLE')
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

      const newContentState = Modifier.replaceText(
        contentState,
        selection.set('anchorOffset', selection.getStartOffset() - 1) as SelectionState,
        '  '
      )

      const contentStateWithTag = Modifier.insertText(
        newContentState,
        selection.set('anchorOffset', selection.getStartOffset()) as SelectionState,
        value,
        undefined,
        entityKey
      )

      const newEditorState = EditorState.push(editorState, contentStateWithTag, 'apply-entity')

      updateEditorState(newEditorState)
      setSearchForTag(false)
    },
    [editorState, updateEditorState]
  )

  const openTagSearch = useCallback((x: number, y: number) => {
    if (popupMenuContainerRef.current) {
      popupMenuContainerRef.current.style.position = 'fixed'
      popupMenuContainerRef.current.style.top = `${(y - 5).toString()}px`
      popupMenuContainerRef.current.style.left = `${(x - 10).toString()}px`
      setSearchForTag(true)
    }
  }, [])

  const closeTagSearch = useCallback(() => {
    setSearchForTag(false)
  }, [])

  const checkTag = useCallback(
    (editorState: EditorState) => {
      if (tags.length > 0) {
        const selection = editorState.getSelection()

        if (selection.getHasFocus()) {
          const contentState = editorState.getCurrentContent()
          const block = contentState.getBlockForKey(selection.getStartKey())
          const blockText = block.getText()

          const startOffset = selection.getStartOffset()

          if (blockText.substr(startOffset - 1, 1) === '#') {
            const entity = block.getEntityAt(startOffset)

            if (!entity) {
              const s = getSelection()

              if (s?.rangeCount) {
                const range = s.getRangeAt(0)

                // check if we have client rects
                const rects = range.getClientRects()
                if (!rects.length) {
                  // probably new line buggy behavior
                  if (range.startContainer && range.collapsed) {
                    // explicitely select the contents
                    range.selectNodeContents(range.startContainer)
                  }
                }

                const position = range.getBoundingClientRect()
                openTagSearch(position.left, position.top)
              }
            }
          }
        }
      }
    },
    [openTagSearch, tags.length]
  )

  const onEditorChange = useCallback(
    (editorState: EditorState) => {
      checkTag(editorState)
      updateEditorState(editorState)
    },
    [checkTag, updateEditorState]
  )

  const onKeyCommand = useCallback(
    (command: string, editorState: EditorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command)

      if (newState) {
        updateEditorState(newState)

        return 'handled'
      }

      return 'not-handled'
    },
    [updateEditorState]
  )

  const mapKeyToEditorCommand = useCallback(
    (event: React.KeyboardEvent<IObject>) => {
      /* TAB */
      if (event.keyCode === 9) {
        const newEditorState = RichUtils.onTab(event, editorState, 4 /* maxDepth */)

        if (newEditorState !== editorState) {
          updateEditorState(newEditorState)
        }

        return 'tab'
      }

      return getDefaultKeyBinding(event)
    },
    [editorState, updateEditorState]
  )

  // const addImage = () => {
  //   setIsFileManagerOpen(true)
  // }

  // const closeFileManager = useCallback(() => {
  //   setIsFileManagerOpen(false)
  // }, [setIsFileManagerOpen])

  // const onSelectImage = useCallback(
  //   image => {
  //     if (image[0].url) {
  //       const contentState = editorState.getCurrentContent()
  //       const contentStateWithEntity = contentState.createEntity('image/png', 'IMMUTABLE', {
  //         src: image[0].url,
  //       })
  //       const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
  //       const newState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')

  //       onEditorChange(newState)
  //     }
  //     closeFileManager()
  //   },
  //   [closeFileManager, editorState, onEditorChange]
  // )

  const promptForLink = useCallback(() => {
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = selection.getStartKey()
      const startOffset = selection.getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

      let url = ''

      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
      }

      setUrl(url)
      setShowLinkSettings(true)
    }
  }, [editorState, setUrl])

  const confirmLink = useCallback(
    ({ url }: IInsertLinkFormValues, { setSubmitting }: FormikHelpers<IInsertLinkFormValues>) => {
      const contentState = editorState.getCurrentContent()
      const contentStateWithEntity = contentState.createEntity(WysiwygEntities.LINK, 'MUTABLE', {
        url,
      })

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      })
      updateEditorState(
        RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey)
      )

      setShowLinkSettings(false)
      setSubmitting(false)
    },
    [editorState, updateEditorState]
  )

  const removeLink = useCallback(() => {
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      updateEditorState(RichUtils.toggleLink(editorState, selection, null))
    }
  }, [updateEditorState, editorState])

  const promptForAction = useCallback(() => {
    const selection = editorState.getSelection()

    if (selection.isCollapsed()) {
      setShowAction(true)
    }
  }, [editorState])

  const confirmAction = useCallback(
    (
      { title, action }: IInsertActionFormValues,
      { setSubmitting }: FormikHelpers<IInsertActionFormValues>
    ) => {
      const selection = editorState.getSelection()
      const contentState = editorState.getCurrentContent()

      const contentStateWithEntity = contentState.createEntity(
        WysiwygEntities.ACTION,
        'IMMUTABLE',
        {
          action,
          title,
        }
      )

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

      const contentStateWithAction = Modifier.replaceText(
        contentState,
        selection.set('anchorOffset', selection.getEndOffset()) as SelectionState,
        title,
        undefined,
        entityKey
      )

      updateEditorState(EditorState.push(editorState, contentStateWithAction, 'apply-entity'))

      setShowAction(false)
      setSubmitting(false)
    },
    [editorState, updateEditorState]
  )

  const confirmParam = useCallback(
    (values: IInsertParamFormValues, { setSubmitting }: FormikHelpers<IInsertParamFormValues>) => {
      const contentState = editorState.getCurrentContent()
      const contentStateWithEntity = contentState.replaceEntityData(tagEntityKey || '', {
        parameter: values.param,
      })
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      })

      updateEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()))

      setTagEntityKey(null)
      setSubmitting(false)
    },
    [editorState, tagEntityKey, updateEditorState]
  )

  const removeParam = useCallback(() => {
    const contentState = editorState.getCurrentContent()

    const contentStateWithEntity = contentState.replaceEntityData(tagEntityKey || '', {})

    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    })

    updateEditorState(EditorState.forceSelection(newEditorState, newEditorState.getSelection()))

    setTagEntityKey(null)
  }, [editorState, tagEntityKey, updateEditorState])

  const onHideLinkPopover = useCallback(() => {
    setShowLinkSettings(false)
  }, [])

  const onHideActionPopover = useCallback(() => {
    setShowAction(false)
  }, [])

  const toggleInlineStyle = useCallback(
    (command: string) => {
      updateEditorState(RichUtils.toggleInlineStyle(editorState, command))
    },
    [editorState, updateEditorState]
  )

  const toggleBlockType = useCallback(
    (command: string) => {
      updateEditorState(RichUtils.toggleBlockType(editorState, command))
    },
    [editorState, updateEditorState]
  )

  const getClassName = useCallback((contentBlock: ContentBlock) => {
    const type = contentBlock.getType()

    return (
      ({
        unstyled: css.alignLeft,
        center: css.alignCenter,
        right: css.alignRight,
      } as IObject<string>)[type] || ''
    )
  }, [])

  const focusEditor = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.focus()
    }
  }, [editorRef])

  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <>
      <Dialog.Inline
        direction="bottom"
        isOpen={showLinkSettings}
        width={310}
        onBlur={onHideLinkPopover}>
        <div className={cx(css.linkPopover, 'dark:text-white')}>
          <div className="flex justify-between items-center mb-2">
            <Translation keyValue="general.action.insert_link" />
            <Button.Close key="closeButton" onClick={onHideLinkPopover} />
          </div>

          <Formik
            enableReinitialize
            validateOnChange
            initialValues={insertLinkFormValidationSchema.cast({ url })}
            validationSchema={insertLinkFormValidationSchema}
            onSubmit={confirmLink}>
            {({ handleReset }) => {
              return (
                <Form>
                  <InsertLinkForm />

                  <div className="flex justify-around items-center mt-2 space-x-2">
                    <Button.Submit label={getTranslation('general.action.confirm')} />

                    <Button
                      color="grey"
                      label={getTranslation('general.action.remove')}
                      onClick={() => {
                        removeLink()
                        handleReset()
                      }}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Dialog.Inline>

      {enableAction && (
        <Dialog.Inline
          direction="bottom"
          isOpen={showAction}
          width={310}
          onBlur={onHideActionPopover}>
          <div className={cx(css.linkPopover, 'dark:text-white')}>
            <div className="flex justify-between items-center mb-2">
              <Translation keyValue="sys_templates.action.insert_action" />
              <Button.Close key="closeButton" onClick={onHideActionPopover} />
            </div>

            <Formik
              enableReinitialize
              validateOnChange
              initialValues={insertActionFormValidationSchema.cast({})}
              validationSchema={insertActionFormValidationSchema}
              onSubmit={confirmAction}>
              {({ handleReset }) => {
                return (
                  <Form>
                    <InsertActionForm />

                    <div className="flex justify-around items-center mt-2 space-x-2">
                      <Button.Submit label={getTranslation('general.action.confirm')} />

                      <Button
                        color="grey"
                        label={getTranslation('general.action.reset')}
                        onClick={handleReset}
                      />
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Dialog.Inline>
      )}

      <Dialog.Inline
        direction="bottom"
        isOpen={!!tagEntityKey}
        width={310}
        onBlur={onClearEntityKey}>
        <div className={cx(css.linkPopover, 'dark:text-white')}>
          <div className="flex justify-between items-center mb-2">
            <Translation keyValue="sys_templates.add_parameter" />

            <Button.Close key="closeButton" onClick={onClearEntityKey} />
          </div>

          <Formik
            enableReinitialize
            validateOnChange
            initialValues={insertParamFormValidationSchema.cast({
              param: tagEntityKey
                ? editorState
                    .getCurrentContent()
                    .getEntity(tagEntityKey)
                    .getData().parameter
                : '',
            })}
            validationSchema={insertParamFormValidationSchema}
            onSubmit={confirmParam}>
            {({ handleReset }) => {
              return (
                <Form>
                  <InsertParamForm />

                  <div className="flex justify-around items-center mt-2 space-x-2">
                    <Button.Submit label={getTranslation('general.action.confirm')} />

                    <Button
                      color="grey"
                      label={getTranslation('general.action.remove')}
                      onClick={() => {
                        removeParam()
                        handleReset()
                      }}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Dialog.Inline>

      <div className={cx('divide-y flex flex-col', className)}>
        <div
          className={cx(
            css.controlsWrap,
            'flex flex-nowrap items-center pt-4 px-6 pl-2 space-x-4 divide-x text-txt-light overflow-x-auto'
          )}>
          <InlineControls
            disabled={isDisabled}
            editorState={editorState}
            onToggle={toggleInlineStyle}
          />

          <AlignControls
            disabled={isDisabled}
            editorState={editorState}
            onToggle={toggleBlockType}
          />

          <div className={css.selectWrapper}>
            <Input.Select
              isSmall
              className={cx(css.customSelect)}
              colorScheme="gray"
              isDisabled={isDisabled}
              options={[
                { id: 'unstyled', title: getTranslation('general.label.text') },
                { id: 'header-one', title: getTranslation('general.label.heading_one') },
                { id: 'header-two', title: getTranslation('general.label.heading_two') },
                { id: 'header-three', title: getTranslation('general.label.heading_three') },
              ]}
              placeholder={getTranslation('general.label.select_font_style_placeholder')}
              onChange={val => {
                toggleBlockType((val as IValue).id)
              }}
            />
          </div>

          <BlockControls
            disabled={isDisabled}
            editorState={editorState}
            onToggle={toggleBlockType}
          />

          <div className="flex pl-4 space-x-4">
            {/* <Button.Wrapper
              noStyles
              className="hover:text-txt dark:text-txt dark:hover:text-txt-light"
              isDisabled={isDisabled}
              onClick={addImage}>
              <Icon size="lg" name="image" />
            </Button.Wrapper> */}

            <Button.Wrapper
              noStyles
              className="hover:text-txt dark:text-txt dark:hover:text-txt-light"
              isDisabled={isDisabled}
              onClick={promptForLink}>
              <Icon size="md" name="link" type="solid" />
            </Button.Wrapper>

            {enableAction && (
              <Button.Wrapper
                noStyles
                className="hover:text-txt dark:text-txt dark:hover:text-txt-light"
                isDisabled={isDisabled}
                onClick={promptForAction}>
                <Icon size="md" name="poll-h" type="solid" />
              </Button.Wrapper>
            )}
          </div>
        </div>

        <div
          className={cx(
            css.editorWrap,
            'py-6 px-6 overflow-auto text-sm tracking-wide text-txt dark:text-white',
            fixedHeight ? 'h-56' : 'flex-grow',
            isDisabled && 'cursor-not-allowed'
          )}
          onClick={focusEditor}>
          <Editor
            ref={editorRef}
            blockRendererFn={mediaBlockRenderer}
            blockStyleFn={getClassName}
            editorState={editorState}
            handleKeyCommand={onKeyCommand}
            keyBindingFn={mapKeyToEditorCommand}
            placeholder={getTranslation('general.label.wysiwyg_placeholder')}
            readOnly={isDisabled || searchForTag}
            onBlur={onBlur}
            onChange={onEditorChange}
            onFocus={onFocus}
            {...passingProps}
          />
        </div>
      </div>

      <div ref={popupMenuContainerRef} className="z-50">
        <Dialog.Inline direction="bottom" isOpen={searchForTag} width={230} onBlur={closeTagSearch}>
          <List.Option
            className="z-50"
            searchBy="title"
            items={tags.map(({ value, title }) => ({ id: value, title }))}
            onClick={onSelectTag}
          />
        </Dialog.Inline>
      </div>

      {/* <FileManagerDialog
        open={isFileManagerOpen}
        onCancel={closeFileManager}
        onSelect={onSelectImage}
      /> */}
    </>
  )
}

//  ==========  =====================  ==========

//       P A R T I A L   C O M P O N E N T S

//  ==========  =====================  ==========

export type ButtonType = {
  active: boolean
  command: string
  icon: TIconName
}

export interface IControlsProps {
  /**
   * List of buttons to render
   */
  buttons: ButtonType[]
  /**
   * Whether it shoud be disabeld
   */
  disabled?: boolean
  /**
   * Callbacct to un on control button toggle
   */
  onToggle: (command: string) => void
}

export const Controls = ({ buttons, disabled, onToggle }: IControlsProps) => {
  const _onToggle = (command: string) => {
    onToggle(command)
  }

  const renderControlsButton = (icon: TIconName, command: string, isActive: boolean) => {
    return (
      <Button.Wrapper
        key={command}
        noStyles
        className={
          isActive
            ? 'text-txt dark:text-txt-light'
            : 'text-txt-light hover:text-txt dark:text-txt dark:hover:text-txt-light'
        }
        isDisabled={disabled}
        onClick={() => _onToggle(command)}>
        <Icon size="md" name={icon} type="solid" />
      </Button.Wrapper>
    )
  }

  return (
    <div className="flex space-x-6 pl-4">
      {buttons.map(({ icon, command, active }) => renderControlsButton(icon, command, active))}
    </div>
  )
}

export interface IBlockControlsProps extends Omit<IControlsProps, 'buttons'> {
  /**
   * State object from Editor
   */
  editorState: EditorState
}

export const BlockControls = ({ editorState, ...passingProps }: IBlockControlsProps) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  const buttons = useMemo<ButtonType[]>(
    () => [
      {
        active: blockType === 'unordered-list-item',
        command: 'unordered-list-item',
        icon: 'list-ul',
      },
      {
        active: blockType === 'ordered-list-item',
        command: 'ordered-list-item',
        icon: 'list-ol',
      },
    ],
    [blockType]
  )

  return <Controls buttons={buttons} {...passingProps} />
}

export interface IInlineControlsProps extends Omit<IControlsProps, 'buttons'> {
  /**
   * State object from Editor
   */
  editorState: EditorState
}

export const InlineControls = ({ editorState, ...passingProps }: IInlineControlsProps) => {
  const currentStyle = editorState.getCurrentInlineStyle()

  const buttons = useMemo<ButtonType[]>(
    () => [
      { active: currentStyle.has('BOLD'), command: 'BOLD', icon: 'bold' },
      { active: currentStyle.has('ITALIC'), command: 'ITALIC', icon: 'italic' },
      { active: currentStyle.has('UNDERLINE'), command: 'UNDERLINE', icon: 'underline' },
    ],
    [currentStyle]
  )

  return <Controls buttons={buttons} {...passingProps} />
}

export interface IAlignControlsProps extends Omit<IControlsProps, 'buttons'> {
  /**
   * State object from Editor
   */
  editorState: EditorState
}

export const AlignControls = ({ editorState, ...passingProps }: IAlignControlsProps) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  const buttons = useMemo<ButtonType[]>(
    () => [
      {
        active: blockType === 'unstyled',
        command: 'unstyled',
        icon: 'align-left',
      },
      {
        active: blockType === 'center',
        command: 'center',
        icon: 'align-center',
      },
      {
        active: blockType === 'right',
        command: 'right',
        icon: 'align-right',
      },
    ],
    [blockType]
  )

  return <Controls buttons={buttons} {...passingProps} />
}

export interface IMediaProps {
  block: ContentBlock
}

export const Media = ({ block }: IMediaProps) => {
  const entity = Entity.get(block.getEntityAt(0))

  const { src } = entity.getData()
  const type = entity.getType()

  return type.startsWith('image') ? (
    <img alt="wysiwygImage" className="w-full" src={src} />
  ) : (
    undefined
  )
}

// core
import React, { useCallback } from 'react'
// components
import { IInputDefaultProps } from './InputDefault'
// libraries
import cx from 'classnames'
// utils
import { stopEvent } from 'utils'

interface IInputCheckboxProps extends Omit<IInputDefaultProps<boolean>, 'onChange'> {
  onChange?(value: boolean): void
}

export const InputCheckbox = ({
  className,
  isDisabled,
  id,
  label,
  name,
  value,
  onChange,
}: IInputCheckboxProps) => {
  const _onChange = useCallback(
    e => {
      e.stopPropagation()

      if (!isDisabled && onChange) {
        onChange(!value)
      }
    },
    [value]
  )

  return (
    <div className={cx('flex items-center flex-nowrap space-x-3', className)} onClick={_onChange}>
      <input
        checked={!!value}
        disabled={isDisabled}
        className={cx(
          'form-checkbox icon w-5 h-5 text-primary rounded border border-txt-light',
          isDisabled && 'cursor-not-allowed'
        )}
        defaultChecked={!!value}
        id={id?.toString() || name}
        name={name}
        type="checkbox"
      />

      {/* LABEL */}
      <p className="text-md font-medium whitespace-nowrap">{label}</p>
    </div>
  )
}

// .core
import React, { useCallback, useMemo, useRef, useState } from 'react'
// API
import { FilterOps } from 'api/global-types'
// components
import { Dialog, Icon, Input } from 'components'
import { IInputDefaultProps, InputDefault } from './InputDefault'
// libraries
import cx from 'classnames'
import { useField, useFormikContext } from 'formik'
import { get } from 'lodash'
import moment from 'moment'
import DatePicker from 'react-datepicker'
// utils
import { EDateFormats } from 'utils'
// styles
import css from './InputDatepicker.module.scss'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

type TDateOptionTitle = '<=' | '>=' | '='

interface IDateOption {
  id: FilterOps
  title: TDateOptionTitle
}

const dateOptions: IDateOption[] = [
  {
    id: FilterOps.GTE,
    title: '>=',
  },
  {
    id: FilterOps.LTE,
    title: '<=',
  },
  {
    id: FilterOps.EQ,
    title: '=',
  },
]
interface IInputDatepickerProps extends Omit<IInputDefaultProps<Date>, 'name'> {
  /**
   * Whether time should be included in datepicker or use only date
   */
  includeTime?: boolean
  label?: string
  maxDate?: Date
  minDate?: Date
  name: string
  /**
   * If provided, select input will be rendered on the left side of date input
   *
   * @default 'false'
   */
  hasOptions?: boolean
  onChangeDateOption?(value: FilterOps): void
}

export const InputDatepicker = ({
  colorScheme,
  hasOptions = false,
  includeTime,
  isDisabled,
  label,
  maxDate,
  minDate,
  name,
  onChangeDateOption,
  ...props
}: IInputDatepickerProps) => {
  const calendarRef = useRef<any>(null)
  const { errors, setFieldValue } = useFormikContext()
  const [field] = useField(name)
  const [openCalendar, setOpenCalendar] = useState<boolean>(false)
  const [dateOption, setDateOption] = useState<IDateOption | null>(dateOptions[0])

  const value = field.value
    ? moment(Number(field.value)).format(
        includeTime ? EDateFormats.DEFAULT_DATETIME : EDateFormats.DEFAULT
      )
    : ''

  const CustomInput = useMemo(
    () => (
      <>
        <InputDefault
          readOnly
          classNameError="right-12"
          classNameTooltip="right-22"
          colorScheme={colorScheme}
          error={get(errors, name)}
          isDisabled={isDisabled}
          label={label}
          {...props}
          value={value}
          onFocus={() => setOpenCalendar(!openCalendar)}
        />

        <Icon
          className={cx(
            'absolute right-6 text-gray-400 top-1/2 transform -translate-y-1/2',
            isDisabled && 'cursor-not-allowed scale-100'
          )}
          name="calendar-alt"
          type="regular"
          onClick={() => (isDisabled ? undefined : setOpenCalendar(!openCalendar))}
        />
      </>
    ),
    [props, openCalendar]
  )

  const onChangeOption = useCallback(
    (val: IDateOption) => {
      onChangeDateOption?.(val.id)
      setDateOption(val)
    },
    [dateOption, setDateOption]
  )

  return (
    <div className={cx('relative', colorScheme === 'table' && 'h-full', hasOptions && 'flex')}>
      {hasOptions && (
        <Input.Select
          className="w-20 mr-2"
          placeholder=""
          options={dateOptions}
          value={dateOption}
          // @ts-ignore
          onChange={onChangeOption}
        />
      )}

      {CustomInput}

      <Dialog.Inline
        className="absolute top-0 left-0"
        direction="bottom"
        isOpen={openCalendar}
        side="right"
        transformWidth={false}
        useCard={false}
        width={240}
        zIndex="z-55"
        onBlur={() => setOpenCalendar(false)}>
        <DatePicker
          ref={calendarRef}
          fixedHeight
          inline
          showYearDropdown
          calendarClassName={css.calendar}
          dropdownMode="select"
          maxDate={maxDate}
          minDate={minDate}
          showTimeInput={includeTime}
          selected={
            field.value
              ? field.value instanceof Date
                ? field.value
                : moment(field.value).isValid()
                ? moment(field.value).toDate()
                : undefined
              : undefined
          }
          onChange={date => {
            setFieldValue(name, date)
            props.onChange?.(date as any)
          }}
        />
      </Dialog.Inline>
    </div>
  )
}

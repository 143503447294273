// core
import React from 'react'
// components
import { Loader } from 'components'
// libraries
import cx from 'classnames'
// styles
import css from './Content.module.scss'

export interface IContentProps {
  children: React.ReactNode
  className?: string
}

export const Content = ({ className, children }: IContentProps) => (
  <React.Suspense fallback={<Loader.Fullscreen />}>
    <div className={cx(css.root, 'bg-gray-100', className)}>{children}</div>
  </React.Suspense>
)

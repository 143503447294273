import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        blogs: relayStylePagination([
          'orderBy',
          'search',
          'isPublished',
          'publishedAt',
          'sysLanguageId',
        ]),
        sysTranslations: relayStylePagination(['orderBy', 'search', 'showOnlyNotTranslated']),
        sysActivityLogs: relayStylePagination(),
        lastLoggedInUsers: relayStylePagination(),

        /* loggedInUser: (existingData, { toReference, readField }) =>
          existingData ||
          toReference({
            __typename: 'SysUser',
            id: readField('loggedInUserId', toReference({ __typename: 'Query' })),
          }),

        loggedInUserId: existingData => existingData || null,

        notifications: relayStylePagination(['targetSysUserId', 'isSeen']),

        selectedLanguageId: existingData =>
          existingData || localStorage.getItem('languageId') || '1',

        settings: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysSettingsOption', alias: args ? args.alias : '' }),

        sysLanguageKeys: relayStylePagination(['orderBy', 'orderByDirection', 'search', 'tags']),

        translation: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysLanguageKey', languageKey: args ? args.key : '' }), */
      },
    },

    Tray: {
      fields: {
        photos: relayStylePagination(),
      },
    },
  },
}

export const cache = new InMemoryCache(cacheConfig)

import { UserFragments } from './UserFragments'
import { gql } from '@apollo/client'

export const UserMutations = {
  CREATE_USER: gql`
    mutation CreateUser($email: String!, $sysAccessGroupId: ID, $data: SysUserInputUpdate) {
      createSysUser(email: $email, sysAccessGroupId: $sysAccessGroupId, data: $data) {
        ...UserFragment
      }
    }

    ${UserFragments.USER}
  `,

  UPDATE_USER: gql`
    mutation UpdateUser($id: ID!, $data: SysUserInputUpdate!) {
      updateSysUser(id: $id, data: $data) {
        ...UserFragment
      }
    }

    ${UserFragments.USER}
  `,

  DELETE_USER: gql`
    mutation DeleteUser($id: ID!) {
      deleteSysUser(id: $id) {
        ...UserFragment
      }
    }

    ${UserFragments.USER}
  `,

  CHANGE_EMAIL_ADDRESS: gql`
    mutation ChangeEmailAddress($sysUserId: ID!, $newEmail: String!, $password: String) {
      changeEmailAddress(sysUserId: $sysUserId, newEmail: $newEmail, password: $password) {
        ...UserFragment
      }
    }

    ${UserFragments.USER}
  `,

  PUT_TOWER_THRESHOLD_NOTIFICATION_SETTING: gql`
    mutation PutTowerThresholdNotificationSetting(
      $towerId: ID!
      $trayId: ID
      $sensorId: ID!
      $channels: [TowerThresholdBreachedNotificationSettingChannel!]!
    ) {
      putTowerThresholdNotificationSetting(
        towerId: $towerId
        trayId: $trayId
        sensorId: $sensorId
        channels: $channels
      )
    }
  `,

  ADD_FIREBASE_TOKEN: gql`
    mutation AddSysUserFirebaseToken($firebaseToken: String!) {
      addSysUserFirebaseToken(firebaseToken: $firebaseToken) {
        id
      }
    }
  `,

  REMOVE_FIREBASE_TOKEN: gql`
    mutation RemoveSysUserFirebaseToken($firebaseToken: String!) {
      removeSysUserFirebaseToken(firebaseToken: $firebaseToken) {
        id
      }
    }
  `,
}

// core
import React from 'react'
// components
import { IDefaultProps } from 'components'
// libraries
import cx from 'classnames'
import Flag from 'react-world-flags'

interface ICountryFlagDefaultProps extends IDefaultProps {
  /**
   * Country code
   */
  code?: string
}

export const CountryFlag = ({ className, code }: ICountryFlagDefaultProps) => {
  const codeExceptions = () => {
    switch (code) {
      // #NOTE: getting the translations for unauth. users uses def. browser lang. which is mostly EN, but the 'react-world-flags' lib doesnt support EN code, only GN
      case 'EN':
        return 'GB'
      default:
        return code
    }
  }
  return (
    <Flag
      className={cx('w-7 h-7 object-cover rounded-full', className)}
      code={codeExceptions()}
      // in case flag was not found
      fallback={
        <span className="flex justify-center items-center w-7 h-7 text-sm rounded-full border">
          {code}
        </span>
      }
      style={{ maxWidth: undefined }}
    />
  )
}

// core
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { LoggedInUserContext } from 'index'
// API
import { LanguageQueries } from 'api/Languages/LanguageQueries'
import {
  GetLanguages,
  GetLanguages_sysLanguages as ILanguageNode,
} from 'api/Languages/types/GetLanguages'
import { NotificationsMutations } from 'api/Notifications/NotificationsMutations'
import {
  DeleteNotifications,
  DeleteNotificationsVariables,
} from 'api/Notifications/types/DeleteNotifications'
import {
  MarkNotificationSeen,
  MarkNotificationSeenVariables,
} from 'api/Notifications/types/MarkNotificationSeen'
import {
  GetUserNotifications,
  GetUserNotifications_sysUser_notifications,
} from 'api/User/types/GetUserNotifications'
import { UpdateUser, UpdateUserVariables } from 'api/User/types/UpdateUser'
import { UserMutations } from 'api/User/UserMutations'
import { UserQueries } from 'api/User/UserQueries'
// components
import {
  Avatar,
  Button,
  Callout,
  ContextMenu,
  CountryFlag,
  ExpandableBox,
  getTranslation,
  Icon,
  IContextMenuItemProps,
  IDefaultProps,
  Loader,
  showToastOk,
  SidePanel,
  Tab,
  Tabs,
  TIconName,
  Translation,
  TUserMenuTabValue,
} from 'components'
// libraries
import { useMutation, useQuery } from '@apollo/client'
import cx from 'classnames'
import { FormikHelpers } from 'formik'
import { useHistory } from 'react-router-dom'
// utils
import {
  EDateFormats,
  EStorageKeys,
  parseAPIErrors,
  parseDate,
  runCallback,
  useRedirect,
} from 'utils'

interface IUserMenuProps extends IDefaultProps {
  /**
   * Which tab is currently opened
   *
   * @default 'user'
   */
  activeTab?: TUserMenuTabValue
  /**
   * Notifications data
   */
  dataNotifications?: GetUserNotifications
  /**
   * Whether the panel is opened
   *
   * @default 'false'
   */
  isOpen: boolean
  /**
   * Toggles the visibility of the side menu.
   */
  onClose: () => void
  onTabChange: (tab: TUserMenuTabValue) => void
}

export const UserMenu = ({
  activeTab = 'user',
  dataNotifications,
  isOpen = false,
  onClose,
  onTabChange,
}: IUserMenuProps) => {
  const { loggedInUser } = useContext(LoggedInUserContext)
  const [currentTab, setCurrentTab] = useState<TUserMenuTabValue>(activeTab)

  const _onTabChange = useCallback((currentTab: TUserMenuTabValue) => {
    setCurrentTab(currentTab)
    onTabChange(currentTab)
  }, [])

  return (
    <SidePanel
      hasBackdrop
      isOpen={isOpen}
      side="right"
      zIndex="z-50"
      className="bg-white w-96"
      onRequestClose={onClose}>
      <MenuHeader activeTab={activeTab} onClose={onClose} onTabChange={_onTabChange} />

      <div className="w-full h-full overflow-hidden">
        {/* {currentTab === 'history' && <HistoryTabContent />} */}
        {currentTab === 'notifications' && (
          <NotificationsTabContent
            dataNotifications={dataNotifications}
            onCloseSidemenu={onClose}
          />
        )}
        {currentTab === 'user' && (
          <UserTabContent
            name={loggedInUser?.fullName}
            email={loggedInUser?.email}
            onClose={onClose}
          />
        )}
      </div>
    </SidePanel>
  )
}

//  ==========  =====================  ==========

//       P A R T I A L   C O M P O N E N T S

//  ==========  =====================  ==========
interface IMenuHeaderProps extends IDefaultProps {
  /**
   * Which tab is currently opened
   *
   * @default 'user'
   */
  activeTab: TUserMenuTabValue
  /**
   * Toggles the visibility of the side menu.
   */
  onClose?: () => void
  /**
   * Changing of the current opened tab
   */
  onTabChange: (tab: TUserMenuTabValue) => void
}

const MenuHeader = ({ activeTab = 'user', onClose, onTabChange }: IMenuHeaderProps) => {
  const [currentTab, setCurrentTab] = useState<TUserMenuTabValue>(activeTab)

  useEffect(() => {
    setCurrentTab(activeTab)
    onTabChange(activeTab)
  }, [activeTab])

  const tabs = [
    {
      id: 'notifications' as TUserMenuTabValue,
      label: <Translation keyValue="general.label.notifications" />,
    },
    {
      id: 'user' as TUserMenuTabValue,
      label: <Translation keyValue="general.label.user" />,
    },
  ]

  return (
    <div className="absolute top-0 flex items-center justify-between w-full h-14 text-sm text-black bg-gray-100 z-5">
      <div className="flex">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={cx(
              currentTab === tab.id && 'bg-white',
              'w-max p-5 px-3 sm:px-5 font-light rounded-t-lg transition focus:outline-none truncate'
            )}
            onClick={() => {
              setCurrentTab(tab.id)
              onTabChange(tab.id)
            }}>
            {tab.label}
          </button>
        ))}
      </div>

      <button className="icon ml-2 h-7 flex items-center pr-4 focus:outline-none" onClick={onClose}>
        <Icon name="chevron-right" type="regular" />
      </button>
    </div>
  )
}

interface IUserTabContentProps extends IDefaultProps {
  /**
   * User's name
   */
  name?: string
  /**
   * User's e-mail address
   */
  email?: string
  /**
   * Toggles the visibility of the side menu.
   */
  onClose: () => void
}

const UserTabContent = ({ name, email, onClose }: IUserTabContentProps) => {
  const { loggedInUser, setLoggedInUser } = useContext(LoggedInUserContext)

  // ==================== State ====================
  const [showLanguages, setShowLanguages] = useState<boolean>(false)
  const [showLogout, setShowLogout] = useState<boolean>(false)

  // ==================== Hooks ====================
  const [, goTo] = useRedirect()

  // ==================== Queries ====================
  const { data: languages, loading: loadingLanguages } = useQuery<GetLanguages>(
    LanguageQueries.GET_LANGUAGES
  )

  const [updateUser, { loading: loadingUpdateUser }] = useMutation<UpdateUser, UpdateUserVariables>(
    UserMutations.UPDATE_USER
  )

  /**
   * Event called when user changes language - clicks on one of the circled flags
   */
  const onChangeLanguage = useCallback(
    (lang: ILanguageNode) => () => {
      const newLangId = lang.id

      if (loggedInUser?.id) {
        updateUser({ variables: { id: loggedInUser.id, data: { sysLanguageId: newLangId } } })
          .then(() => {
            localStorage.setItem(EStorageKeys.DEFAULT_LANG_ID, newLangId)
            window.location.reload()
          })
          .catch(parseAPIErrors)
      }
    },
    [loggedInUser?.id]
  )

  /**
   * Event called user clicks "Manage profile" btn
   */
  const onGoToUserProfile = useCallback(() => {
    if (!loggedInUser?.id) return

    onClose()

    goTo()
      .users(true)
      .detail({ userId: loggedInUser.id })
  }, [loggedInUser])

  /**
   * Renders a correct flag based on provided language code
   * @param lang short code of a language
   * @returns Flag element - SVG image
   */
  const renderLanguageFlag = useCallback(
    (lang: ILanguageNode) => {
      const defaultLanguageId = localStorage.getItem(EStorageKeys.DEFAULT_LANG_ID)
      const flag = <CountryFlag code={lang.codeShort} />

      let isLanguageActive = false

      if (defaultLanguageId) {
        isLanguageActive = defaultLanguageId === lang.id
      }

      return (
        <div
          key={lang.id}
          className={cx(
            'icon flex items-center justify-center w-6 h-6 rounded-full overflow-hidden',
            isLanguageActive
              ? 'ring-2 ring-primary cursor-default transform transition scale-110'
              : 'ring-1 cursor-pointer'
          )}
          onClick={isLanguageActive ? undefined : onChangeLanguage(lang)}>
          {flag}
        </div>
      )
    },
    [onChangeLanguage]
  )

  const onLogout = () => {
    setLoggedInUser(null)
  }

  return (
    <div className="pt-14 flex flex-col text-black overflow-y-auto">
      <Avatar.Detailed
        className="p-8 px-4 sm:px-8 pb-4"
        size="large"
        subtitle={email}
        title={name}
        url={loggedInUser?.profilePicture?.fileUrl}
      />

      <div className="flex flex-col divide-y divide-light overflow-y-auto overflow-x-hidden">
        <div
          className="flex justify-between text-gray-500 text-sm font-light p-4 sm:px-8 cursor-pointer"
          onClick={onGoToUserProfile}>
          <Translation keyValue="general.label.manage_account" />
          <Icon name="chevron-right" type="regular" />
        </div>

        <div className="p-4 sm:px-8">
          <div
            className="flex justify-between items-center text-gray-500 text-sm font-light cursor-pointer"
            onClick={() => setShowLanguages(!showLanguages)}>
            <Translation keyValue="general.label.language_settings" />
            <Icon name={showLanguages ? 'chevron-up' : 'chevron-down'} type="regular" />
          </div>

          <ExpandableBox className="relative" expanded={showLanguages}>
            <Loader.Wrapper isLoading={loadingLanguages || loadingUpdateUser} opacity="opacity-30">
              <div className="flex px-1 pt-3 pb-1 space-x-3">
                {languages?.sysLanguages.map(lang => renderLanguageFlag(lang))}
              </div>
            </Loader.Wrapper>
          </ExpandableBox>
        </div>

        <div
          className="relative flex justify-between items-center text-gray-500 text-sm font-light p-4 sm:px-8 cursor-pointer"
          onClick={() => setShowLogout(true)}>
          <Translation keyValue="sys_auth.action.logout" />
          <Icon size="lg" name="sign-out-alt" />

          <div
            className={cx(
              showLogout ? 'translate-x-0' : 'translate-x-full',
              'absolute top-0 inset-x-0 p-4 sm:px-8 flex items-center justify-between font-light text-sm text-gray-500 bg-white transform transition-all duration-300 ease-out cursor-default'
            )}>
            <Translation keyValue="sys_auth.label.logout_message" />
            <div className="flex items-center space-x-4">
              <Button color="success" icon="check" size="small" onClick={onLogout} />

              <Button color="grey" icon="times" size="small" onClick={() => setShowLogout(false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface INotificationsTabContentProps {
  /**
   * Notifications data
   */
  dataNotifications?: GetUserNotifications
  onCloseSidemenu: () => void
}

const NotificationsTabContent = ({
  dataNotifications,
  onCloseSidemenu,
}: INotificationsTabContentProps) => {
  // ==================== Variables ====================
  const notifications = dataNotifications?.sysUser.notifications
  const notificationsSeen: GetUserNotifications_sysUser_notifications[] = [],
    notificationsUnseen: GetUserNotifications_sysUser_notifications[] = []

  notifications?.forEach(n => (n.seen ? notificationsSeen : notificationsUnseen).push(n))

  // ==================== Mutations ====================
  const [deleteNotifications] = useMutation<DeleteNotifications, DeleteNotificationsVariables>(
    NotificationsMutations.DELETE_NOTIFICATIONS
  )

  const onDeleteNotifications = useCallback(async (all: boolean, ids: string[]) => {
    return deleteNotifications({
      variables: { all, notificationIds: ids },
      update: cache => {
        const queryConfig = {
          query: UserQueries.GET_USER_NOTIFICATIONS,
        }

        const data = cache.readQuery<GetUserNotifications>(queryConfig)

        if (data?.sysUser) {
          cache.writeQuery<GetUserNotifications>({
            ...queryConfig,
            data: {
              sysUser: {
                ...data.sysUser,
                notifications: all
                  ? []
                  : data.sysUser.notifications.filter(({ id }) => !ids.includes(id)),
              },
            },
          })
        }
      },
    })
      .then(() => showToastOk('sys_notifications.label.toast_notification_deleted'))
      .catch(parseAPIErrors)
  }, [])

  const [markAsSeen] = useMutation<MarkNotificationSeen, MarkNotificationSeenVariables>(
    NotificationsMutations.MARK_NOTIFICATION_SEEN
  )

  const onToggleNotificationAsSeen = async (
    all: boolean,
    seen: boolean,
    values?: string[],
    helpers?: FormikHelpers<string[]>
  ) => {
    if (values) {
      return markAsSeen({
        variables: { all, notificationIds: values, seen: seen },
      })
        .then(() => showToastOk('sys_notifications.label.toast_notification_status'))
        .catch(parseAPIErrors)
        .finally(() => helpers?.setSubmitting(false))
    }

    return Promise.resolve()
  }

  const headerMenuItems = [
    {
      children: <Translation keyValue="general.action.mark_all_seen" />,
      stopsEvent: true,
      onClick: async () =>
        onToggleNotificationAsSeen(
          true,
          true,
          notificationsUnseen.map(notification => notification.id) || []
        ),
    } as IContextMenuItemProps,
    {
      children: <Translation keyValue="general.action.mark_all_unseen" />,
      stopsEvent: true,
      onClick: async () =>
        onToggleNotificationAsSeen(
          true,
          false,
          notificationsUnseen.map(notification => notification.id) || []
        ),
    } as IContextMenuItemProps,
    {
      children: <Translation keyValue="general.action.dismiss_all" />,
      stopsEvent: true,
      onClick: async () => onDeleteNotifications(true, []),
    } as IContextMenuItemProps,
  ]

  return (
    <Tabs
      activeTab="all"
      className="pt-14"
      contentRight={
        <ContextMenu
          className="right-3"
          classNameIcon="px-4"
          header={<Translation keyValue="general.label.action" />}
          items={headerMenuItems}
        />
      }>
      <Tab
        id="all"
        label={
          <>
            <Translation keyValue="general.label.all" />
            {notifications?.length ? ` (${notifications.length})` : null}
          </>
        }>
        {notifications?.length ? (
          notifications.map(notification => (
            <NotificationItem
              key={notification.id}
              {...notification}
              onCloseSideMenu={onCloseSidemenu}
              onDelete={onDeleteNotifications}
              onToggleSeen={onToggleNotificationAsSeen}
            />
          ))
        ) : (
          <Callout
            icon="envelope"
            title={<Translation keyValue="general.label.no_messages" />}
            message={<Translation keyValue="general.label.no_messages_message" />}
          />
        )}
      </Tab>
      <Tab
        id="new"
        label={
          <>
            <Translation keyValue="general.label.new" />
            {notificationsUnseen.length ? ` (${notificationsUnseen.length})` : null}
          </>
        }>
        {notificationsUnseen.length ? (
          notificationsUnseen.map(notification => (
            <NotificationItem
              key={notification.id}
              {...notification}
              onCloseSideMenu={onCloseSidemenu}
              onDelete={onDeleteNotifications}
              onToggleSeen={onToggleNotificationAsSeen}
            />
          ))
        ) : (
          <Callout
            icon="envelope"
            title={<Translation keyValue="general.label.no_new_messages" />}
            message={<Translation keyValue="general.label.no_new_messages_message" />}
          />
        )}
      </Tab>
      <Tab
        id="read"
        label={
          <>
            <Translation keyValue="general.label.read" />
            {notificationsSeen.length ? ` (${notificationsSeen.length})` : null}
          </>
        }>
        {notificationsSeen.length ? (
          notificationsSeen.map(notification => (
            <NotificationItem
              key={notification.id}
              {...notification}
              onCloseSideMenu={onCloseSidemenu}
              onDelete={onDeleteNotifications}
              onToggleSeen={onToggleNotificationAsSeen}
            />
          ))
        ) : (
          <Callout
            icon="envelope"
            title={<Translation keyValue="general.label.no_read_messages" />}
            message={<Translation keyValue="general.label.no_read_messages_message" />}
          />
        )}
      </Tab>
    </Tabs>
  )
}

interface INotificationItemProps extends IDefaultProps {
  /**
   * Notification ID
   */
  id: string
  /**
   * Notifications content - message or any other information from notification
   */
  content?: string
  /**
   * Creation date of the notification
   */
  createdAt?: Date
  /**
   * When the notifications come
   */
  date?: Date
  /**
   * Icon to be renderen in unseen notifications
   *
   * @default 'envelope'
   */
  icon?: TIconName
  /**
   * Whether is notification seen
   *
   * @default 'false'
   */
  seen?: boolean
  /**
   * Notification title
   */
  title?: string
  /**
   * URL to be redirected after click on notification
   */
  to?: string
  /**
   * Notification type - other types will be added later
   *
   * @default 'message'
   */
  type?: 'message' | 'info'
  /**
   * Function to be called when clicked on notification
   */
  onClick?: () => void
  /**
   * Function for closing sidemenu
   */
  onCloseSideMenu?: () => void
  /**
   * Function to be called when clicked on 'Delete' option in context menu
   */
  onDelete?(all: boolean, ids?: string[]): Promise<any>
  /**
   * Function to be called when clicked on 'Mark as seen/unseen' option in context menu
   */
  onToggleSeen?(all: boolean, seen: boolean, ids?: string[]): Promise<any>
}

const NotificationItem = ({
  content,
  createdAt,
  date,
  icon = 'envelope',
  id,
  seen,
  title,
  to,
  type = 'message',
  onClick,
  onCloseSideMenu,
  onDelete,
  onToggleSeen,
}: INotificationItemProps) => {
  const history = useHistory()

  const [isLoadingMarkAsSeen, setIsLoadingMarkAsSeen] = useState<boolean>(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)

  const onRedirection = useCallback(() => {
    history.push(`/${to || ''}`)
    onCloseSideMenu?.()
    onToggleSeen?.(false, true, [id])
    runCallback(onClick)
  }, [history, id, to])

  const _onDelete = useCallback(() => {
    if (onDelete) {
      setIsLoadingDelete(true)

      onDelete(false, [id]).finally(() => setIsLoadingDelete(false))
    }
  }, [id, !!onDelete])

  const _onToggleSeen = useCallback(() => {
    if (onToggleSeen) {
      setIsLoadingMarkAsSeen(true)

      onToggleSeen(false, !seen, [id]).finally(() => setIsLoadingMarkAsSeen(false))
    }
  }, [id, seen, !!onToggleSeen])

  const contextMenuItems = useMemo(
    () =>
      [
        {
          children: seen
            ? getTranslation('general.action.mark_unseen')
            : getTranslation('general.action.mark_seen'),
          isLoading: isLoadingMarkAsSeen,
          stopsEvent: true,
          onClick: _onToggleSeen,
        },
        {
          children: getTranslation('general.action.dismiss'),
          isLoading: isLoadingDelete,
          stopsEvent: true,
          onClick: _onDelete,
        },
      ] as IContextMenuItemProps[],
    [id, seen, isLoadingDelete, isLoadingMarkAsSeen]
  )

  const notificationDate = date || createdAt

  return (
    <div
      className={cx(
        seen ? 'bg-white' : 'bg-gray-100',
        'relative w-full max-h-36 flex items-center py-4 pl-6 pr-4 space-x-5 cursor-pointer hover:bg-gray-200'
      )}
      onClick={onRedirection}>
      {!seen && <Icon name={icon} className="text-primary z-0" />}

      <div className="flex flex-col w-full text-txt-light-2 text-xs space-y-2">
        <div className="flex justify-between">
          <p className={seen ? 'text-gray-400' : 'text-primary'}>
            {type === 'message' ? (
              <Translation keyValue="general.label.message" />
            ) : (
              <Translation keyValue="general.label.info" />
            )}
          </p>
          {!!notificationDate && parseDate(notificationDate, EDateFormats.DEFAULT_DATETIME)}
        </div>

        <div className="space-y-1">
          <p className={cx(seen ? 'text-gray-400' : 'text-black', 'heading-h3 text-left')}>
            {title}
          </p>

          <p className={cx(seen && 'text-gray-400', 'max-h-12 max-w-72 line-clamp-3')}>{content}</p>
        </div>
      </div>

      <ContextMenu
        classNameIcon="px-1"
        header={<Translation keyValue="general.label.action" />}
        items={contextMenuItems}
      />
    </div>
  )
}

// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
import { Loader } from 'components/Loader/Loader'
import { getTranslation, Translation } from 'components/Translation/Translation'
// utils
import { EARC, usePermission, useRedirect } from 'utils'
import { Callout } from '../Callout'

interface ICalloutPermissionsProps extends Omit<IDefaultWrapperProps, 'className'> {
  /**
   * Access Right action for viewing data/records
   *
   * @default 'view'
   */
  action?: 'create' | 'view' | 'list'
  /**
   * Module's Access Right Category
   */
  category: EARC
}

export const CalloutPermissions = ({
  action = 'view',
  category,
  children,
}: ICalloutPermissionsProps) => {
  // ==================== Hooks ====================
  const [canView, isLoadingPermission] = usePermission(category, action)
  const [, goTo] = useRedirect()

  return (
    <Loader.Wrapper isLoading={isLoadingPermission}>
      {canView ? (
        children
      ) : (
        <Callout
          title={<Translation keyValue="sys_access_rights.label.missing_permissions" />}
          buttons={[
            {
              label: getTranslation('general.label.go_back'),
              onClick: () => goTo().back(),
            },
            {
              label: getTranslation('general.label.go_to_dashboard'),
              onClick: () => goTo().dashboard(),
            },
          ]}
        />
      )}
    </Loader.Wrapper>
  )
}

// core
import React from 'react'
// components
import { IDefaultWrapperProps, Loader } from 'components'
// libraries
import cx from 'classnames'

interface IContentProps extends IDefaultWrapperProps {
  isLoading?: boolean
}

export const Content = ({ children, className, isLoading }: IContentProps) => (
  <React.Suspense fallback={<Loader.Fullscreen />}>
    <div
      className={cx(
        'relative bg-white dark:bg-darker text-black dark:text-white flex flex-col items-center justify-center w-full min-h-full p-4 pt-18 2xl:pl-76 overflow-hidden',
        className
      )}>
      <Loader.Wrapper isLoading={!!isLoading}>
        <div className="w-full h-full">{children}</div>
      </Loader.Wrapper>
    </div>
  </React.Suspense>
)

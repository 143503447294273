// core
import React from 'react'
// components
import { ContextMenu, Icon, IContextMenuItemProps, TKey, Translation } from 'components'
import { IItemOptionProps, ItemOption } from 'components/Item/variants/ItemOption'
import { IListVariantProps, ListDefault } from './ListDefault'

interface IItemOption extends Omit<IItemOptionProps, 'isActive' | 'onClick'> {}

interface IListOptionProps extends IListVariantProps<IItemOption> {
  /**
   * Collection of generic data used to render content of individual list items - `<Item.Option />`
   */
  items?: IItemOption[]
  /**
   * If provided, on the right side will be rendered icon trough which can be opened Context menu with provided options
   *
   * @default []
   */
  menuOptions?: IContextMenuItemProps[]
  /**
   * ID of the currently selected item
   */
  activeItemId?: TKey
  /**
   * Event called when an item is clicked
   * @param id ID of the clicked item
   */
  onClick(id: TKey): void
}

export const ListOption = ({
  activeItemId,
  className,
  classNameItem,
  items,
  menuOptions = [],
  onClick,
  ...otherProps
}: IListOptionProps) => (
  <ListDefault<IItemOption>
    className={className}
    items={items}
    renderItem={item => (
      <div className="flex justify-between items-center">
        <ItemOption
          {...item}
          key={item.id}
          className={classNameItem}
          isActive={item.id === activeItemId}
          onClick={onClick}
        />

        {Boolean(menuOptions.length) && (
          <ContextMenu
            id={item.id}
            className="sm:top-0 sm:right-4"
            classNameIcon="px-6 border-l"
            header={<Translation keyValue="general.label.action" />}
            items={menuOptions}
          />
        )}
      </div>
    )}
    {...otherProps}
  />
)

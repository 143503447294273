// core
import React, { useCallback, useContext } from 'react'
import { LoggedInUserContext } from 'index'
// components
import {
  Avatar,
  ContextMenu,
  Icon,
  IDefaultProps,
  Input,
  Translation,
  TUserMenuTabValue,
} from 'components'
// layouts
import { SidePanelContext } from 'layouts/Layout'

interface IHeaderProps extends IDefaultProps {
  notificationsCount?: number
  /*
   * Event called when a tab is clicked
   * @param tab The ID of the tab that was clicked
   */
  tabSelect: (tab: TUserMenuTabValue) => void
}

export const Header = ({ notificationsCount, tabSelect }: IHeaderProps) => {
  const { openSidePanel } = useContext(SidePanelContext)
  const { loggedInUser } = useContext(LoggedInUserContext)

  const onClickNotifications = useCallback(() => {
    tabSelect('notifications')
    openSidePanel('userMenu')
  }, [])

  const onClickAvatar = useCallback(() => {
    tabSelect('user')
    openSidePanel('userMenu')
  }, [])

  return (
    <div className="fixed flex items-center top-0 inset-x-0 2xl:left-72 2xl:right-0 bg-white h-14 shadow-lg z-35">
      <div className="flex justify-end w-full">
        <Input.SearchHeader
          className="dark:text-white"
          classNameInput="dark:bg-dark dark:text-white dark:placeholder-white"
        />

        <div className="relative flex items-center sm:space-x-3 pr-4 z-10">
          {/**TODO: connect with API to provide last 3 visited pages to "items" prop */}
          {/* <ContextMenu
            classNameIcon="hidden sm:block"
            header={<Translation keyValue="general.label.last_three_pages" />}
            iconProps={{ className: 'text-gray-400', name: 'history', size: 'lg', type: 'duotone' }}
            items={[]}
          /> */}

          <button
            className="icon p-1 text-gray-400 hidden sm:block focus:outline-none"
            onClick={onClickNotifications}>
            <Icon name="bell" size="lg" />

            {Boolean(notificationsCount) && (
              <span className="absolute -top-1 -right-1 w-5 h-5 border-2 border-white rounded-full text-xxs text-white font-bold bg-primary flex justify-center items-center">
                {notificationsCount}
              </span>
            )}
          </button>

          <Avatar url={loggedInUser?.profilePicture?.fileUrl} onClick={onClickAvatar} />
        </div>
      </div>
    </div>
  )
}

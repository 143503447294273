// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
// libraries
import cx from 'classnames'

interface IScrollableProps extends IDefaultWrapperProps {
  /**
   * In pixels, the max height of the Scrollable container, which if exceeded,
   * makes the rest of the content scrollable
   *
   * @default undefined
   */
  maxHeight?: number
  /**
   * Event called every time user scrolls wihin the container
   *
   * @default undefined
   */
  onScroll?(): React.UIEventHandler<HTMLDivElement>
}

export const Scrollable = ({ children, className, onScroll }: IScrollableProps) => (
  <div className={cx('h-full overflow-auto', className)} onScroll={onScroll}>
    {children}
  </div>
)

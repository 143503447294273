// core
import React from 'react'
// components
import logo from '../../../assets/img/loader.jpg'
import { ILoaderDefaultProps } from './LoaderDefault'
// libraries
import cx from 'classnames'

interface ILoaderGifProps extends Omit<ILoaderDefaultProps, 'isLoading'> {}

//
export const LoaderGif = ({ className }: ILoaderGifProps) => (
  <img className={cx('w-5 h-5', className)} src={logo} alt="Loading..." />
)

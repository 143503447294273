import { gql } from '@apollo/client'

export const LanguageFragments = {
  LANGUAGE: gql`
    fragment LanguageFragment on SysLanguage {
      id
      codeShort
      title
      isDefault
      production
      createdAt
      updatedAt
    }
  `,
}

// core
import React, { useCallback, useState } from 'react'
// components
import { Icon } from 'components/Icon/Icon'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { IInputDefaultProps } from './InputDefault'
// libraries
import cx from 'classnames'
import { isObject } from 'lodash'
// utils
import { IObject, stopEvent } from 'utils'

interface IInputWithOptionsProps<Option = IObject, Value = string>
  extends Omit<IInputDefaultProps<Value>, 'onChange'> {
  /**
   * Currently selected option
   */
  activeOption: Option
  /**
   * Whether the option select is disabled (used in `<Translation />`)
   */
  isDisabledOption?: boolean
  /**
   * List of side-options to select from
   */
  options: Option[]
  /**
   * Property name of an option, that holds the value which will be used as unique ID
   *
   * @default 'id'
   */
  optionIdProp?: keyof Option | 'id'
  /**
   * Property name of an option, that holds the value which will be displayed as a label
   *
   * @default 'title'
   */
  optionLabelProp?: keyof Option | 'title'
  /**
   * Value of the `<input />`
   */
  value: Value
  /**
   * Event called whenever the `<input/>` value changes or an option is selected
   * @param option selected option
   * @param value entered input's value
   */
  onChange(option: Option, value: Value): void
}

export const InputWithOptions = <Option, Value>({
  activeOption,
  className,
  classNameInput,
  colorScheme = 'gray',
  error,
  isDisabled,
  isDisabledOption,
  isFocused,
  label,
  options = [],
  optionIdProp = 'id',
  optionLabelProp = 'title',
  placeholder,
  tooltip,
  value,
  onChange,
}: IInputWithOptionsProps<Option, Value>) => {
  const [_activeOption, _setActiveOption] = useState<Option>(activeOption)
  const [isSelecting, setIsSelecting] = useState<boolean>(false)

  const getOptionProp = (option: Option, prop: keyof Option | 'id' | 'title'): string => {
    if (isObject(option) && !option.hasOwnProperty(prop))
      throw new Error(
        `ERROR in InputWithOptions, provided property ${prop as string} does not exist on the option !`
      )

    return String(option[prop as keyof Option])
  }

  const onSelect = useCallback(
    (option: Option) => (e: React.MouseEvent) => {
      stopEvent(e)
      _setActiveOption(option)
      onToggleSelection()
      onChange(_activeOption, value)
    },
    []
  )

  const onToggleSelection = useCallback(() => {
    setIsSelecting(prev => !prev)
  }, [setIsSelecting])

  const defaultBgColor = cx(
    colorScheme === 'gray' && 'bg-light',
    colorScheme === 'white' && 'bg-white',
    colorScheme === 'black' && 'bg-black',
    colorScheme === 'table' && 'bg-custom-bg-dark'
  )
  const defaultTextColor = cx(
    colorScheme === 'gray' && 'text-txt-dark',
    colorScheme === 'white' && 'text-txt-dark',
    colorScheme === 'black' && 'text-white',
    colorScheme === 'table' && 'text-white'
  )
  const defaultPlaceholderTextColor = cx(
    colorScheme === 'gray' && 'placeholder-txt-dark',
    colorScheme === 'white' && 'placeholder-txt-dark',
    colorScheme === 'black' && 'placeholder-white',
    colorScheme === 'table' && 'placeholder-white'
  )

  const textColors = cx(
    cx(error ? 'text-danger placeholder-danger' : [defaultTextColor, defaultPlaceholderTextColor])
  )

  const inputCSS = () =>
    cx(
      'w-full h-13 block group placeholder-italic dark:bg-black pt-2 pb-2.5 text-md rounded-r dark:text-white focus:ring-0 border-none text-sm',
      defaultBgColor,
      textColors,
      error
        ? 'pr-20 ring-1 ring-danger ring-inner focus:ring-danger focus:ring-inner'
        : 'focus:ring-0',
      isDisabled && 'placeholder-txt-light cursor-not-allowed',
      label ? 'pt-6' : 'pt-2',
      tooltip ? 'pr-12' : 'group',
      classNameInput
    )

  return (
    <div className="relative">
      <div
        className={cx(
          'w-full relative group mt-1 flex shadow-lg dark:shadow-white-shadow rounded',
          defaultBgColor,
          className
        )}>
        <button
          className={cx(
            'min-w-36 inline-flex items-center justify-between px-3 rounded-l dark:text-white dark:bg-black focus:outline-none',
            isDisabledOption && 'cursor-not-allowed',
            defaultTextColor
          )}
          disabled={isDisabledOption}
          onClick={onToggleSelection}>
          {activeOption[optionLabelProp as keyof Option]}

          <Icon
            className={cx(
              'ml-4 text-txt-light-2 dark:text-gray-300 transform duration-300 ease-in-out',
              isSelecting && 'rotate-180'
            )}
            name="chevron-down"
            type="solid"
          />
        </button>

        <div className="w-full relative">
          <input
            autoFocus={isFocused}
            className={inputCSS()}
            placeholder={placeholder}
            type="text"
            value={String(value)}
            onChange={e => onChange(_activeOption, (e.currentTarget.value as unknown) as Value)}
          />

          {/* ERROR INDICATOR */}
          {error && (
            <Tooltip
              className={cx(`top-3.5 right-${tooltip ? '12' : '5'}`)}
              color="danger"
              message={error}>
              <Icon className="text-danger" name="exclamation-circle" size="lg" />
            </Tooltip>
          )}

          {/* TOOLTIP */}
          {tooltip && (
            <Tooltip className="top-3.5 right-5" icon="question-circle" {...tooltip}>
              <Icon className="text-primary" name="question-circle" size="lg" type="regular" />
            </Tooltip>
          )}

          <span className="absolute top-1.5 left-3 text-xxs2 text-txt-light-2 dark:text-gray-300">
            {label}
          </span>
        </div>
      </div>

      {isSelecting && (
        <ul
          className={cx(
            defaultBgColor,
            defaultTextColor,
            'absolute left-0 top-16 mb-10 w-full shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-20'
          )}>
          {options.map((option, index) => {
            const optionId = option[optionIdProp as keyof Option]

            return (
              <li
                key={`option_${index}_${getOptionProp(option, optionIdProp)}`}
                className="cursor-pointer select-none relative py-2 px-3 hover:bg-primary hover:text-white"
                id={getOptionProp(option, optionIdProp)}
                role="option"
                onClick={onSelect(option)}>
                <div className="flex justify-between items-center">
                  <span className="font-normal block truncate">
                    {getOptionProp(option, optionLabelProp)}
                  </span>

                  {optionId === _activeOption[optionIdProp as keyof Option] && (
                    <Icon name="check" className="text-success" />
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

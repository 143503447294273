// components
import { getTranslation } from 'components'
// libraries
import * as yup from 'yup'
// utils
import { gRegex, parseDate } from 'utils'

export const configure = (): void => {
  yup.setLocale({
    mixed: {
      required: () => getTranslation('general.label.validation_required') || 'Field is required',
      defined: () => getTranslation('general.label.validation_defined') || 'Field must be defined',
    },
    number: {
      default: () => getTranslation('general.label.validation_number') || 'Type must be number',
      defined: () => getTranslation('general.label.validation_defined') || 'Field must be defined',
      required: () => getTranslation('general.label.validation_required') || 'Field is required',
      max: ({ max }: { max: number }) =>
        getTranslation('general.label.validation_max_number[value]', { value: max }) ||
        `Min ${max}`,
      min: ({ min }: { min: number }) =>
        getTranslation('general.label.validation_min_number[value]', { value: min }) ||
        `Min ${min}`,
    },
    string: {
      default: () => getTranslation('general.label.validation_string') || 'Type must be string',
      defined: () => getTranslation('general.label.validation_defined') || 'Field must be defined',
      required: () => getTranslation('general.label.validation_required') || 'Field is required',
      lowercase: () => getTranslation('general.label.validation_lowercase') || 'Must be lowercase',
      email: () => getTranslation('general.label.validation_email') || 'Must be valid email',
      url: () => getTranslation('general.label.validation_url') || 'Invalid URL',
      uppercase: () => getTranslation('general.label.validation_uppercase') || 'Must be uppercase',
      max: ({ max }: { max: number }) =>
        getTranslation('general.label.validation_max_string[value]', { value: max }) ||
        `Max length ${max}`,
      min: ({ min }: { min: number }) =>
        getTranslation('general.label.validation_min_string[value]', { value: min }) ||
        `Min length ${min}`,
    },
    boolean: {
      default: () => getTranslation('general.label.validation_boolean') || 'Type must be boolean',
      defined: () => getTranslation('general.label.validation_defined') || 'Field must be defined',
      required: () => getTranslation('general.label.validation_required') || 'Field is required',
    },
    date: {
      default: () => getTranslation('general.label.validation_date') || 'Type must be date',
      defined: () => getTranslation('general.label.validation_defined') || 'Field must be defined',
      required: () => getTranslation('general.label.validation_required') || 'Field is required',
      max: ({ max }: { max: Date }) =>
        getTranslation('general.label.validation_max_date[value]', { value: parseDate(max) }) ||
        `Min ${max}`,
      min: ({ min }: { min: Date }) =>
        getTranslation('general.label.validation_min_date[value]', { value: parseDate(min) }) ||
        `Min ${min}`,
    },
  })

  /**
   * yup method to validate url
   */
  yup.addMethod<yup.StringSchema>(yup.string, 'isUrl', function() {
    return this.test(
      'is-valid-url',
      getTranslation('general.label.validation_url') || 'Invalid URL',
      (value?: string) => {
        const url = new RegExp(gRegex.url)
        return !!value?.match(url)
      }
    )
  })
}

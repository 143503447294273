// core
import React from 'react'
// components
import { TKey } from 'components'
import { IItemCategoryProps, ItemCategory } from 'components/Item/variants/ItemCategory'
import { IListVariantProps, ListDefault } from './ListDefault'

interface IListItemProps extends IListVariantProps<IItemCategoryProps> {
  /**
   * ID of the currently active/selected item
   */
  activeItemId?: TKey
  /**
   * Collection of data that will be rendered via `<ItemCategory />`
   */
  items: IItemCategoryProps[]
  /**
   * Event called when an item is clicked
   * @param id ID of the clicked item
   */
  onClick(id: TKey): void
}

export const ListCategory = ({
  activeItemId,
  className,
  classNameItem,
  items,
  onClick,
  ...otherProps
}: IListItemProps) => (
  <ListDefault<IItemCategoryProps>
    className={className}
    items={items}
    renderItem={item => (
      <ItemCategory
        {...item}
        key={item.id || item.title}
        className={classNameItem}
        isActive={item.id === activeItemId}
        onClick={onClick}
      />
    )}
    {...otherProps}
  />
)

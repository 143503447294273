// core
import React from 'react'
// components
import { MessageDefault, IMessageDefaultProps } from './MessageDefault'
// libraries
import cx from 'classnames'

interface IMessageSuccessProps extends IMessageDefaultProps {}

export const MessageSuccess = ({ className, ...otherProps }: IMessageSuccessProps) => {
  return (
    <MessageDefault
      className={cx('bg-success divide-green-300', className)}
      icon="check"
      {...otherProps}
    />
  )
}

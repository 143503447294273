// #NOTE: copied from .app

import { useEffect, useReducer } from 'react'

export type Direction = 'ASC' | 'DESC'

export interface ITableSortConfig {
  direction: string
  key: string | null
}

function reducer({ key, direction }: ITableSortConfig, sortKey: string | null) {
  return {
    direction: sortKey === key ? (direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    key: sortKey,
  }
}

export function useSort(
  identifier: string,
  defaultKey: string | null,
  direction: 'ASC' | 'DESC' = 'ASC'
): [ITableSortConfig, (key: string | null) => void] {
  const localStorageKey = `sort-${identifier}`
  // initial load from local storage
  const sortString = localStorage.getItem(localStorageKey)
  let initialSort = { direction, key: defaultKey }

  if (sortString) {
    try {
      const sort = JSON.parse(sortString || '')
      initialSort = sort
    } catch {
      // do nothing
    }
  }

  const [sort, dispatch] = useReducer(reducer, initialSort)

  // store sort to local storage
  useEffect(() => {
    // set on change
    if (Object.keys(sort).length > 0) {
      localStorage.setItem(localStorageKey, JSON.stringify(sort))
    } else {
      localStorage.removeItem(localStorageKey)
    }
  }, [sort])

  return [sort, dispatch]
}

// core
import React, { useEffect, useMemo, useState } from 'react'
// API
import { getClient } from 'api/Api'
import { SysTranslationInput } from 'api/global-types'
import { LanguageQueries } from 'api/Languages/LanguageQueries'
import { GetLanguages_sysLanguages as ILanguageNode } from 'api/Languages/types/GetLanguages'
import { TranslationMutations } from 'api/Translations/TranslationMutations'
import { TranslationQueries } from 'api/Translations/TranslationQueries'
import {
  CreateTranslationKey,
  CreateTranslationKeyVariables,
} from 'api/Translations/types/CreateTranslationKey'
import { GetSysTranslationsInLanguage_sysTranslationsInLanguage as ITranslationNode } from 'api/Translations/types/GetSysTranslationsInLanguage'
import { GetTranslationsInLanguage } from 'api/Translations/types/GetTranslationsInLanguage'
import {
  UpdateTranslation,
  UpdateTranslationVariables,
} from 'api/Translations/types/UpdateTranslation'
// components
import { Button, Dialog, getTranslation, Input, Loader } from 'components'
// libraries
import { ApolloError, useMutation } from '@apollo/client'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
// utils
import { EStorageKeys, parseAPIErrors, TranslationKeys } from 'utils'

interface ITranslationCreate {
  key: string
  translations: SysTranslationInput[]
}

const translationSchema: Yup.SchemaOf<ITranslationCreate> = Yup.object()
  .noUnknown()
  .shape({
    key: Yup.string().required(),
    translations: Yup.array().of(
      Yup.object({
        sysLanguageId: Yup.string()
          .ensure()
          .defined()
          .required(),
        translation: Yup.string()
          .ensure()
          .default('')
          .defined(),
      })
    ),
  })
  .defined()

export type TTranslationValues = Yup.InferType<typeof translationSchema>

interface ITranslationDialogProps {
  isShown: boolean
  keyValue: TranslationKeys
  onToggle: (isShown: boolean) => void
}

/**
 * Dialog for creating Translations
 * I made it into a separate component and used default Dialog instead of RP one for sake of performance and count of API calls
 */
export const TranslationDialog = ({ isShown, keyValue, onToggle }: ITranslationDialogProps) => {
  // ==================== State ====================
  const [translationsInOtherLanguages, setTranslationsInOtherLanguages] = useState<
    (ITranslationNode | undefined)[]
  >([])

  // ==================== Hooks ====================
  useEffect(() => {
    if (!translationsInOtherLanguages.length) {
      getTranslationsInOtherLanguages()
    }
  }, [translationsInOtherLanguages.length])

  // ==================== Mutations ====================
  const [createKeyWithTranslations] = useMutation<
    CreateTranslationKey,
    CreateTranslationKeyVariables
  >(TranslationMutations.CREATE_TRANSLATION)

  const [updateKeyWithTranslations] = useMutation<UpdateTranslation, UpdateTranslationVariables>(
    TranslationMutations.UPDATE_TRANSLATION
  )

  // ==================== Variables ====================
  // Retrieve cached languages - this is always called first in App.tsx (used in UserMenu)
  const cachedLanguages: ILanguageNode[] = useMemo(
    () =>
      getClient().readQuery({
        query: LanguageQueries.GET_LANGUAGES,
      })?.sysLanguages || [],
    []
  )

  // Array of promises fetching translations for all system-defined languages
  const languageTranslationsPromises = cachedLanguages.map(async lang =>
    getTranslationInLanguagePromise(keyValue, lang.id)
  )

  const initialTranslationValues = useMemo(
    () =>
      translationSchema.cast({
        key: keyValue,
        translations: cachedLanguages.map((lang, index) => ({
          sysLanguageId: lang.id,
          translation: translationsInOtherLanguages[index]?.translation,
        })),
      }),
    [cachedLanguages, translationsInOtherLanguages]
  )

  // ==================== Events ====================
  // Had to be a separate method due to async/await
  const getTranslationsInOtherLanguages = async () => {
    const res = await Promise.all(languageTranslationsPromises)
    setTranslationsInOtherLanguages(res)
  }

  const onSubmit = async (
    values: TTranslationValues,
    helpers: FormikHelpers<TTranslationValues>
  ) => {
    // remove translations that have empty value so they wont be saved and this component will still render the red exlamation mark
    values.translations = values.translations?.filter(trans => trans.translation !== '')

    return createKeyWithTranslations({
      variables: values,
    })
      .then(() => onToggle(false))
      .catch((err: ApolloError) => {
        const _err = err.graphQLErrors[0]
        const existingTranslationId = _err.extensions?.variables?.id

        if (_err.extensions?.code === 'KEY_ALREADY_EXISTS') {
          updateKeyWithTranslations({
            variables: {
              id: existingTranslationId,
              key: keyValue,
              data: values.translations,
            },
          })
            .then(() => onToggle(false))
            .catch(parseAPIErrors)
        } else {
          parseAPIErrors(err)
        }
      })
      .finally(() => helpers.setSubmitting(false))
  }

  /**
   * A speciall usecase: dev can toggle this dialog by pressing `Control + T` shortcut to create any translation for any key anytime/anywhere
   * This however passes and empty string into the `keyValue` prop (for more check the `TranslationContext` in `Layout`)
   * This check determines which of the inputs will be autofocused
   */
  const isKeyValueEmpty = (keyValue as string) === ''

  return (
    <Dialog isShown={isShown} onToggle={onToggle}>
      <Loader.Wrapper isLoading={false}>
        <Formik
          enableReinitialize
          validateOnChange
          // @ts-ignore
          initialValues={initialTranslationValues}
          validationSchema={translationSchema}
          onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form className="flex flex-col space-y-4">
              <span>{getTranslation('sys_translations.action.create_new_translation')}</span>

              <Input.Field
                isDisabled={!isKeyValueEmpty}
                isFocused={isKeyValueEmpty}
                label={getTranslation('general.label.key')}
                name="key"
              />

              {cachedLanguages.map((lang, index) => (
                <Input.WithOptions
                  key={lang.id}
                  isDisabledOption
                  activeOption={lang}
                  isFocused={index === 0 && !isKeyValueEmpty}
                  options={[]}
                  placeholder={getTranslation(
                    'sys_translations.label.translation_create_placeholder[language]',
                    { language: lang.title }
                  )}
                  value={
                    values.translations?.find(trans => trans.sysLanguageId === lang.id)
                      ?.translation || ''
                  }
                  onChange={(_, value) =>
                    setFieldValue(
                      'translations',
                      values.translations?.map(trans => ({
                        ...trans,
                        translation: trans.sysLanguageId === lang.id ? value : trans.translation,
                      }))
                    )
                  }
                />
              ))}

              <div className="flex flex-1 justify-around">
                <Button.Submit />
                <Button.Close onClick={() => onToggle(false)} />
              </div>
            </Form>
          )}
        </Formik>
      </Loader.Wrapper>
    </Dialog>
  )
}

// Exact copy of the same method from `Translation.tsx` with 1 change - it's asynchronous - had to make it to save performance
export const getTranslationInLanguagePromise = async (
  keyValue: TranslationKeys,
  languageId?: string
): Promise<ITranslationNode | undefined> => {
  const defaultLanguageId = localStorage.getItem(EStorageKeys.DEFAULT_LANG_ID)

  const translationsInLanguage = await getClient().query<GetTranslationsInLanguage>({
    query: TranslationQueries.GET_TRANSLATIONS_IN_LANGUAGE,
    variables: {
      sysLanguageId: languageId || defaultLanguageId, // The langauage ID of translation we're looking for OR the default one from the storage
      fallbackSysLanguageCodeShort: navigator.language.slice(0, 2).toUpperCase(), // The current browser language
    },
  })

  if (!translationsInLanguage) return undefined

  // Retrieve the translation based on the provided key
  const translation = translationsInLanguage.data.sysTranslationsInLanguage.find(
    ({ key }) => key === keyValue
  )

  return translation
}

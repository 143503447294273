/* #TODOs:

    - update the styles to make the dialog more visible - add stronger shadow or smth
    - remove CSS usage and file
*/

// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
import { Backdrop } from 'components/Backdrop/Backdrop'
// libraries
import cx from 'classnames'
import ReactDOM from 'react-dom'
// styles
import css from './DialogDefault.module.scss'

export interface IDialogDefaultProps extends IDefaultWrapperProps {
  /**
   * Sets the dialog as fullscreen below `md` breakpoint.
   *
   * @default false
   */
  isSmFullHeight?: boolean
  /**
   * Whether the Dialog is visible or not
   */
  isShown?: boolean
  zIndex?: number
  /**
   * Event called when Dialog's visibility is toggled.
   * This can be done either via this prop, or render props from `DialogRP`
   * @param isShown Whether the Dialog is visible or not
   */
  onToggle?(isShown?: boolean): void
}

/**
 * Default Dialog variant, a simple, screen-centered `div` with fullscreen background blur using `<Backdrop />`
 */
export const DialogDefault = ({
  children,
  className,
  isSmFullHeight = false,
  isShown,
  zIndex,
  style,
  onToggle,
}: IDialogDefaultProps) => {
  /**
   * Toggle the Dialog's visibility
   */
  const _onToggle = () => {
    onToggle?.(!isShown)
  }

  if (!isShown) return null

  return ReactDOM.createPortal(
    <div className={css.wBackground} style={zIndex ? { zIndex } : undefined}>
      {/* BLURRED BACKGROUND */}
      <Backdrop visible zIndex="z-10" onClick={_onToggle} />

      {/* DIALOG */}
      <div
        className={cx(
          'card dark:text-white flex flex-col p-5 z-15 max-h-full max-w-full w-screen sm:max-w-md sm:min-w-36',
          isSmFullHeight && 'h-full sm:h-auto',
          className
        )}
        style={style}>
        {children}
      </div>
    </div>,
    document.body
  )
}

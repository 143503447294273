// core
import React, { useCallback, useState, useEffect } from 'react'
// components
import { Callout, Loader, Translation } from 'components'
import { IItemVariantProps } from 'components/Item/variants/ItemDefault'
// libraries
import { AutoSizer, ListRowProps, ScrollEventData } from 'react-virtualized'
import { List as ListReactVirtualized } from 'react-virtualized/dist/commonjs/List'
// utils
import { IListDefaultProps } from './ListDefault'

interface IListVirtualizedProps<T> extends Omit<IListDefaultProps<T>, 'className'> {
  /**
   * List items
   */
  items: (T & IItemVariantProps)[]
  /**
   * Is list loading? (Initial load, not fetching more)
   *
   * @default false
   */
  isLoading?: boolean
  /**
   * Row height
   *
   * @default 60
   */
  rowHeight: number
  /**
   * Distance from the bottom of the list at which the `onLoadMore` is called
   *
   * @default 0
   */
  threshold?: number
  /**
   * Total count of records
   */
  totalCount: number
  /**
   * Called when scrolled to the very bottom of the list
   */
  onLoadMore: () => Promise<any>
}

export const ListVirtualized = <T,>({
  isLoading = false,
  items,
  rowHeight = 60,
  threshold = 0,
  totalCount,
  onLoadMore,
  renderItem,
}: IListVirtualizedProps<T>) => {
  const [listItems, setListItems] = useState<((T & IItemVariantProps) | { id: 'loading' })[]>([])
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  useEffect(() => {
    setListItems(items)
    setIsLoadingMore(false)
  }, [items])

  useEffect(() => {
    if (isLoadingMore) setListItems(prevListItems => [...prevListItems, { id: 'loading' }])
  }, [isLoadingMore])

  const _renderItem = useCallback(
    (item: ListRowProps): React.ReactNode => {
      return (
        <div key={item.key} className="w-full" style={{ height: rowHeight, ...item.style }}>
          {item.index === items.length && isLoadingMore ? (
            <Loader.Fullscreen />
          ) : (
            items[item.index] && renderItem(items[item.index])
          )}
        </div>
      )
    },
    [renderItem, isLoadingMore, items.length]
  )

  const onScroll = useCallback(
    (e: ScrollEventData) => {
      if (
        e.scrollTop >= e.scrollHeight - e.clientHeight - threshold! &&
        items.length !== totalCount
      ) {
        setIsLoadingMore(true)
        onLoadMore().finally(() => setIsLoadingMore(false))
      }
    },
    [items.length, totalCount]
  )

  return isLoading ? (
    <Loader.Fullscreen />
  ) : listItems.length ? (
    <AutoSizer>
      {({ height, width }) => (
        <ListReactVirtualized
          height={height}
          rowCount={listItems.length}
          rowHeight={rowHeight}
          rowRenderer={_renderItem}
          width={width}
          onScroll={onScroll}
        />
      )}
    </AutoSizer>
  ) : (
    <Callout
      icon="exclamation-circle"
      title={<Translation keyValue="general.label.no_records" />}
    />
  )
}

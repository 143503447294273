// core
import React from 'react'
// components
import { Icon, IDefaultProps, Loader, TIcon, TIconName } from 'components'
import { ButtonWrapper } from './ButtonWrapper'
// libraries
import cx from 'classnames'

export type TButtonColor = 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'grey'

export interface IButtonDefaultProps extends IDefaultProps {
  /**
   * Whether to allow click event and its propagation
   *
   * @default false
   */
  allowEventPropagation?: boolean
  /**
   * Background color of the button
   *
   * @usage Each color is used for a specific case:
   *  `primary` - action, `secondary` - ??? TBD ???, `danger` - delete, `success` - save, `warning` - edit, `grey` - close,
   *
   * @default 'primary'
   */
  color?: TButtonColor
  /**
   * List of icon names to display on the RIGHT side
   *
   * Order in which they're defined is the order they're rendered in
   *
   * @default undefined
   */
  icon?: TIconName
  /**
   * Type of the FontAwesome icon
   *
   * @default 'solid'
   */
  iconType?: TIcon
  /**
   * Whether the button is clickable
   *
   * @default false
   */
  isDisabled?: boolean
  /**
   * Whether to render only icon without the label
   *
   * @default false
   */
  isIconOnly?: boolean
  /**
   * Whether the button should display a spinner and be disabled while process is being executed
   *
   * @default false
   */
  isLoading?: boolean
  /**
   * Text to display in the middle of the Button
   *
   * @default undefined
   */
  label?: string
  /**
   * Whether the wrapper should be style-less
   */
  noStyles?: boolean
  /**
   * Size of the button - changes the proportions and padding
   *
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Button's type
   *
   * @default 'button'
   */
  type?: 'submit' | 'reset' | 'button'
  /**
   * Event called when button is clicked
   *
   * @default undefined
   */
  onClick?(): void
}

export const ButtonDefault = ({
  className,
  isLoading = false,
  isIconOnly,
  icon,
  iconType = 'regular',
  label,
  ...otherProps
}: IButtonDefaultProps) => {
  /**
   * Concats all Tailwind classes
   * @returns string
   */
  const twCSS = (): string => {
    const padding = !isIconOnly && label ? 'w-min px-3 space-x-2' : 'justify-center'
    return cx(padding, className)
  }

  return (
    <ButtonWrapper
      className={twCSS()}
      isLoading={isLoading}
      label={isIconOnly ? undefined : label}
      {...otherProps}>
      {/* SPINNER / ICON  */}
      {isLoading ? <Loader /> : icon && <Icon name={icon} type={iconType} />}

      {/* LABEL */}
      {!isIconOnly && label && <span className="min-w-max">{label}</span>}
    </ButtonWrapper>
  )
}

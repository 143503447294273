// core
import React, { useCallback, useState } from 'react'
// components
import {
  Callout,
  IDefaultProps,
  IDefaultWrapperProps,
  Input,
  Scrollable,
  Translation,
} from 'components'
import { IItemVariantProps } from 'components/Item/variants/ItemDefault'
// libraries
import cx from 'classnames'
// utils
import { parseArray } from 'utils'

/**
 * ! FOR LIST VARIANTS ONLY !
 *
 * This props interface is shared among all other lists but exclude `renderItem` prop as each of them
 * has a specific, custom-defined value for it - since they are variants
 *
 * #NOTE: initially i wanted to use only `IListDefaultProps` but TS throws error for all List variants that
 * the `renderItem` prop is mandatory but missing and i didnt wanna make it optional either cause it needs to
 * be specified if the `ListDefault` is used instead of a variant
 */
export interface IListVariantProps<T> extends Omit<IListDefaultProps<T>, 'renderItem'> {}

/**
 * ! FOR `ListDefault` ONLY
 *
 * ! DO NOT USE THIS WITH ANY LIST VARIANTS, USE `IListVariantProps` INSTEAD
 *
 * This props interface is used only for `ListDefault`
 */
export interface IListDefaultProps<T> extends IDefaultProps {
  /**
   * CSS classes for the each item within the list
   *
   * @default undefined
   */
  classNameItem?: string
  /**
   * Whether the list is searchable, if true, SearchInput will be rendered atop the list
   *
   * @default undefined
   */
  searchBy?: keyof T | (keyof T)[]
  /**
   * Collection of generic data used to render content of individual list items
   *
   * If not specified an icon with a message is displayed indicating no records were provided
   *
   * @default undefined
   */
  items?: (T & IItemVariantProps)[]
  /**
   * Method for rendering an element per item
   * @param data Generic data for a specific item in the interation
   */
  renderItem(item: T & IItemVariantProps): React.ReactNode
}

const listCSS = 'list'

export const ListDefault = <T,>({
  className,
  items,
  searchBy,
  renderItem,
}: IListDefaultProps<T>) => {
  const [searchTerm, setSearchTerm] = useState<string>('')

  const onSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [])

  return items?.length ? (
    <>
      {searchBy && <Input.Search onChange={onSearch} />}

      {/**TODO: needs to be fixed - needed for applying overflow-visible in card when list is used, scroll seemed to not be working anyway */}
      <Scrollable>
        <ListWrapper className={className}>
          {(searchBy ? parseArray(items).searchBy(searchBy, searchTerm) : items).map(
            (item, index) => (
              <li key={item.id || `item-${index}`}>{renderItem(item)}</li>
            )
          )}
        </ListWrapper>
      </Scrollable>
    </>
  ) : (
    <Callout
      className="my-10"
      icon="empty-set"
      title={<Translation keyValue="general.label.no_records" />}
    />
  )
}

export const ListWrapper = ({ children, className }: IDefaultWrapperProps) => (
  <ul className={cx(listCSS, className)}>{children}</ul>
)

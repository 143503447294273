// core
import React, { ReactNode } from 'react'
// libraries
import { Helmet } from 'react-helmet'
// utils
import { preparePageTitle } from 'utils'

export interface IMetaProps {
  /**
   * Page title
   */
  title: string
  /**
   * Page subtitles list
   */
  subtitles?: string[]
  /**
   * More properties
   */
  children?: ReactNode
}

export const Meta = ({ title, subtitles, children }: IMetaProps) => {
  return (
    <Helmet>
      <title>{preparePageTitle(title, subtitles)}</title>

      {children}
    </Helmet>
  )
}

// #NOTE: this is modified useColumns hook customized to work with `DetailPanel`

// core
import { useEffect, useReducer, useRef } from 'react'
// libraries
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
// utils
import { IObject, isFunction } from 'utils'

type State<CategoryType, TabType> = {
  category: CategoryType | null
  tab: TabType | null
}

type NewState<CategoryType, TabType> = {
  category?: CategoryType | null
  tab?: TabType | null
}

export function useDetailPanel<CategoryType, TabType, ScreenParams>(
  categoryDefault: CategoryType | null,
  tabDefault: TabType | null,
  screenParams?: IObject<any>,
  setScreenParam?: (param: keyof ScreenParams, value: string | null) => void,
  normalizeParams?: (params: IObject<string>) => IObject<string>
): [
  State<CategoryType, TabType>,
  (
    action:
      | NewState<CategoryType, TabType>
      | ((oldState: State<CategoryType, TabType>) => NewState<CategoryType, TabType>)
  ) => void
] {
  const didMountRef = useRef(false)

  const { search } = useLocation()
  const history = useHistory()

  function reducer(
    state: State<CategoryType, TabType>,
    newState:
      | NewState<CategoryType, TabType>
      | ((oldState: State<CategoryType, TabType>) => NewState<CategoryType, TabType>)
  ) {
    const newStateObject = typeof newState === 'function' ? newState(state) : newState

    return { ...state, ...newStateObject }
  }

  const [detailPanel, dispatch] = useReducer(reducer, {
    category: categoryDefault || null,
    tab: tabDefault || null,
  })

  /**
   * Function to prepare string of parameter for pushing to history object
   */
  const prepareQueryString = () => {
    let queryStringParams: any = {
      category: detailPanel.category,
      tab: detailPanel.tab,
      ...screenParams,
    }

    if (typeof normalizeParams === 'function') {
      queryStringParams = normalizeParams(queryStringParams)
    }

    queryStringParams = Object.keys(queryStringParams)
      .filter(key => queryStringParams[key])
      .reduce((obj: any, key: string) => {
        return { ...obj, [key]: queryStringParams[key] }
      }, {})

    return `?${encodeURI(queryString.stringify(queryStringParams))}`
  }

  /**
   * Sync querystring on initial mount
   */
  useEffect(() => {
    if (!search) {
      history.replace({ search: prepareQueryString() })
    }
  }, [])

  /**
   * Compares variables and update state if there is difference
   */
  const setParamsIfNotSame = <T>(
    qsParam: T | null,
    param: T | null,
    setParam: (value: T | null) => void
  ): void => {
    if (qsParam && qsParam !== param) {
      setParam(qsParam)
    }
  }

  /**
   * Create new history step if some variable change
   */
  useEffect(() => {
    // skip for initial mount
    if (didMountRef.current) {
      const queryStringParams = prepareQueryString()

      if (queryStringParams !== search) {
        history.push({ search: queryStringParams })
      }
    } else {
      didMountRef.current = true
    }
  }, [detailPanel.category, detailPanel.tab, JSON.stringify(screenParams)])

  /**
   * Update state if querystring change
   */
  useEffect(() => {
    const qsParams = queryString.parse(decodeURI(search))

    setParamsIfNotSame<CategoryType>(
      // @ts-ignore
      qsParams.category || null,
      detailPanel.category,
      (content: CategoryType | null) => dispatch({ category: content })
    )
    setParamsIfNotSame<TabType>(
      // @ts-ignore
      qsParams.tab || null,
      detailPanel.tab,
      (content: TabType | null) => dispatch({ tab: content })
    )

    if (screenParams && isFunction(setScreenParam)) {
      Object.keys(screenParams).forEach(param => {
        setParamsIfNotSame<any>(
          qsParams[param] || null,
          Object.hasOwnProperty.call(screenParams, param) ? screenParams[param] : null,
          (value: string) =>
            // @ts-ignore
            setScreenParam(param as keyof ScreenParams, value)
        )
      })
    }
  }, [search])

  return [detailPanel, dispatch]
}

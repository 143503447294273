import { UserFragments } from './UserFragments'
import { gql } from '@apollo/client'

export const UserQueries = {
  GET_PUBLIC_USER: gql`
    query GetPublicSysUser($id: ID, $email: String) {
      publicSysUser(id: $id, email: $email) {
        id
        email
        fullName
        profilePicture {
          id
          fileUrl
        }
      }
    }
  `,

  GET_USER: gql`
    query GetSysUser($id: ID!) {
      sysUser(id: $id) {
        ...UserFragment
      }
    }

    ${UserFragments.USER}
  `,

  GET_USERS: gql`
    query GetUsers(
      $after: String
      $first: Int
      $orderBy: [SysUsersOrderBy!]
      $createdAt: [DateFilterCategory!]
      $sysAccessGroupId: [ID!]
      $search: String
    ) {
      sysUsers(
        after: $after
        first: $first
        orderBy: $orderBy
        createdAt: $createdAt
        sysAccessGroupId: $sysAccessGroupId
        search: $search
      ) {
        edges {
          cursor
          node {
            id
            fullName
            email
            profilePicture {
              id
              fileUrl
            }

            sysAccessGroup {
              id
              title
            }
          }
        }

        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }

        totalCount
      }
    }
  `,

  GET_LAST_LOGGED_IN_USERS: gql`
    query GetLastLoggedInUsers($after: String, $first: Int) {
      lastLoggedInUsers(after: $after, first: $first) {
        edges {
          cursor
          node {
            id
            fullName
            lastLoginAt
            profilePicture {
              id
              fileUrl
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  `,

  GET_USER_NOTIFICATIONS: gql`
    query GetUserNotifications {
      sysUser {
        id
        notifications {
          id
          title
          content
          seen
          createdAt
        }
      }
    }
  `,
}

// core
import { useEffect, useReducer, useRef } from 'react'
// libraries
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
// utils
import { IObject, isFunction } from 'utils'

type State<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes> = {
  first: FirstColContentTypes | null
  second: SecondColContentTypes | null
  third: ThirdColContentTypes | null
}

type NewState<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes> = {
  first?: FirstColContentTypes | null
  second?: SecondColContentTypes | null
  third?: ThirdColContentTypes | null
}

export function useColumns<
  FirstColContentTypes,
  SecondColContentTypes,
  ThirdColContentTypes,
  ScreenParams
>(
  firstColDefault: FirstColContentTypes | null,
  secondColDefault: SecondColContentTypes | null,
  thirdColDefault: ThirdColContentTypes | null,
  screenParams?: IObject<any>,
  setScreenParam?: (param: keyof ScreenParams, value: string | null) => void,
  normalizeParams?: (params: IObject<string>) => IObject<string>
): [
  State<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>,
  (
    action:
      | NewState<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>
      | ((
          oldState: State<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>
        ) => NewState<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>)
  ) => void
] {
  const didMountRef = useRef(false)

  const { search } = useLocation()
  const history = useHistory()

  function reducer(
    state: State<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>,
    newState:
      | NewState<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>
      | ((
          oldState: State<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>
        ) => NewState<FirstColContentTypes, SecondColContentTypes, ThirdColContentTypes>)
  ) {
    const newStateObject = typeof newState === 'function' ? newState(state) : newState

    return { ...state, ...newStateObject }
  }

  const [columns, dispatch] = useReducer(reducer, {
    first: firstColDefault || null,
    second: secondColDefault || null,
    third: thirdColDefault || null,
  })

  /**
   * Function to prepare string of parameter for pushing to history object
   */
  const prepareQueryString = () => {
    let queryStringParams: any = {
      firstColContent: columns.first,
      secondColContent: columns.second,
      thirdColContent: columns.third,
      ...screenParams,
    }

    if (typeof normalizeParams === 'function') {
      queryStringParams = normalizeParams(queryStringParams)
    }

    queryStringParams = Object.keys(queryStringParams)
      .filter(key => queryStringParams[key])
      .reduce((obj: any, key: string) => {
        return { ...obj, [key]: queryStringParams[key] }
      }, {})

    return `?${encodeURI(queryString.stringify(queryStringParams))}`
  }

  /**
   * Sync querystring on initial mount
   */
  useEffect(() => {
    if (!search) {
      history.replace({ search: prepareQueryString() })
    }
  }, [])

  /**
   * Compares variables and update state if there is difference
   */
  const setParamsIfNotSame = <T>(
    qsParam: T | null,
    param: T | null,
    setParam: (value: T | null) => void
  ): void => {
    if (qsParam && qsParam !== param) {
      setParam(qsParam)
    }
  }

  /**
   * Create new history step if some variable change
   */
  useEffect(() => {
    // skip for initial mount
    if (didMountRef.current) {
      const queryStringParams = prepareQueryString()

      if (queryStringParams !== search) {
        history.push({ search: queryStringParams })
      }
    } else {
      didMountRef.current = true
    }
  }, [columns.first, columns.second, columns.third, JSON.stringify(screenParams)])

  /**
   * Update state if querystring change
   */
  useEffect(() => {
    const qsParams = queryString.parse(decodeURI(search))

    setParamsIfNotSame<FirstColContentTypes>(
      // @ts-ignore
      qsParams.firstColContent || null,
      columns.first,
      (content: FirstColContentTypes | null) => dispatch({ first: content })
    )
    setParamsIfNotSame<SecondColContentTypes>(
      // @ts-ignore
      qsParams.secondColContent || null,
      columns.second,
      (content: SecondColContentTypes | null) => dispatch({ second: content })
    )
    setParamsIfNotSame<ThirdColContentTypes>(
      // @ts-ignore
      qsParams.thirdColContent || null,
      columns.third,
      (content: ThirdColContentTypes | null) => dispatch({ third: content })
    )

    if (screenParams && isFunction(setScreenParam)) {
      Object.keys(screenParams).forEach(param => {
        setParamsIfNotSame<any>(
          qsParams[param] || null,
          Object.hasOwnProperty.call(screenParams, param) ? screenParams[param] : null,
          (value: string) =>
            // @ts-ignore
            setScreenParam(param as keyof ScreenParams, value)
        )
      })
    }
  }, [search])

  return [columns, dispatch]
}

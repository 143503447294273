// core
import { Dispatch, Reducer, useEffect, useReducer } from 'react'
// API
import { ITableFilter, TFilterValue } from './TableFilters'
// libraries
import { isArray, isObject } from 'lodash'
// utils
import { SearchContextOptions } from 'utils'

/**
 * Object holding all active filters for a specific module, the key is a table column ID and value is the actual filter data
 *
 * @example
 * "filter-sysUsers": {
 *  regionId: "12385n413294hdsa01230584",
 *  status: "WAITING"
 * }
 */
export type IFilter<F> = Record<keyof F, TFilterValue>

export type TReducerAction<F> = {
  type: 'add' | 'remove' | 'reset'
  filterKey?: keyof F
  filterData?: TFilterValue
}

function reducer<F>(filters: IFilter<F>, action: TReducerAction<F>): IFilter<F> {
  const newFilters: IFilter<F> = { ...filters }
  const { type, filterKey, filterData } = action

  switch (type) {
    case 'add':
      return filterKey
        ? {
            ...filters,
            [filterKey]: filterData,
          }
        : filters

    case 'remove':
      if (filterKey) {
        delete newFilters[filterKey]
      }
      return newFilters

    case 'reset':
      return {} as IFilter<F>

    default:
      throw new Error(`Unsupported action type "${type}"`)
  }
}

export type SetActiveFilters<F> = Dispatch<TReducerAction<F>>

export function useFilter<F>(identifier: string): [IFilter<F>, SetActiveFilters<F>] {
  const localStorageKey = `filter-${identifier}`
  const filterString = localStorage.getItem(localStorageKey)
  let initialFilters = {} as IFilter<F>

  if (filterString) {
    try {
      const filters: IFilter<F> = JSON.parse(filterString || '')
      if (isObject(filters) && !isArray(filters)) {
        initialFilters = { ...filters }
      }
    } catch {
      // do nothing
    }
  }

  const [filters, dispatch] = useReducer<Reducer<F, TReducerAction<F>>>(reducer, initialFilters)

  // Either update existing or remove empty filters from localStorage
  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      localStorage.setItem(localStorageKey, JSON.stringify(filters))
    } else {
      localStorage.removeItem(localStorageKey)
    }
  }, [filters])

  return [(filters || {}) as IFilter<F>, dispatch]
}

/**
 * Returns total count of all applied filters + search
 *
 * #NOTE: added the `!== undefined || !== null` check in place since there can be `'isPublished': false`type of filters
 * @param filters filters for Table
 * @param search search bar value
 */
export const countFilters = <F>(filters: ITableFilter<F>[], search?: SearchContextOptions) => {
  return (
    filters.filter(filter => filter.filter !== undefined && filter.filter !== null).length +
    (search ? 1 : 0)
  )
}

export const setStoredFilters = <F>(identifier: string, filters?: F) => {
  if (!filters) return

  const localStorageKey = `filter-${identifier}`
  localStorage.setItem(localStorageKey, JSON.stringify(filters))
}

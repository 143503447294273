// core
import React from 'react'
// components
import { IDefaultProps, Translation } from 'components'
// libraries
import cx from 'classnames'

export interface IFooterDefaultProps extends IDefaultProps {}

export const Footer = ({ className }: IFooterDefaultProps) => {
  return (
    <div className="py-4 w-full">
      <a
        href="https://www.ambitas.org"
        rel="noopener noreferrer"
        target="_blank"
        className={cx(
          className,
          'flex flex-col flex-wrap justify-start text-xs text-gray-200 hover:underline'
        )}>
        {/* 'flex flex-col items-center w-full text-xs text-center text-gray-200 hover:underline', */}
        <Translation keyValue="general.label.developed_by_ambitas" />
        <span>
          &copy; {new Date().getFullYear()}{' '}
          <Translation keyValue="general.label.all_rights_reserved" />
        </span>
      </a>
    </div>
  )
}

// core
import React from 'react'
// components
import { Icon } from 'components'
import { getTranslation } from 'components/Translation/Translation'
import { IInputDefaultProps, InputDefault } from './InputDefault'
// libraries
import cx from 'classnames'

interface IInputSearchProps extends IInputDefaultProps<string> {}

export const InputSearch = ({ className, classNameInput, ...props }: IInputSearchProps) => {
  return (
    <div className="group flex w-full px-6 dark:bg-darker">
      <div className="input-search">
        <Icon name="search" />

        <InputDefault
          noStyles
          className={cx('w-full', className)}
          classNameInput={cx('w-full focus:ring-0 dark:bg-transparent', classNameInput)}
          placeholder={getTranslation('general.label.search')}
          {...props}
        />
      </div>
    </div>
  )
}

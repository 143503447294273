// core
import React from 'react'
// variants
import { ListCategory } from './variants/ListCategory'
import { IListDefaultProps, ListDefault, ListWrapper } from './variants/ListDefault'
import { ListDetailed } from './variants/ListDetailed'
import { ListExpandable } from './variants/ListExpandable'
import { ListExpandableVirtualized } from './variants/ListExpandableVirtualized'
import { ListOption } from './variants/ListOption'
import { ListUser } from './variants/ListUser'
import { ListVirtualized } from './variants/ListVirtualized'

export class List<T> extends React.Component<IListDefaultProps<T>> {
  static Category = ListCategory
  static Detailed = ListDetailed
  static Expandable = ListExpandable
  static ExpandableVirtualized = ListExpandableVirtualized
  static Option = ListOption
  static User = ListUser
  static Virtualized = ListVirtualized
  static Wrapper = ListWrapper

  render() {
    return <ListDefault<T> {...this.props} />
  }
}

// core
import React from 'react'
// components
import { MessageDefault, IMessageDefaultProps } from './MessageDefault'
// libraries
import cx from 'classnames'

interface IMessageErrorProps extends IMessageDefaultProps {}

export const MessageError = ({ className, ...otherProps }: IMessageErrorProps) => {
  return <MessageDefault className={cx('bg-danger divide-red-400', className)} {...otherProps} />
}

import { gql } from '@apollo/client'

export const UserFragments = {
  USER: gql`
    fragment UserFragment on SysUser {
      id
      bio
      education
      email
      fullName
      name {
        first
        last
      }
      school
      workPosition
      gdprAcceptedAt
      cellPhone

      contacts {
        title
        value
        type {
          id
          title
        }
      }

      # which access group this user belongs to
      sysAccessGroup {
        id
        title
      }

      # user's preferred language
      sysLanguage {
        id
        codeShort
        title
        isDefault
      }

      profilePicture {
        id
        fileName
        fileUrl
      }

      towerThresholdBreachedNotificationSettings {
        id
        towerId
        trayId
        sensorId
        channels
      }

      createdAt
      updatedAt
    }
  `,
}

// core
import React from 'react'
// components
import { IMessageDefaultProps, MessageDefault } from './variants/MessageDefault'
// variants
import { MessageError } from './variants/MessageError'
import { MessageWarning } from './variants/MessageWarning'
import { MessageSuccess } from './variants/MessageSuccess'

export class Message extends React.Component<IMessageDefaultProps> {
  static Error = MessageError
  static Warning = MessageWarning
  static Success = MessageSuccess

  render() {
    return <MessageDefault {...this.props} />
  }
}

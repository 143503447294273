import React from 'react'
// components
import { IDefaultProps } from 'components'
// libraries
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ReferenceLine,
  ReferenceLineProps,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { IObject } from 'utils'

export type TChart = 'line' | 'area' | 'bar' | 'pie' | 'radar'

const defaultStrokeColors = [
  '#8BA770',
  '#3B82F6',
  '#EF4444',
  '#10B981',
  '#F59E0B',
  '#6B7280',
  '#EC4899',
]
const defaultFillColors = [
  '#EEF2EB',
  '#BFDBFE',
  '#FECACA',
  '#A7F3D0',
  '#FDE68A',
  '#E5E7EB',
  '#FBCFE8',
]
const strokeColor = { stroke: '#9CA3AF' }
const axesFontSize = { fontSize: '0.8rem' }

/**
 * Other attributes or more examples can be found at https://recharts.org/en-US/guide
 */
interface IChartCommonProps extends IDefaultProps {
  colorsFill?: string[]
  colorsStroke?: string[]
  /**
   * Data names displayed on X axis - if not provided, X axis will be hidden
   */
  dataNameX?: string
  /**
   * Whether there is a grid behind chart
   *
   * @default 'false'
   */
  hasGrid?: boolean
  /**
   * Whether there is a chart legend
   *
   * @default 'false'
   */
  hasLegend?: boolean
  /**
   *
   * @default 'false'
   */
  hasTooltip?: boolean
  /**
   * Whether to display Y axes
   *
   * @default 'true'
   */
  hasYAxes?: boolean
  /**
   * !!! Only for 'scatter' type chart !!!
   * Name of the values - will be displayed in the 'Legend'
   */
  name?: string
  /**
   * !!! Only for 'scatter' type chart !!!
   * Name of the XAxes values - displayed in tooltip
   *
   * @default 'x'
   */
  nameX?: string
  /**
   * !!! Only for 'scatter' type chart !!!
   * Name of the YAxes values - displayed in tooltip
   *
   * @default 'y'
   */
  nameY?: string
  /**
   * !!! Only for 'scatter' type chart !!!
   * Unit of the XAxes values - will be displayed after the value on the X axes
   */
  unitX?: string
  /**
   * !!! Only for 'scatter' type chart !!!
   * Unit of the YAxes values - will be displayed after the value on the Y axes
   */
  unitY?: string
}

export interface IReferenceLine extends ReferenceLineProps {
  id: string
}

type IChartDataTypeProps =
  | {
      /**
       * Data which will be represented in chart
       *
       * Example of the data object {name: 'dataName', dataValue1: 100, dataValue2: 200, ..., dataValueN: 500}
       * Property 'name' will be 'dataKey' for the XAxes of the chart (or default 'dataKey' for the 'pie' and 'radara' chart type)
       * For each 'dataValue' prop, in the data object, there will be displayed separate line, bar, area, radar or part of the pie in the chart (based on the chart type)
       * Data obj. for 'pie' chart type must include property 'value' and no 'dataValue' properties (example: {name: 'propName', value: 0})
       * Data obj. for 'radar' chart type must also include property 'fullMark' - the max possible value for current 'dataKey' (example: {name: 'propName', dataValue1: 100, dataValue2: 200, ..., dataValueN: 500, fullMark: 1000})
       */
      data: IObject<any>[]
      /**
       * Reference lines for the chart
       * Can be used to visualize thresholds, limits and similar values
       *
       * @default []
       */
      referenceLines?: IReferenceLine[]
      /**
       * Type of the chart
       *
       *  @default 'line'
       */
      type?: TChart
    }
  | {
      /**
       * Data which will be represented in chart
       *
       * Data obj. for 'scatter' chart type consist JUST of the 'x' and 'y' properties (example: {x: 0, y: 1})
       */
      data: { x: number; y: number }[]
      /**
       * N/A For 'scatter'
       */
      referenceLines: undefined
      /**
       * Type of the chart
       */
      type: 'scatter'
    }

type IChartProps = IChartDataTypeProps & IChartCommonProps

export const Chart = ({ type = 'line', ...props }: IChartProps) => {
  switch (type) {
    case 'area':
      return <AreaChartType {...props} />
    case 'bar':
      return <BarChartType {...props} />
    case 'pie':
      return <PieChartType {...props} />
    case 'radar':
      return <RadarChartType {...props} />
    case 'scatter':
      return <ScatterChartType {...props} />
    default:
      return <LineChartType {...props} />
  }
}

interface IChartTypesProps extends Omit<IChartProps, 'type'> {}

const CustomTooltip = ({ props }: { props: TooltipProps<ValueType, NameType> }) => {
  // eslint-disable-next-line react/prop-types
  if (props.active && props.payload?.length) {
    return (
      <div className="bg-white rounded-md shadow-lg p-4">
        {/* eslint-disable-next-line react/prop-types */}
        <p className="text-gray-900">{props.label}</p>
        {/* eslint-disable-next-line react/prop-types */}
        {props.payload.map(val => (
          <p key={`key-${val.dataKey}`} className="text-sm text-gray-700">
            {`${val.name}: ${val.value}`}
          </p>
        ))}
      </div>
    )
  }

  return null
}

const LineChartType = ({
  className,
  colorsFill = defaultFillColors,
  colorsStroke = defaultStrokeColors,
  data,
  dataNameX,
  hasGrid = false,
  hasLegend = false,
  hasTooltip = false,
  hasYAxes = true,
  referenceLines = [],
}: IChartTypesProps) => {
  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <LineChart data={data}>
        {hasGrid && <CartesianGrid strokeDasharray="3 3" />}
        {dataNameX && (
          <XAxis
            axisLine={strokeColor}
            dataKey={dataNameX}
            style={axesFontSize}
            tickLine={strokeColor}
          />
        )}
        {hasYAxes && <YAxis axisLine={strokeColor} style={axesFontSize} tickLine={strokeColor} />}
        {hasTooltip && <Tooltip content={props => <CustomTooltip props={props} />} />}
        {hasLegend && <Legend />}
        {Object.keys(data[0] || []).map((key, index) => {
          if (key === 'name' || key === 'color') return null

          return (
            <Line
              key={`line-${key}`}
              activeDot={{ r: 5 }}
              dataKey={key}
              stroke={colorsStroke[index % colorsStroke.length]}
              type="monotone"
            />
          )
        })}
        {referenceLines.map(({ id, ...referenceLineProps }) => (
          <ReferenceLine {...referenceLineProps} key={id} />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

const AreaChartType = ({
  className,
  colorsFill = defaultFillColors,
  colorsStroke = defaultStrokeColors,
  data,
  dataNameX,
  hasGrid = false,
  hasLegend = false,
  hasTooltip = false,
  hasYAxes = true,
  referenceLines = [],
}: IChartProps) => {
  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <AreaChart data={data}>
        {hasGrid && <CartesianGrid strokeDasharray="3 3" />}
        {dataNameX && (
          <XAxis
            axisLine={strokeColor}
            dataKey={dataNameX}
            style={axesFontSize}
            tickLine={strokeColor}
          />
        )}
        {hasYAxes && <YAxis axisLine={strokeColor} style={axesFontSize} tickLine={strokeColor} />}
        {hasTooltip && <Tooltip content={props => <CustomTooltip props={props} />} />}
        {hasLegend && <Legend />}
        {Object.keys(data[0] || []).map((key, index) => {
          if (key === 'name' || key === 'color') return null

          return (
            <Area
              key={`area-${key}`}
              dataKey={key}
              fill={colorsFill[index % colorsFill.length]}
              stackId={`${key}-${index}`}
              stroke={colorsStroke[index % colorsStroke.length]}
              type="monotone"
            />
          )
        })}

        {referenceLines.map(({ id, ...referenceLineProps }) => (
          <ReferenceLine {...referenceLineProps} key={id} />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  )
}

const BarChartType = ({
  className,
  colorsStroke = defaultStrokeColors,
  data,
  dataNameX,
  hasGrid = false,
  hasLegend = false,
  hasTooltip = false,
  hasYAxes = true,
  referenceLines = [],
}: IChartProps) => {
  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <BarChart data={data}>
        {hasGrid && <CartesianGrid strokeDasharray="3 3" />}
        {dataNameX && (
          <XAxis
            axisLine={strokeColor}
            dataKey={dataNameX}
            style={axesFontSize}
            tickLine={strokeColor}
          />
        )}
        {hasYAxes && <YAxis axisLine={strokeColor} style={axesFontSize} tickLine={strokeColor} />}
        {hasTooltip && <Tooltip content={props => <CustomTooltip props={props} />} />}
        {hasLegend && <Legend />}
        {Object.keys(data[0] || []).map((key, index) => {
          if (key === 'name' || key === 'color') return null

          return (
            <Bar
              key={`bar-${key}`}
              dataKey={key}
              fill={colorsStroke[index % colorsStroke.length]}
            />
          )
        })}

        {referenceLines.map(({ id, ...referenceLineProps }) => (
          <ReferenceLine {...referenceLineProps} key={id} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

const PieChartType = ({
  className,
  colorsStroke = defaultStrokeColors,
  data,
  hasLegend = false,
  hasTooltip = false,
}: IChartProps) => {
  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <PieChart>
        {hasLegend && <Legend />}
        {hasTooltip && <Tooltip content={props => <CustomTooltip props={props} />} />}
        <Pie
          label
          cx="50%"
          cy="50%"
          data={data}
          dataKey="value"
          fill="#8884d8"
          isAnimationActive={false}
          outerRadius={80}>
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={colorsStroke[index % colorsStroke.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
}

const RadarChartType = ({
  className,
  colorsFill = defaultFillColors,
  colorsStroke = defaultStrokeColors,
  data,
  dataNameX,
  hasGrid = true,
  hasLegend = false,
  hasTooltip = false,
}: IChartProps) => {
  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
        {hasLegend && <Legend />}
        {hasTooltip && <Tooltip content={props => <CustomTooltip props={props} />} />}
        {hasGrid && <PolarGrid />}
        {dataNameX && <PolarAngleAxis dataKey={dataNameX} style={axesFontSize} />}
        <PolarRadiusAxis style={axesFontSize} />

        {Object.keys(data[0] || []).map((val, index) => {
          if (val === dataNameX || val === 'fullMark') return null

          return (
            <Radar
              key={`radar-${index}`}
              dataKey={val}
              fill={colorsFill[index % colorsFill.length]}
              fillOpacity={0.6}
              stroke={colorsStroke[index % colorsStroke.length]}
            />
          )
        })}
      </RadarChart>
    </ResponsiveContainer>
  )
}

const ScatterChartType = ({
  className,
  colorsStroke = defaultStrokeColors,
  data,
  hasGrid = true,
  hasLegend = false,
  hasTooltip = false,
  name,
  nameX,
  nameY,
  unitX,
  unitY,
}: IChartProps) => {
  return (
    <ResponsiveContainer className={className} width="100%" height="100%">
      <ScatterChart>
        {hasGrid && <CartesianGrid strokeDasharray="3 3" />}
        {hasTooltip && <Tooltip content={props => <CustomTooltip props={props} />} />}
        {hasLegend && <Legend />}
        <XAxis
          axisLine={strokeColor}
          dataKey="x"
          name={nameX}
          style={axesFontSize}
          tickLine={strokeColor}
          type="number"
          unit={unitX}
        />
        <YAxis
          axisLine={strokeColor}
          dataKey="y"
          name={nameY}
          style={axesFontSize}
          tickLine={strokeColor}
          type="number"
          unit={unitY}
        />
        <Scatter data={data} fill={colorsStroke[0]} name={name} />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

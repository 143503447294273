// core
import React, { useCallback, useContext, useMemo, useState } from 'react'
// API
import {
  GetSysMenuItems_sysMenuItems,
  GetSysMenuItems_sysMenuItems_childSysMenuItems as ChildItem,
} from 'api/SideMenu/types/GetSysMenuItems'
// components
import { ReactComponent as Logo } from 'assets/img/logo_default_white.svg'
import {
  ExpandableBox,
  Footer,
  Icon,
  IDefaultProps,
  SidePanel,
  TIconName,
  Translation,
} from 'components'
// libraries
import cx from 'classnames'
import { Link } from 'react-router-dom'
// layouts
import { ViewportContext } from 'layouts'
// utils
import { footerPresence, runCallback, TranslationKeys, useRedirect } from 'utils'

interface ISidemenuProps extends IDefaultProps {
  /**
   * List of all side menu items - modules defined in the DB
   */
  menuItems: GetSysMenuItems_sysMenuItems[]
  /**
   * Whether the Sidemenu is open or not
   */
  isOpen: boolean
  /**
   * Toggles the visibility of the side menu.
   */
  onRequestClose?: () => void
}

export const SideMenu = ({
  className,
  isOpen = false,
  menuItems,
  onRequestClose,
}: ISidemenuProps) => {
  const { isLargerThan } = useContext(ViewportContext)
  // JS has a inconsistent bug with sorting, slicing fixes it
  const items = useMemo(() => menuItems.slice().sort((a, b) => a.orderIndex - b.orderIndex), [
    menuItems,
  ])

  const twCSS = (): string => {
    return cx(isLargerThan['2xl'] && 'rounded-none shadow-none', className)
  }

  return (
    <SidePanel
      className={cx(twCSS(), '-left-px sm:left-0 bg-dark w-72 flex flex-col py-4 overflow-hidden')}
      hasBackdrop={!isLargerThan['2xl']}
      hasRoundedCorners={!isLargerThan['2xl']}
      hasShadow={!isLargerThan['2xl']}
      isOpen={isOpen || isLargerThan['2xl']}
      zIndex="z-40"
      onRequestClose={onRequestClose}>
      <div className="h-5 flex items-start w-full justify-end 2xl:justify-start">
        <Link to="/" onClick={onRequestClose}>
          <Logo
            className={cx(
              isOpen || isLargerThan['2xl'] ? 'opacity-100' : 'opacity-0',
              'w-36 h-5 transition-all duration-500 delay-75'
            )}
            style={{
              transform: `translateX(${isLargerThan['2xl'] ? -12 : 4}px)`,
            }}
          />
        </Link>
      </div>
      <ul className="mt-5 mb-10 space-y-2 overflow-hidden overflow-y-auto">
        {items.map(
          (item, index) =>
            item.isVisible && (
              <SideMenuItem
                key={`sideMenuItem-${index}`}
                hasSubitems={item.childSysMenuItems.length > 0}
                title={item.title}
                icon={(item.iconName as TIconName) || undefined}
                subitems={item.childSysMenuItems}
                to={item.url}
                level={item.depthLevel}
                onClick={onRequestClose}
              />
            )
        )}
      </ul>

      {/* <hr className="absolute bottom-20 inset-x-5" /> */}

      {footerPresence && <Footer className="absolute inset-x-0 bottom-10 px-6" />}
    </SidePanel>
  )
}

//  ==========  =====================  ==========

//       P A R T I A L   C O M P O N E N T S

//  ==========  =====================  ==========
interface ISideMenuItemProps extends IDefaultProps {
  /**
   * Any additional content of the items
   */
  children?: any
  hasSubitems: boolean
  /**
   * Icon name will be passed as name prop to Icon component
   *
   * @default 'home'
   */
  icon?: TIconName
  /**
   * Level of menu items - root is 0
   *
   * @default '0'
   */
  level?: number
  /**
   * Subitems
   */
  subitems?: ChildItem[]
  title: string
  /**
   * Target address
   */
  to?: string
  onClick?: () => void
}

const SideMenuItem = ({
  children,
  className,
  hasSubitems: _hasSubitems,
  icon = 'home',
  level = 0,
  subitems,
  title,
  to,
  onClick,
}: ISideMenuItemProps) => {
  const [, goTo] = useRedirect()

  const visibleSubitems = useMemo(
    // JS has a inconsistent bug with sorting, slicing fixes it
    () => subitems?.slice().sort((a, b) => a.orderIndex - b.orderIndex),
    [subitems]
  )
  const hasSubitems = visibleSubitems?.length

  const handleClick = useCallback(() => {
    if (!_hasSubitems && !hasSubitems) {
      if (to) {
        goTo().anyPage(`/${to}`)
      } else {
        goTo().dashboard()
      }
    }

    runCallback(onClick)
  }, [onClick, _hasSubitems])

  const twCSS = (): string => {
    return cx(level === 0 ? '' : level === 1 ? 'pl-14' : 'pl-18')
  }

  return !hasSubitems ? (
    <li
      className={cx(
        twCSS(),
        'group relative w-full flex items-center justify-between text-xl px-4 py-3 leading-6 font-semibold transition hover:bg-primary cursor-pointer z-20',
        className
      )}
      onClick={handleClick}>
      <span className="w-full flex space-x-3">
        {level !== 0 && (
          <span
            className={cx(
              level === 1 ? 'left-10' : 'left-14',
              'block absolute top-0 w-4 h-6 -ml-1 self-start rounded-bl-lg border-2 border-t-0 border-r-0 border-gray-700 transition group-hover:border-white'
            )}
          />
        )}
        {level === 0 && (
          <span className="flex items-center justify-center w-10">
            <Icon name={icon} />
          </span>
        )}
        <p className="w-full max-w-44 text-sm truncate whitespace-normal">
          <Translation keyValue={title as TranslationKeys} />
        </p>
      </span>
      {children}
    </li>
  ) : (
    <SideMenuItems
      className={cx(twCSS(), className)}
      hasSubitems={(subitems?.length || 0) > 0}
      icon={icon}
      level={level}
      subitems={subitems}
      title={title}
      onClick={handleClick}>
      {children}
    </SideMenuItems>
  )
}

const SideMenuItems = ({ ...passingProps }: ISideMenuItemProps) => {
  const [showSubitems, showSubitemsSet] = useState<boolean>(false)

  const twCSS = (): string => {
    return cx(
      showSubitems && passingProps.level === 0
        ? 'bg-darker'
        : showSubitems && passingProps.level === 1
        ? 'bg-black'
        : ''
    )
  }

  return (
    <ul className="relative overflow-hidden">
      <span
        className={cx(
          showSubitems ? 'block' : 'hidden',
          'absolute top-0 bottom-0 -left-0.5 w-1.5 h-full rounded-r-lg bg-primary z-30'
        )}
      />

      <SideMenuItem
        hasSubitems
        className={cx(twCSS(), passingProps.className)}
        icon={passingProps.icon}
        level={passingProps.level}
        title={passingProps.title}
        to={passingProps.to}
        onClick={() => showSubitemsSet(!showSubitems)}>
        <Icon
          className="absolute right-4 flex text-sm"
          name={showSubitems ? 'chevron-up' : 'chevron-down'}
          type="regular"
        />
      </SideMenuItem>

      <li className={passingProps.level === 0 ? 'bg-darker' : 'bg-black'}>
        <ExpandableBox expanded={showSubitems}>
          <ul>
            {passingProps.subitems?.map(
              (subitem, index) =>
                subitem.isVisible && (
                  <SideMenuItem
                    key={index}
                    hasSubitems={subitem.childSysMenuItems.length > 0}
                    icon={(subitem.iconName as TIconName) || undefined}
                    level={subitem.depthLevel}
                    subitems={subitem.childSysMenuItems as any}
                    title={subitem.title}
                    to={subitem.url}
                    onClick={passingProps.onClick}
                  />
                )
            )}
          </ul>
        </ExpandableBox>
      </li>
    </ul>
  )
}

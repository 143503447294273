// core
import React, { useCallback } from 'react'
import { IDefaultProps } from 'components'
// partials
import { WysiwygLink } from './Entities'
// functions
import { findEntities, mediaBlockRenderer, WysiwygEntities } from './functions'
// libraries
import cx from 'classnames'
import { CompositeDecorator, ContentBlock, convertFromRaw, Editor, EditorState } from 'draft-js'
// styles
import css from './WysiwygEditor.module.scss'

import 'draft-js/dist/Draft.css'

export interface IWysiwygEditorOutputProps extends IDefaultProps {
  /**
   * Editor state in JSON
   */
  content: string
}

export const WysiwygEditorOutput = ({ content, className }: IWysiwygEditorOutputProps) => {
  const getClassName = useCallback((contentBlock: ContentBlock) => {
    const type = contentBlock.getType()

    switch (type) {
      case 'unstyled':
        return css.alignLeft
      case 'center':
        return css.alignCenter
      case 'right':
        return css.alignRight
      default:
        return ''
    }
  }, [])

  const compositeDecorator = new CompositeDecorator([
    {
      component: WysiwygLink,
      strategy: findEntities(WysiwygEntities.LINK),
    },
  ])

  return (
    <div
      className={cx(
        css.editorWrap,
        'py-6 px-6 h-56 overflow-auto text-txt text-sm tracking-wide',
        className
      )}>
      <Editor
        readOnly
        blockRendererFn={mediaBlockRenderer}
        blockStyleFn={getClassName}
        editorState={EditorState.createWithContent(
          convertFromRaw(JSON.parse(content)),
          compositeDecorator
        )}
        onChange={() => {
          // must be here, but do nothing because this is readOnly and wont change
        }}
      />
    </div>
  )
}

// core
import React from 'react'
// components
import { IInputDefaultProps, InputDefault } from './InputDefault'
// libraries
import { Field, FieldProps, useFormikContext } from 'formik'
import { get } from 'lodash'
import { EDateFormats, parseDate } from 'utils'

interface IInputDateTimePickerProps<T> extends Omit<IInputDefaultProps<T>, 'value'> {
  name: string
}

/**
 * DateTime variant of `InputDefault` used within `Formik`'s forms
 *
 * @warning
 * THIS INPUT HAS TO BE PLACED WITHIN `<Formik>`
 *
 * It uses custom `useFormikContext()` hook which looks for parent Formik
 * and throws an error if it doesn't find one
 */
export const InputDateTimePicker = <T,>({ name, ...otherProps }: IInputDateTimePickerProps<T>) => {
  const { errors } = useFormikContext()

  const parseValue = (val: any) => {
    if (typeof val === 'string') return val

    const dateObj = new Date(val)

    return parseDate(dateObj, EDateFormats.ISO)
  }

  return (
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <InputDefault
          {...otherProps}
          {...fieldProps.field}
          type="datetime-local"
          error={get(errors, name)}
          value={parseValue(fieldProps.field.value)}
        />
      )}
    </Field>
  )
}

import { AccessRightsFragments } from './AccessRightsFragments'
import { gql } from '@apollo/client'

export const AccessRightsQueries = {
  GET_ACCESS_GROUP: gql`
    query GetAccessGroup($id: ID!) {
      sysAccessGroup(id: $id) {
        ...AccessGroupFragment
      }
    }

    ${AccessRightsFragments.ACCESS_GROUP}
  `,

  GET_ALL_ACCESS_GROUPS: gql`
    query GetAllAccessGroups {
      sysAccessGroups {
        ...AccessGroupFragment
      }
    }

    ${AccessRightsFragments.ACCESS_GROUP}
  `,

  GET_ALL_ACCESS_GROUPS_FOR_SELECT: gql`
    query GetAllAccessGroupsForSelect {
      sysAccessGroups {
        id
        title
      }
    }
  `,

  GET_ACCESS_RIGHT_CATEGORY: gql`
    query GetAccessRightCategory($id: ID!) {
      sysAccessRightCategory(id: $id) {
        ...AccessRighCategoryFragment
      }
    }

    ${AccessRightsFragments.ACCESS_RIGHT_CATEGORY}
  `,

  GET_ALL_ACCESS_RIGHT_CATEGORIES: gql`
    query GetAllAccessRightCategories($forType: ActionsTargetEnum, $forId: ID!) {
      sysAccessRightCategories {
        id
        ...AccessRighCategoryFragment

        extendedActions(forType: $forType, forId: $forId) {
          alias
          allowed
          locked
        }
      }
    }

    ${AccessRightsFragments.ACCESS_RIGHT_CATEGORY}
  `,

  GET_EXISTING_ACCESS_RIGHT_ACTIONS: gql`
    query GetExistingAccessRightActions {
      existingAccessRightActions
    }
  `,
}

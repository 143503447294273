// core
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
// components
import {
  Button,
  Callout,
  ContextMenu,
  getTranslation,
  Icon,
  IContextMenuItemProps,
  IDefaultProps,
  IDefaultWrapperProps,
  Input,
  Loader,
  Translation,
} from 'components'
import { ITableFilter, TableFilters } from './TableFilters'
import { ITableCheckboxConfig } from './useCheckboxes'
import { countFilters, SetActiveFilters } from './useFilter'
import { ITableSortConfig } from './useSort'
// libraries
import cx from 'classnames'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { AutoSizer, Index, ScrollEventData } from 'react-virtualized'
import {
  Column,
  ColumnProps,
  SortDirectionType,
  Table as TableVirtualized,
  TableHeaderRowProps,
  TableRowProps,
} from 'react-virtualized/dist/commonjs/Table'
import * as Yup from 'yup'
// utils
import { EKeyCodes, ICoords, IObject, stopEvent, TranslationKeys } from 'utils'
import css from './Table.module.scss'

// styles
import 'react-virtualized/styles.css'

const DEFAULT_SORT_COL = 'id'
const DEFAULT_SORT_DIRECTION: SortDirectionType = 'DESC'
const DEFAULT_COL_ALIGNMENT: TColumnAlignments = 'flex-start'
const DEFAULT_COL_FLEX: number = 1
const DEFAULT_COL_WIDTH: number = 100

type TColumnAlignments = 'flex-start' | 'center' | 'flex-end'

interface IPagination {
  start: number
  end: number
}

export interface ITableColumn<T> extends Omit<ColumnProps, 'width'> {
  /**
   * Column's alignment
   *
   * @default 'flex-start' // DEFAULT_COL_ALIGNMENT
   */
  align?: TColumnAlignments
  /**
   * If provided, renders a custom component instead of the default one
   *
   * @param cellData the value of the object from `data` in the current row and in a current column, (in other words: `cellData === (collection[rowIndex] as T)[dataKey])`)
   * @param rowData the data of the entire row (in other words: `(data[rowIndex] as T)`)
   */
  component?: (cellData: string, rowData: T) => ReactNode
  /**
   * Name of the property of the object from data collection the column needs to access/render
   */
  dataKey: keyof T
  /**
   * Flex value of a column. It's applied to both column cells and its header
   *
   * @default 1 // DEFAULT_COL_FLEX
   */
  flex?: number
  /**
   * Translation key for the column's header text
   *
   * @default dataKey
   */
  header?: TranslationKeys
  /**
   * Wild raw string to display in the column's header
   *
   * ! THIS PROP TAKES PRIORITY OVER `header` PROP !
   *
   * NOTE: this is a special use case: dynamic columns - in `LanguagesPage.tsx` the header displays the name of the lang. which isn't translated
   * so a raw string is passed, below is a check for rendering the raw string if it isn't of type `TranslationKeys`
   */
  headerRaw?: string
  /**
   * If provided, renders a custom component for inline adding (in `TableRowAdd`) instead the default text input
   */
  componentForAdd?: (formikProps: FormikProps<any>) => ReactNode
  /**
   * If provided, renders a custom component for inline editing instead the default text input
   */
  componentForEdit?: (row: TableRowProps, formikProps: FormikProps<any>) => ReactNode
  /**
   * Key of the property which should be used for sorting and also if it should be sorted
   */
  sortKey?: string
  /**
   * Used in Formik for assigning values to `Input.Field` for editing or creating entries
   *
   * @default undefined
   */
  name?: string
  /**
   * Custom column's width, used for both column's width and maxWidth
   *
   * All columns have `flexGrow={1}` by def. Setting this value will override the flex
   *
   * @default 100 // DEFAULT_COL_WIDTH
   */
  width?: number
}

/**
 * Table props with generic types for entries, create and update interfaces
 *
 * E - individual entries displayed by the table
 *
 * C - create entry interface
 *
 * U - update entry interface
 */
export interface ITableProps<E, C = any, U = any, F = any> extends IDefaultProps {
  /**
   * List of row IDs that were checked via row's checkboxes
   *
   * @default []
   */
  checkedIds?: string[]
  /**
   * ClassName will be passed to "Translation" when no data provided on table
   *
   * @default "w-full h-full flex justify-center items-center text-gray-500 font-extralight italic text-center"
   */
  classNameNoDataTitle?: string
  /**
   * ClassName of wrapper div element of table
   */
  classNameWrap?: string
  /**
   * Columns config
   */
  columns: ITableColumn<E>[]
  /**
   * Collection of data fetched from API to display
   *
   * @default []
   */
  data?: E[]
  /**
   * Title of the 'Edit' option in context menu row options
   * @default 'general.action.edit'
   */
  editRowOptionTitle?: string
  /**
   * Collection of possible filter for the Table
   */
  filters?: ITableFilter<F>[]
  /**
   * Whether table should show Error callout (also can be passed error as a string to show current error message)
   */
  error?: boolean | string
  /**
   * Initial values for creating new entry, used in `TableRowAdd` formik
   *
   * @default undefined
   */
  formikInitialsCreate?: C
  /**
   * Initial values for inline editing an entry
   *
   * @default rowData
   */
  formikInitialsUpdate?: (row: TableRowProps) => U
  /**
   * Yup schema for adding new entries, used in `TableRowAdd` formik
   *
   * @default undefined
   */
  formikSchemaCreate?: Yup.SchemaOf<C>
  /**
   * Yup schema for inline editing an entry
   *
   * @default undefined
   */
  formikSchemaUpdate?: Yup.SchemaOf<U> | ((row: TableRowProps) => Yup.SchemaOf<U>)
  /**
   * Custom height of the header - should be identical or slightly bigger that `rowHeight`
   *
   * @default '50px'
   */
  headerHeight?: number
  /**
   * Whether footer should be hidden
   */
  hideFooter?: boolean
  /**
   * Whether adding a new row should be done inline
   * This forces the table to use the `TableRowAdd` component.
   *
   * @default true
   */
  inlineAdd?: boolean
  /**
   * Whether editing a row should be done inline
   * This forces the table to use the editRow
   *
   * @default true
   */
  inlineEdit?: boolean
  /**
   * Whether the `Table` is fetching more data, usually changed w/ `onLoadMore`
   *
   * @default false
   */
  isLoading?: boolean
  /**
   * The minimum width of the Table in pixels. Used of displaying table on smaller devices. Enables horizontal scrolling
   *
   * @default 900
   */
  minWidth?: number
  /**
   * Custom height of each row
   *
   * @default '50px'
   */
  rowHeight?: number
  /**
   * Array of `ContextMenu` options for each row
   *
   * @default undefined
   */
  rowOptions?(id: string): IContextMenuItemProps[]
  /**
   * Config object of current sorting (e.g: by which key and in which direction)
   *
   * @default undefined
   */
  sort?: ITableSortConfig
  /**
   * Distance from the bottom of the list at which the `onLoadMore` is called
   *
   * @default 0
   */
  threshold?: number
  /**
   * Complete count of all entries from DB, even the un-fetched ones
   *
   * @default data.length
   */
  totalCount?: number
  /**
   * This callback has 2 states:
   *
   * 1. If `inlineAdd` prop is `false` - this is called when user clicks the `+` icon
   * in table header - for when you want custom functionality (like redirecting to a separate page)
   *
   * 2. If `inlineAdd` prop is `true` - this is called when user clicks the `✓` icon in `TableRowAdd`
   */
  onAdd?(values?: C): Promise<any>
  /**
   * Event called whenever the collection of checked row IDs changes
   */
  onCheck?: (o: ITableCheckboxConfig) => any
  onDelete?(id: string[]): Promise<any>
  //@ts-ignore
  onEdit?: (values?: U, rowId: string) => Promise<any>
  onFilter?: SetActiveFilters<F>
  /**
   * Event called whenever user reaches bottom of the scrollable area - used for fetching the next page
   */
  onLoadMore?(): Promise<any> // (params: IndexRange) => Promise<any>
  onRowClick?(rowId?: string): void
  onRowDoubleClick?(): void
  onRowRightClick?(): void
  onSort?: (key: string) => void
}

export const Table = <E, C = any, U = any>({
  checkedIds = [],
  className,
  classNameNoDataTitle,
  classNameWrap,
  columns = [],
  data = [],
  editRowOptionTitle = 'general.action.edit',
  error,
  filters = [],
  formikInitialsCreate,
  formikInitialsUpdate,
  formikSchemaCreate,
  formikSchemaUpdate,
  headerHeight = 50,
  hideFooter,
  inlineAdd = true,
  inlineEdit = true,
  isLoading,
  minWidth = 900,
  rowHeight = 50,
  rowOptions,
  sort = {
    key: DEFAULT_SORT_COL,
    direction: DEFAULT_SORT_DIRECTION,
  },
  threshold = 0,
  totalCount = data.length,
  onAdd,
  onCheck,
  onDelete,
  onEdit,
  onFilter,
  onLoadMore,
  onRowClick,
  onRowDoubleClick,
  onSort,
}: ITableProps<E, C, U>): JSX.Element => {
  const tableRef = useRef<HTMLDivElement>(null)

  // ==================== State ====================
  const [editRowId, setEditRowId] = useState<string>()
  // Currently viewed "page" - index range of items currently rendered by the table - used by TableFooter
  const [pagination, setPagination] = useState<IPagination>({ start: 0, end: 0 })
  // Toggles visibility of inline adding - renders TableRowAdd
  const [isAdding, setIsAdding] = useState<boolean>(false)
  // Toggles visibility of no data callout in case table has no data
  const [isEmpty, setIsEmpty] = useState<boolean>(true)
  // Height of the table wrapper
  const tableHeight =
    (data.length * rowHeight || rowHeight) +
    headerHeight +
    (isAdding && data.length ? rowHeight : 0)

  // Client height of table wrapper
  const tableClientHeight = tableRef.current?.clientHeight

  /** Whether the row has pre-pended components such as icons for adding, filters, context menu or checkboxes */
  const isRowManaged = !!filters.length || rowOptions || onAdd || onCheck

  // "componentDidMount"
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)

    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [isAdding, editRowId])

  // ==================== Events ====================
  /**
   * Creates object with initial values for creating new entries with `TableRowAdd`
   * @returns A default initial values object, or an override if provided via prop
   * @default
   * {
   *    [col.name || col.dataKey]: ''
   * }
   */
  const _formikInitialsCreate = (): C | any => {
    if (formikInitialsCreate) {
      return formikInitialsCreate
    }

    const initialsForCreate = {}

    columns.forEach(col => {
      if (!col.componentForAdd)
        // @ts-ignore
        initialsForCreate[col.name || col.dataKey] = ''
    })

    return initialsForCreate as C
  }

  /**
   * Creates object with initial values for editing entries
   * @returns A default initial values object, or an override if provided via prop
   * @default
   * row.rowData
   */
  const _formikInitialsUpdate = (row: TableRowProps) => {
    if (formikInitialsUpdate) {
      return formikInitialsUpdate(row)
    }

    const initialsForUpdate = {}

    columns.forEach(col => {
      if (!col.componentForEdit)
        // @ts-ignore
        initialsForUpdate[col.name || col.dataKey] = row.rowData[col.dataKey]
    })

    return initialsForUpdate as U
  }

  /**
   * Creates default Yup validation schema for inline adding and editing Formiks
   * If `formikSchemaCreate` or `formikSchemaUpdate` are provided, th
   * @returns Yup schema with hard-coded id prop and columns as rest
   */
  const _formikSchemaCreateAndUpdate = (type: 'create' | 'update', row: TableRowProps) => {
    if (type === 'create' && formikSchemaCreate) return formikSchemaCreate

    if (type === 'update' && formikSchemaUpdate)
      return typeof formikSchemaUpdate === 'function'
        ? formikSchemaUpdate(row)
        : formikInitialsUpdate

    const schema: IObject = {}

    columns.forEach(col => {
      schema[String(col.dataKey)] = Yup.string()
        .ensure()
        .defined()
    })

    return Yup.object()
      .noUnknown()
      .shape({
        id: Yup.string(), // needed to prevent TS error: "uknown cannot be used as index"
        ...schema,
      })
  }

  /**
   * Event called when the user clicks + icon in the table header
   */
  const _onAdd = useCallback(async () => {
    if (inlineAdd) {
      setIsAdding(true)
    } else {
      return onAdd?.()
    }
  }, [inlineAdd, onAdd])

  /**
   * Event called when user clicks the `X` icon in the `TableRowAdd` OR submits the form
   */
  const onCancelAdd = useCallback(
    async (values?: C, helpers?: FormikHelpers<C>) => {
      if (values && helpers)
        return onAdd?.(values)
          .then(() => setIsAdding(false))
          .catch(() => {
            // do nothing...
          })

      setIsAdding(false)
      isEmpty && setIsEmpty(true)

      return Promise.resolve()
    },
    [onAdd]
  )

  /**
   * Event called when user clicks the `X` icon in the `TableRowAdd`
   */
  const onCancelEdit = () => {
    setEditRowId(undefined)
  }

  const _onCheck = useCallback(
    (id: string) => (_value: boolean) => {
      if (onCheck) {
        onCheck({ ids: [id], type: 'toggle' })
      }
      //   searchApi.setFocus(false)    // #TODO: search
    },
    [onCheck]
  )

  const _onEdit = (rowId: string) => (values: U, helpers: FormikHelpers<U>) => {
    if (!onEdit || !editRowId) return

    onEdit(values, rowId)
      .then(() => onCancelEdit())
      .catch(() => {
        // do nothing...
      })
      .finally(() => helpers.setSubmitting(false))
  }

  /**
   * Hide no data callout and open add row in table
   */
  const onCloseNoDataCallout = useCallback(() => (_onAdd(), setIsEmpty(false)), [])

  /**
   * Event called when checkboxes are active, several items were selected and delete btn in header was clicked
   */
  const onDeleteMultiple = useCallback(async () => {
    if (!onDelete) return Promise.resolve()

    return onDelete(checkedIds).then(() => onCheck?.({ ids: [], type: 'uncheckAll' }))
  }, [checkedIds, onCheck, onDelete])

  /**
   * Event called whenever user presses the ESC key
   */
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === EKeyCodes.ESC) {
        if (editRowId) {
          onCancelEdit()
        }

        if (isAdding) {
          onCancelAdd()
        }
      }
    },
    [editRowId, isAdding]
  )

  /**
   * Event called whenever user double clicks a row
   * @param index row's index
   */
  const _onRowDoubleClick = (id: string) => () => {
    if (onRowDoubleClick) {
      onRowDoubleClick()
      return
    }

    if (onEdit) {
      setEditRowId(id)
    }
  }

  /**
   * Event method called when scrolling through the `Table` - handles fetching next page upon reaching Table's end
   */
  const onScroll = useCallback(
    (e: ScrollEventData) => {
      if (
        e.scrollTop >= e.scrollHeight - e.clientHeight - threshold! &&
        data.length !== totalCount
      ) {
        onLoadMore?.()
      }
    },
    [data]
  )

  /**
   * Check if scrolling height with wrapper's clientHeight are the same which means, that no more rows are fetched. If also totalCount is bigger than length of data, initial onLoadMore is executed
   */
  useEffect(() => {
    if (tableClientHeight === tableHeight && totalCount > data.length) onLoadMore?.()
  }, [tableClientHeight])

  /**
   * Event called when a sortable header column is clicked
   *
   * @param {string} sortBy the key which to sort by, it's the same as `column.dataKey`
   */
  const _onSort = (sortBy: string, isSortable: boolean) => () => {
    if (isSortable) {
      onSort?.(sortBy.toUpperCase())
    }
  }

  /**
   * Render method for table header
   * @param width the final width of the table
   */
  const renderHeader = (width: number) => (_props?: TableHeaderRowProps) => {
    return (
      <div className="table-header" style={{ width, height: headerHeight }}>
        {onDelete && checkedIds.length ? (
          <div className="w-full flex px-6 py-3">
            <div className="w-full">
              <Translation
                keyValue="general.label.table_checkbox_selection[count]"
                variables={{ count: checkedIds.length }}
              />
            </div>

            <Button.Delete onClick={onDeleteMultiple} />
          </div>
        ) : (
          <>
            {isRowManaged && (
              <div className="table-header-edit-cell">
                {/* ADD ICON */}
                {onAdd && (
                  <Icon
                    className="icon"
                    name="plus-circle"
                    size="2x"
                    type="regular"
                    onClick={_onAdd}
                  />
                )}

                {/* FILTERS */}
                {!!filters.length && onFilter && (
                  <TableFilters filters={filters} maxWidth={width - 112} setFilters={onFilter} /> // #NOTE: 112 is the size of add and filter icons + paddings
                )}
              </div>
            )}

            {/* HEADER CELLS */}
            {!countFilters(filters) &&
              columns.map((col, index) => {
                const isColSortable = onSort && col.sortKey

                return (
                  <div
                    key={`header_${col.dataKey}_${index}`}
                    style={{
                      width: col.width || DEFAULT_COL_WIDTH,
                      minWidth: col.minWidth,
                      flex: col.flex || DEFAULT_COL_FLEX,
                      justifyContent: col.align ?? DEFAULT_COL_ALIGNMENT,
                    }}
                    onClick={_onSort(col.sortKey || col.dataKey.toString(), !!isColSortable)}>
                    <div
                      className={cx('table-header-cell', isColSortable && 'space-x-1.5')}
                      style={{ justifyContent: col.align ?? DEFAULT_COL_ALIGNMENT }}>
                      {col.headerRaw ? (
                        <span className={cx(isColSortable && 'link')}>{col.headerRaw}</span>
                      ) : (
                        <Translation
                          className={cx(isColSortable && 'link')}
                          keyValue={col.header || (col.dataKey as TranslationKeys)}
                        />
                      )}

                      {/* SORT */}
                      {isColSortable && (
                        <Icon
                          className="text-primary"
                          name={
                            sort.key === col.sortKey && sort.direction === 'ASC'
                              ? 'sort-up'
                              : 'sort-down'
                          }
                          size="lg"
                        />
                      )}
                    </div>
                  </div>
                )
              })}
          </>
        )}
      </div>
    )
  }

  /**
   * Render method for displaying info message when there are no records within the `Table`
   */
  const renderNoData = () => (
    <Translation
      className={cx(
        'w-full h-full flex justify-center items-center text-gray-500 font-extralight italic text-center',
        classNameNoDataTitle
      )}
      keyValue="general.label.no_records"
    />
  )

  /**
   * Custom method for rendering each table row.
   *
   * This is the 2nd part of my little hack, here based on the shifted index is rendered either `TableRowAdd`, `TableLoader` or a default row
   * @param rowProps The props of each table row
   */
  const renderRow = (rowProps: TableRowProps): ReactNode => {
    const _rowId = rowProps.rowData.id
    const _rowOptions = rowOptions?.(_rowId)
      ? rowOptions(_rowId).map(option => ({
          ...option,
          parentId: _rowId,
        }))
      : []

    // Add inline edit option - should be allways the first option in context menu
    if (onEdit) {
      _rowOptions.unshift({
        id: 'edit',
        children: <Translation keyValue={editRowOptionTitle as TranslationKeys} />,
        parentId: _rowId,
        onClick: () => (inlineEdit ? setEditRowId(_rowId) : onEdit(undefined, _rowId)),
      })
    }

    // Add delete option - should be allways the last option in context menu
    if (onDelete) {
      _rowOptions.push({
        id: 'delete',
        children: <Translation keyValue="general.action.delete" />,
        isDeleteItem: true,
        parentId: _rowId,
        shouldClickBlur: true,
        onClick: async () => onDelete([_rowId]),
      })
    }

    // Using the "fake" last row, render the TableLoader
    if (isLoading && rowProps.index === data.length) {
      return <TableLoader key={_rowId} isLoading={!!isLoading} style={rowProps.style} />
    }

    return editRowId === _rowId && inlineEdit ? (
      <Formik
        key={_rowId}
        initialValues={_formikInitialsUpdate(rowProps)}
        validationSchema={_formikSchemaCreateAndUpdate('update', rowProps)}
        onSubmit={_onEdit(_rowId)}>
        {(form: FormikProps<U>) => {
          const { dirty, isValid, handleSubmit } = form

          return (
            <Form>
              <div
                className="table-rw-manage"
                style={{
                  ...rowProps.style,
                  overflow: 'visible',
                }}>
                <div className="table-header-edit-cell pl-7.5 text-txt-light">
                  {/* CANCEL EDIT ICON */}
                  <Button.Wrapper noStyles type="reset" onClick={onCancelEdit}>
                    <Icon className="icon" name="times" size="2x" type="regular" />
                  </Button.Wrapper>

                  {/* SUBMIT EDIT ICON */}
                  <Button.Wrapper
                    noStyles
                    type="submit"
                    isDisabled={!dirty || !isValid}
                    onClick={handleSubmit}>
                    <Icon
                      className={cx('icon', isValid && 'text-success')}
                      name="check"
                      size="2x"
                      type="regular"
                    />
                  </Button.Wrapper>
                </div>

                {/* INLINE EDIT CELLS w/ INPUTS */}
                {columns.map((col, index) => (
                  <div
                    key={`tableRowAdd_col_${col.dataKey}_${index}`}
                    className="h-full w-full"
                    style={{
                      flex: col.flex || DEFAULT_COL_FLEX,
                      // width: col.width || DEFAULT_COL_WIDTH,   // 1. `width` will get overwritten by above w-full, 2. it doesn't do anything anyway
                      minWidth: col.minWidth || DEFAULT_COL_WIDTH,
                    }}>
                    {col.componentForEdit ? (
                      col.componentForEdit(rowProps, form)
                    ) : (
                      <Input.Field
                        colorScheme="table"
                        classNameInput="group placeholder-italic h-full w-full text-md text-white focus:ring-0 border-none block text-sm rounded focus:text-txt-dark focus:bg-white dark:focus:bg-dark dark:focus:text-txt-light"
                        name={col.name || col.dataKey.toString()}
                        placeholder={getTranslation('general.label.table_cell_placeholder[key]', {
                          key: col.header ? getTranslation(col.header) : col.dataKey.toString(),
                        })}
                        type="text"
                      />
                    )}
                  </div>
                ))}
              </div>
            </Form>
          )
        }}
      </Formik>
    ) : (
      // Render the default table row
      <TableRow
        key={_rowId}
        menuItems={_rowOptions}
        style={{
          ...rowProps.style,
          overflow: 'visible',
        }}
        onClick={() => onRowClick?.(_rowId)}
        onDoubleClick={_onRowDoubleClick(_rowId)}>
        {isRowManaged && (
          <div className="table-row-edit-cell pl-6 pr-3">
            <div className="flex items-center justify-between">
              <ContextMenu
                isDark
                isDisabled={!(_rowOptions.length > 0)}
                classNameIcon="p-2.5"
                className="-top-7"
                direction="right"
                type={_rowOptions.length > 0 ? 'hover' : 'click'}
                header={getTranslation('general.label.action')}
                items={_rowOptions}
              />

              {/* CHECKBOXES */}
              {onCheck && (
                <Input.Checkbox
                  isDisabled={!_rowOptions.some(({ id }) => id === 'delete')}
                  value={checkedIds.includes(_rowId)}
                  onChange={_onCheck(_rowId)}
                />
              )}
            </div>
          </div>
        )}

        {/* ROW CELLS */}
        {columns.map((col, index) => (
          <TableCell
            key={`col_${index}_${col.dataKey}`}
            style={{
              width: col.width || DEFAULT_COL_WIDTH,
              minWidth: col.minWidth,
              flex: col.flex || DEFAULT_COL_FLEX,
              justifyContent: col.align ?? DEFAULT_COL_ALIGNMENT,
            }}>
            {col.component?.(String(rowProps.rowData[col.dataKey]), rowProps.rowData) || (
              <span className="truncate">{String(rowProps.rowData[col.dataKey])}</span>
            )}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  /**
   * Getter method for retrieving row data
   *
   * Technically this is not represented on UI in any way, however the `TableVirtualized` won't work w/o this.
   *
   * I added a little hack to shifting the index since the library doesnt support adding special rows within the table.
   * This allowed me to render the `TableRowAdd` before all other rows and `TableLoader` after them.
   */
  const rowGetter = ({ index }: Index) => {
    let newIndex = index

    /* #NOTE: 
        This getter works with `TableVirtualize.rowCount` prop,
        I increased it by 1 so that it will "overflow" the length of `data`
        and the index will reach the `data.length` value
        This allowed me to add additonal "fake" row after the last one
        so that the TableSpinner could be rendered indicating fetching the next page
     */
    if (index === data.length) {
      newIndex = index - 1
    }

    return data[newIndex]
  }

  return data.length || !isEmpty || isLoading || countFilters(filters) ? (
    <>
      <div className={cx(css.horizontalScroll, 'table-t', classNameWrap)}>
        <div ref={tableRef} className={cx(css.wTable, className)} style={{ height: tableHeight }}>
          <AutoSizer>
            {({ height, width }) => {
              // Full table-sized loader for initial fetching
              if (!data.length && isLoading) {
                return <TableLoader isLoading style={{ width, height }} />
              }

              // Set's the minumum table width and makes it scrollable horizontally so it won't squeeze the table on smaller devices
              const tableWidth = Math.max(minWidth, width)

              return (
                <>
                  {/* TABLE HEADER */}
                  {renderHeader(tableWidth)()}

                  {/* ADD NEW ROW */}
                  {isAdding && onAdd && (
                    <TableRowAdd<E, C>
                      columns={columns}
                      initialValues={_formikInitialsCreate()}
                      style={{
                        width: tableWidth,
                        height: rowHeight,
                      }}
                      // @ts-ignore
                      validationSchema={_formikSchemaCreateAndUpdate('create')}
                      onCancel={onCancelAdd}
                      onSubmit={onCancelAdd}
                    />
                  )}

                  <TableVirtualized
                    disableHeader
                    headerHeight={0}
                    height={height - headerHeight - (isAdding ? rowHeight : 0)}
                    // rowStyle={{ overflow: 'visible' }}
                    // gridStyle={{ overflow: 'visible' }}
                    // containerStyle={{ overflow: 'visible' }}
                    noRowsRenderer={isAdding ? undefined : renderNoData}
                    overscanRowCount={5}
                    rowCount={data.length}
                    rowGetter={rowGetter}
                    rowRenderer={renderRow}
                    rowHeight={rowHeight!}
                    //   sort={}  // ! DO NOT USE - useless since we have custom header render
                    width={tableWidth}
                    onRowsRendered={({ startIndex, stopIndex }) =>
                      setPagination({
                        start: startIndex + 1,
                        end: stopIndex + 1,
                      })
                    }
                    //   onRowRightClick={onRowRightClick}    // #NOTE: is in docs but not in .d.ts: https://github.com/bvaughn/react-virtualized/blob/master/docs/Table.md#headerrowrenderer
                    onScroll={onScroll}>
                    {columns.map(
                      (
                        { dataKey, flex = DEFAULT_COL_FLEX, minWidth, sortKey, width },
                        colIndex
                      ) => (
                        <Column
                          key={`${dataKey}_${colIndex}`}
                          className={css.column}
                          dataKey={dataKey.toString()}
                          defaultSortDirection={DEFAULT_SORT_DIRECTION}
                          disableSort={!sortKey}
                          flexGrow={flex}
                          maxWidth={width}
                          minWidth={minWidth}
                          width={width || 100}
                        />
                      )
                    )}
                  </TableVirtualized>
                </>
              )
            }}
          </AutoSizer>
        </div>
      </div>

      {!hideFooter && <TableFooter {...pagination} total={totalCount} />}
    </>
  ) : error ? (
    <Callout
      icon="exclamation-triangle"
      title={
        typeof error === 'string' ? (
          getTranslation(error as TranslationKeys)
        ) : (
          <Translation keyValue="general.response.error_loading_data" />
        )
      }
    />
  ) : (
    <Callout
      buttons={[
        {
          icon: 'plus',
          label: getTranslation('general.action.add'),
          onClick: onCloseNoDataCallout,
        },
      ]}
      icon="folder-open"
      title={<Translation keyValue="general.label.no_records" />}
      message={<Translation keyValue="general.label.no_records_message" />}
    />
  )
}

//  ==========  =====================  ==========

//       P A R T I A L   C O M P O N E N T S

//  ==========  =====================  ==========

interface ITableLoaderProps extends IDefaultProps {
  isLoading: boolean
}

const TableLoader = ({ isLoading, style }: ITableLoaderProps) =>
  isLoading ? (
    <div key="table_spinner" className="flex flex-1 items-center justify-center" style={style}>
      <Loader className="text-primary" />
    </div>
  ) : null

//

interface ITableCellProps extends IDefaultWrapperProps {}

export const TableCell = ({ children, className, style }: ITableCellProps) => (
  <div style={style}>
    <div
      className={cx('table-row-cell flex truncate', css.tableCell, className)}
      style={{ justifyContent: style?.justifyContent }}>
      {children}
    </div>
  </div>
)

//

interface ITableRowProps extends IDefaultWrapperProps {
  menuItems?: IContextMenuItemProps[]
  onClick?(): void
  onDoubleClick?(): void
}

const TableRow = ({
  children,
  className,
  menuItems = [],
  style,
  onClick,
  onDoubleClick,
}: ITableRowProps) => {
  const rowRef = useRef<HTMLDivElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [coords, setCoords] = useState<ICoords>({ x: 0, y: 0 })

  useEffect(() => {
    const rowRefCurrent = rowRef.current

    const contextMenuListener = (e: MouseEvent) => {
      if (e.target) {
        stopEvent(e)
        setCoords({ x: e.pageX, y: e.pageY })
        setIsFocused(true)
        setTimeout(() => setIsMenuOpen(true), 80) // context menu will open at exact coords and not at predefined position of modal
      }
    }

    menuItems.length > 0 && rowRefCurrent?.addEventListener('contextmenu', contextMenuListener)

    return () => rowRefCurrent?.removeEventListener('contextmenu', contextMenuListener)
  }, [])

  return (
    <div
      ref={rowRef}
      className={cx(
        'table-rw overflow-hidden',
        isFocused && 'bg-gray-100 dark:bg-darker',
        className
      )}
      style={style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}>
      <ContextMenu
        isDark
        direction="right"
        header={getTranslation('general.label.action')}
        iconProps={null}
        isOpen={isMenuOpen}
        items={menuItems.map(item => ({
          ...item,
          onClick: () => {
            item.onClick?.()
            setIsMenuOpen(false)
          },
        }))}
        requestedX={coords.x}
        requestedY={coords.y}
        type="click"
        onClose={() => {
          setIsMenuOpen(false), setIsFocused(false)
        }}
      />

      {children}
    </div>
  )
}

//

interface ITableRowAddProps<E, C = any> extends IDefaultProps {
  columns: ITableColumn<E>[]
  initialValues: C
  validationSchema: Yup.SchemaOf<C>
  onCancel(): void
  onSubmit(values: C, helpers: FormikHelpers<C>): Promise<any>
}

const TableRowAdd = <E, C = any>({
  className,
  columns,
  initialValues,
  style,
  validationSchema,
  onCancel,
  onSubmit,
}: ITableRowAddProps<E, C>) => {
  return (
    // @ts-ignore
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(form: FormikProps<C>) => {
        const { dirty, isValid, handleSubmit } = form
        return (
          <Form style={{ flex: 1 }}>
            <div
              id="tableRowAdd"
              className={cx('table-rw-manage', className)}
              style={{ ...style, overflow: 'visible' }}>
              {/* ROW MANAGEMENT ICONS */}
              <div className="table-header-edit-cell pl-7.5 text-txt-light">
                <Icon
                  className={cx('icon')}
                  name="times"
                  size="2x"
                  type="regular"
                  onClick={onCancel}
                />

                <Button.Wrapper
                  noStyles
                  type="submit"
                  isDisabled={!dirty || !isValid}
                  onClick={handleSubmit}>
                  <Icon
                    className={cx('icon', isValid && 'text-success')}
                    name="check"
                    size="2x"
                    type="regular"
                  />
                </Button.Wrapper>
              </div>

              {columns.map((col, index) => (
                <div
                  key={`tableRowAdd_col_${col.dataKey}_${index}`}
                  className="h-full w-full"
                  style={{
                    flex: col.flex || DEFAULT_COL_FLEX,
                    // width: col.width || DEFAULT_COL_WIDTH,   // 1. `width` will get overwritten by above w-full, 2. it doesn't do anything anyway
                    minWidth: col.minWidth || DEFAULT_COL_WIDTH,
                  }}>
                  {col.componentForAdd ? (
                    col.componentForAdd(form)
                  ) : (
                    <Input.Field
                      colorScheme="table"
                      classNameInput="group placeholder-italic h-full w-full text-md text-white focus:ring-0 border-none block text-sm focus:text-txt-dark focus:bg-white focus:placeholder-gray-400 dark:focus:bg-dark dark:focus:text-txt-light"
                      name={col.name || col.dataKey.toString()}
                      placeholder={getTranslation('general.label.table_cell_placeholder[key]', {
                        key: col.header ? getTranslation(col.header) : col.dataKey.toString(),
                      })}
                      type="text"
                    />
                  )}
                </div>
              ))}
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

//

interface ITableFooterProps extends IDefaultProps, IPagination {
  total: number
}

const TableFooter = ({ className, end, start, total }: ITableFooterProps) => (
  <div className={cx('py-3 text-xs text-center font-extralight', className)}>
    <Translation
      className="block"
      keyValue="general.label.table_footer[start,end,total]"
      variables={{ start, end, total }}
    />
  </div>
)

// core
import React from 'react'
// components
import { TKey } from 'components'
import { IItemUserProps, ItemUser } from 'components/Item/variants/ItemUser'
import { IListVariantProps, ListDefault } from './ListDefault'

interface IItemUser extends Omit<IItemUserProps, 'isActive' | 'onClick'> {}

interface IListUserProps extends IListVariantProps<IItemUser> {
  /**
   * Collection of generic data used to render content of individual list items - `<Item.User />`
   */
  items?: IItemUser[]
  /**
   * ID of the currently selected user
   */
  activeUserId?: TKey
  /**
   * Event called when an item is clicked
   * @param id ID of the clicked user
   */
  onClick(id: TKey): void
}

export const ListUser = ({
  className,
  classNameItem,
  items,
  activeUserId,
  onClick,
  ...otherProps
}: IListUserProps) => (
  <ListDefault<IItemUser>
    className={className}
    items={items}
    renderItem={item => (
      <ItemUser
        {...item}
        key={item.id}
        className={classNameItem}
        isActive={item.id === activeUserId}
        onClick={onClick}
      />
    )}
    {...otherProps}
  />
)

// core
import { gql } from '@apollo/client'
import { SideMenuFragments } from 'api/SideMenu/SideMenuFragments'

export const SideMenuQueries = {
  GET_SIDEMENU_ITEMS: gql`
    query GetSysMenuItems($showAll: Boolean) {
      sysMenuItems(showAll: $showAll) {
        ...SideMenuItem

        childSysMenuItems {
          ...SideMenuItem

          childSysMenuItems {
            ...SideMenuItem
          }
        }
      }
    }
    ${SideMenuFragments.SIDEMENU_ITEM}
  `,
}

// core
import React from 'react'
// components
import { getTranslation } from 'components'
import { ButtonDefault, IButtonDefaultProps } from './ButtonDefault'

interface IButtonCloseProps extends IButtonDefaultProps {}

export const ButtonClose = ({ className, label, ...otherProps }: IButtonCloseProps) => (
  <ButtonDefault
    className={className}
    color="grey"
    icon="times"
    label={label || getTranslation('general.action.close')}
    {...otherProps}
  />
)

// core
import React, {
  ComponentType,
  createContext,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react'
// API
import { init as initApollo } from 'api/Api'
import { GetSysUser, GetSysUserVariables } from 'api/User/types/GetSysUser'
import { UserQueries } from 'api/User/UserQueries'
import App from './App'
import * as serviceWorker from './serviceWorker'
// libraries
import { ApolloProvider, useQuery } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { createBrowserHistory } from 'history'
import { isMacOs } from 'react-device-detect'
import ReactDOM from 'react-dom'
import { Router, useHistory, useLocation } from 'react-router-dom'
// utils
import { configure as configureYup, EStorageKeys, logout, parsePayload, refreshToken } from 'utils'

// styles
import './index.css'
import './styles/index.scss'
// @ts-ignore
if (!isMacOs) import('styles/scrollbars.scss')
import 'tailwindcss/tailwind.css'
import 'fontawesome-free/css/all.min.css'
import 'fontawesome-pro/css/all.min.css'

export type LoggedInUserType = {
  id: string
  email: string
  fullName: string
  sysLanguageId: string
  profilePicture: {
    fileUrl: string
  } | null
}

export const LoggedInUserContext = createContext<{
  loggedInUser: LoggedInUserType | null | undefined
  setLoggedInUser: (data: LoggedInUserType | null) => void
}>({
  loggedInUser: null,
  setLoggedInUser: () => {
    // do nothing
  },
})

let ErrorBoundary: ComponentType<any> = Fragment
const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY

if (BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY || '',
    appType: 'client',
    appVersion: process.env.REACT_APP_BUILD_NUMBER || '0',
    enabledBreadcrumbTypes: [
      'navigation',
      // 'request', // leave custom breadcrubms in Apollo link
      'process',
      'log',
      'user',
      'state',
      'error',
      'manual',
    ],
    plugins: [new BugsnagPluginReact()],
    redactedKeys: ['password', 'authorization'],
  })
  const bugsnagReactPlugin = Bugsnag.getPlugin('react')
  if (bugsnagReactPlugin) {
    ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React)
  }
}

initApollo().then(client => {
  const history = createBrowserHistory()

  function AppRoot() {
    const location = useLocation()
    const history = useHistory()

    const [loggedInUser, setLoggedInUserState] = useState<LoggedInUserType | null | undefined>(
      undefined
    )

    const { data } = useQuery<GetSysUser, GetSysUserVariables>(UserQueries.GET_USER, {
      skip: !loggedInUser,
      variables: { id: loggedInUser?.id || '' },
    })

    useEffect(() => {
      if (data?.sysUser && loggedInUser) {
        // @ts-ignore
        setLoggedInUserState(user => (user ? { ...user, ...data.sysUser } : null))
      }
    }, [data?.sysUser])

    const setLoggedInUser = useCallback(
      (user: LoggedInUserType | null) => {
        setLoggedInUserState(user)

        if (!user) {
          logout(true).then(() => {
            history.push('/login', {
              prevPath: location.pathname + location.search,
            })
          })
        }
      },
      [setLoggedInUserState, location, history, client]
    )

    useEffect(() => {
      // set translations for yup
      configureYup()

      // add custom error callback to apollo client
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      client.onError = async () => {
        setLoggedInUser(null)
        return Promise.resolve()
      }

      // Check stored auth token
      const token = localStorage.getItem('token')

      if (token) {
        const payload = parsePayload(token)

        // Check if the token is expired
        if (
          payload &&
          typeof payload === 'object' &&
          payload.exp &&
          payload.exp < Date.now() / 1000
        ) {
          setLoggedInUser(null)
        }
        // Try to refresh the token
        else {
          refreshToken().then(token => {
            const payload = parsePayload(token)
            setLoggedInUser(typeof payload === 'object' ? (payload as LoggedInUserType) : null)
          })
        }

        // Send logged-in user to Bugsnag
        if (BUGSNAG_API_KEY && payload && typeof payload === 'object') {
          const handleError = (event: any) => {
            event.setUser(payload.id, payload.emailPrimary, payload.fullName)
          }
          Bugsnag.addOnError(handleError)

          return () => {
            Bugsnag.removeOnError(handleError)
          }
        }
      } else {
        // Do not redirect the user because maybe we are in set-new-password route or any other public route
        setLoggedInUserState(null)
        logout(true)
      }

      return undefined
    }, [])

    return (
      <LoggedInUserContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <App />
      </LoggedInUserContext.Provider>
    )
  }

  function AppSetup() {
    return (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <Router history={history}>
            <AppRoot />
          </Router>
        </ApolloProvider>
      </ErrorBoundary>
    )
  }

  ReactDOM.render(<AppSetup />, document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { gql } from '@apollo/client'

export const SideMenuFragments = {
  SIDEMENU_ITEM: gql`
    fragment SideMenuItem on SysMenuItem {
      id
      depthLevel
      description
      iconName
      isVisible
      moduleTitle
      moduleInfo {
        sysLanguageId
        content
      }
      orderIndex
      sysAccessRightActionKey
      parentSysMenuItemId
      #   clonesSysMenuItem
      clonesSysMenuItemId
      title
      url
    }
  `,
}

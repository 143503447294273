// core
import React, { useContext, useEffect } from 'react'
// API
import { TranslationQueries } from 'api/Translations/TranslationQueries'
import {
  GetTranslationsInLanguage,
  GetTranslationsInLanguageVariables,
} from 'api/Translations/types/GetTranslationsInLanguage'
// components
import { Loader } from 'components'
import { LoggedInUserContext } from './index'
// libraries
import { useQuery } from '@apollo/client'
// layouts
import { Layout, LayoutAuth } from 'layouts'
// utils
import { EStorageKeys } from 'utils'

export default function App() {
  const { loggedInUser } = useContext(LoggedInUserContext)

  const { loading: loadingTranslations } = useQuery<
    GetTranslationsInLanguage,
    GetTranslationsInLanguageVariables
  >(TranslationQueries.GET_TRANSLATIONS_IN_LANGUAGE, {
    variables: {
      sysLanguageId: localStorage.getItem(EStorageKeys.DEFAULT_LANG_ID), // The preffered user's language ID
      fallbackSysLanguageCodeShort: navigator.language.slice(0, 2).toUpperCase(), // The current browser language
    },
  })

  //  componentDidMount
  useEffect(() => {
    const translationsTimestamp = new Date().getTime().toString()

    // save the last translation fetch timestamp
    localStorage.setItem(EStorageKeys.TRANSLATIONS_TIMESTAMP, translationsTimestamp)
  }, [])

  /**
   * After the user logs in, update the default lang in storage with the user prefered one
   */
  useEffect(() => {
    // User's preferred lang. ID
    const sysLanguageId = loggedInUser?.sysLanguageId

    if (sysLanguageId) {
      localStorage.setItem(EStorageKeys.DEFAULT_LANG_ID, sysLanguageId)
    }
  }, [loggedInUser])

  const isLoading =
    typeof loggedInUser === 'undefined' ||
    // loadingSettings ||
    loadingTranslations

  return (
    <Loader.Wrapper isLoading={isLoading}>
      {!loggedInUser ? <LayoutAuth /> : <Layout />}
    </Loader.Wrapper>
  )
}

import { gql } from '@apollo/client'

export const AccessRightsFragments = {
  ACCESS_GROUP: gql`
    fragment AccessGroupFragment on SysAccessGroup {
      id
      title
      description
      sysUsersCount

      superiorSysAccessGroup {
        id
        title
      }

      # users under this group
      sysUsers(first: 9999, search: null) {
        edges {
          node {
            id
            email
            fullName
          }
          cursor
        }
        pageInfo {
          endCursor
        }
        totalCount
      }

      createdAt
      updatedAt
    }
  `,

  ACCESS_RIGHT_CATEGORY: gql`
    fragment AccessRighCategoryFragment on SysAccessRightCategory {
      id
      alias
      title
      description
      actions
      createdAt
      updatedAt
    }
  `,
}

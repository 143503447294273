// core
import React from 'react'
// components
import { getTranslation, Input } from 'components'
// libraries
import * as Yup from 'yup'

export const insertActionFormValidationSchema = Yup.object()
  .noUnknown()
  .shape({
    action: Yup.string()
      .ensure()
      .defined()
      .required(),
    title: Yup.string()
      .ensure()
      .defined()
      .required(),
  })
  .defined()

export type IInsertActionFormValues = Yup.InferType<typeof insertActionFormValidationSchema>

export function InsertActionForm() {
  return (
    <>
      <Input.Field className="mb-2" label={getTranslation('general.label.title')} name="title" />

      <Input.Field label={getTranslation('general.label.action')} name="action" />
    </>
  )
}

export const insertLinkFormValidationSchema = Yup.object()
  .noUnknown()
  .shape({
    url: Yup.string()
      .required()
      .url(),
  })
  .defined()

export type IInsertLinkFormValues = Yup.InferType<typeof insertLinkFormValidationSchema>

export function InsertLinkForm() {
  return <Input.Field name="url" />
}

export const insertParamFormValidationSchema = Yup.object()
  .noUnknown()
  .shape({
    param: Yup.string()
      .required()
      .ensure(),
  })
  .defined()

export type IInsertParamFormValues = Yup.InferType<typeof insertParamFormValidationSchema>

export function InsertParamForm() {
  return <Input.Field name="param" />
}

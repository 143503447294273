// core
import React from 'react'
// components
import { IDefaultWrapperProps } from 'components'
// libraries
import cx from 'classnames'

/**
 * Wrapper component for rendering a header of a column
 */
export const ColumnHeader = ({ children, className }: IDefaultWrapperProps) => (
  <div
    className={cx(
      'flex justify-between items-center px-2 pb-2 text-lg font-semibold dark:text-white',
      className
    )}>
    {children}
  </div>
)

/**
 * Wrapper component for rendering elements into a column - used for whole pages
 */
export const Column = ({ children, className }: IDefaultWrapperProps) => {
  return (
    <div
      className={cx(
        'absolute inset-0 sm:pb-18 top-28 max-h-full md:top-0 w-full md:relative pt-5 lg:px-3 lg:first:pl-2 lg:last:pr-2 z-10 bg-white dark:bg-dark md:dark:bg-transparent md:bg-transparent',
        className?.includes('pb-') ? null : 'pb-10',
        className
      )}>
      {children}
    </div>
  )
}

interface IColumnsProps extends IDefaultWrapperProps {
  /**
   * Redifining the children prop as array
   * This ensures the `Columns` will always have more than 1 `Column`, as it should
   */
  children: React.ReactElement<IDefaultWrapperProps>[]
  /**
   * Whether second column should be wider ( use only with 2 columns )
   */
  secondWider?: boolean
}

/**
 * Wrapper component <Column /> - used for whole pages
 */
export const Columns = ({ children, className, secondWider }: IColumnsProps) => {
  const columnWidthClassesArray = ['md:w-1/2', 'md:w-1/3', 'md:w-1/4', 'md:w-1/5']

  const twCSS = (index: number, length: number, className?: string): string => {
    const widthClasses = secondWider
      ? index === 0
        ? `md:w-1/3`
        : `md:w-2/3`
      : columnWidthClassesArray[length - 2] || 'md:w-1/2'

    return cx(widthClasses, className)
  }

  return (
    <div className={cx('w-full min-w-0 h-full flex md:space-x-4 lg:space-x-0', className)}>
      {children.map(
        (content, index) =>
          (Array.isArray(content.props.children)
            ? content.props.children.filter(Boolean).length > 0
            : content.props.children) &&
          React.cloneElement(content, {
            ...content.props,
            key: (content.props.id || `column-${index}`) as string,
            className: twCSS(index, children.length, content.props.className),
          })
      )}
    </div>
  )
}

// core
import React, { useCallback, useEffect, useRef, useState } from 'react'
// components
import { Icon, IDefaultProps, ITreeItemProps, Loader, Scrollable, Tooltip } from 'components'
// libraries
import cx from 'classnames'
import { DndProvider, DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import MultiBackend, { MouseTransition, TouchTransition } from 'react-dnd-multi-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
// utils
import { stopEvent, treeMarginLeftClasses } from 'utils'

/**
 * recreated HTML5toTouch because imported one doesnt pass pipeline
 */
export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
}

interface IMonitorItem {
  depth: number
  id: string
  maxNestedLevel: number
  type: string
}

interface ITreePassingProps {
  /**
   * Item that should be highlighted
   *
   * @note prop inside component, dont use from outside
   */
  activeItemId?: string | null
  /**
   * List id where option belongs. Important to correctly sort items and not mix them
   */
  listId?: string
  /**
   * Level which is last and cannot be nested more
   * @default 2
   */
  maxNestedLevel?: number
  /**
   * Children same as their parent which will be nested under in different color
   */
  parentId?: string
  /**
   * Parent ids to not be able to drop parent into child
   */
  parentIds?: string[]
}

export interface IDraggableTreeItemProps
  extends Omit<ITreeItemProps, 'children'>,
    ITreePassingProps {
  children?: IDraggableTreeItemProps[]
  /**
   * Callback to call when element is dropped
   */
  onDrop?: (movedKey: string, placeBeforeKey: string, newParentId: string | undefined) => void
}

interface ITreeProps extends IDefaultProps, ITreePassingProps {
  data: IDraggableTreeItemProps[]
  level?: number
  /**
   * Callback to call when element is dropped
   */
  onDrop?: (movedKey: string, placeBeforeKey: string, newParentId: string | undefined) => void
}

interface ISortedItem {
  id: string
  parentId?: string
}

interface IDraggableTreeProps extends IDefaultProps {
  /**
   * Item that should be highlighted
   */
  activeItemId?: string | null
  /**
   * Tree data to show
   */
  data: IDraggableTreeItemProps[]
  /**
   * If provided, renders a custom element at the left end of every item
   */
  itemEndRender?: (item: IDraggableTreeItemProps) => React.ReactNode
  /**
   * Whether the Tree is fetching data, displays a loader
   */
  isLoading?: boolean
  /**
   * Level which is last and cannot be nested more
   * @default 3
   */
  maxNestedLevel?: number
  /**
   * Callback to call when element is sorted
   */
  onSort: (newOrder: string[], movedId: string, parentId?: string) => void
}

interface ILastDropZoneProps
  extends IDefaultProps,
    Omit<ITreePassingProps, 'maxNestedLevel' | 'listId'> {
  /**
   * Id of current item
   */
  id: string
  /**
   * List id where option belongs. Important to correctly sort items and not mix them
   */
  listId: string
  /**
   * Whether dropzone should be white
   */
  white?: boolean
  /**
   * Callback to run sort
   */
  onDrop: (id: string, beforeId: string, newParentId: string | undefined) => void
}

/**
 * transforms dnd variable into state variable
 * @param variable boolean Drag and Drop variable which will be transformed into state
 */
export function useDragAndDropStateVar(variable: boolean): boolean {
  const [draggableVar, setDraggableVar] = useState<boolean>(false)

  // this useEffect has to be here, because of library css rendering bug
  useEffect(() => {
    setTimeout(() => {
      setDraggableVar(variable)
    }, 0)
  }, [variable])

  return draggableVar
}

/**
 * Hook to access dropZone
 * @param listId List id where option belongs. Important to correctly sort items and not mix them
 * @param id Id of current item
 * @param parentId Id of current item's parent
 * @param onDrop callback to happen when element is dropped
 * @returns object and ref to drop element
 */
function useDropZone(
  listId: string,
  id: string,
  parentIds: string[] | undefined,
  onDrop: (id: string, beforeId: string, newParentId: string | undefined) => void
): [{ canDrop: boolean; isDragging: boolean }, any] {
  const [{ canDrop, isDragging }, drop] = useDrop({
    accept: listId,
    canDrop: (_, monitor: DropTargetMonitor<IMonitorItem>) =>
      !parentIds?.includes(monitor.getItem().id) &&
      monitor.getItem().id !== id &&
      (parentIds || []).length + monitor.getItem().depth <= monitor.getItem().maxNestedLevel,
    collect: (monitor: DropTargetMonitor<IMonitorItem>) => ({
      canDrop: monitor.canDrop() && monitor.isOver(),
      isDragging: monitor.canDrop(),
    }),
    drop: (_, monitor: DropTargetMonitor<IMonitorItem>) => {
      onDrop(monitor.getItem().id, id, parentIds?.pop())
    },
  })

  const isDraggable = useDragAndDropStateVar(isDragging)
  const isDroppable = useDragAndDropStateVar(canDrop)

  return [{ canDrop: isDroppable, isDragging: isDraggable }, drop]
}

function LastDropZone({ id, listId, parentIds, white, onDrop }: ILastDropZoneProps) {
  const [{ canDrop, isDragging }, drop] = useDropZone(listId, id, parentIds, onDrop)

  return (
    <div
      ref={drop}
      className={cx(
        'rounded relative flex items-center justify-between py-1',
        isDragging && white ? 'bg-white' : isDragging ? 'bg-gray-100 dark:bg-gray-600' : 'hidden',
        {
          'bg-primary-light': canDrop,
        }
      )}>
      <div />
    </div>
  )
}

/**
 *  element on which you can drop another in case of nesting
 */
function NestedDropZone({ parentIds, listId, onDrop }: ILastDropZoneProps) {
  const [{ canDrop, isDragging }, drop] = useDropZone(listId, '', parentIds, onDrop)

  return (
    <div
      className={cx(
        'rounded relative flex items-center justify-between',
        isDragging ? 'bg-gray-100 dark:bg-gray-600' : 'hidden'
      )}>
      <div
        ref={drop}
        className={cx('ml-4 flex items-center justify-between rounded flex-grow py-1', {
          'bg-primary': canDrop,
        })}
      />
    </div>
  )
}

/**
 * function to get depth of tree item
 * @param children children of tree item
 */
function getDepth(children: IDraggableTreeItemProps[]): number {
  return 1 + Math.max(0, ...children.map(({ children = [] }) => getDepth(children)))
}

/**
 * Function to make tree flat
 * @param items items which should be flat
 * @param list list in which will be flat array pushed
 * @param level recursive param in which level we currently are
 * @param parent whole parent object
 */
const treeFlatterer = (
  items: IDraggableTreeItemProps[],
  list: IDraggableTreeItemProps[] = [],
  level: number = 0,
  parent: IDraggableTreeItemProps | null = null
) => {
  items.forEach(item => {
    const { children } = item
    const flatItem = {
      ...item,
      level,
      parentId: parent?.id,
    }

    list.push({ ...flatItem })

    if (children?.length) treeFlatterer(children, list, level + 1, item)
  })
}

const TreeItem = ({
  children,
  endRender,
  id,
  activeItemId,
  isLast,
  level = 0,
  listId,
  maxNestedLevel,
  parentIds,
  title,
  tooltip,
  onDrop,
  onClick,
}: IDraggableTreeItemProps) => {
  const dropRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState<boolean>(true)

  const [{ isDragging }, drag, connectPreview] = useDrag({
    canDrag: () => true,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    type: listId || '',
    item: { id, type: listId, depth: getDepth(children || []) - 1, maxNestedLevel: maxNestedLevel },
  })

  const isActive = activeItemId === id

  const isDraggable = useDragAndDropStateVar(isDragging)

  const [{ canDrop }, connectDrop] = useDropZone(listId || '', id, parentIds, onDrop!)

  connectPreview(dropRef)
  connectDrop(dropRef)

  const onToggleChildrenVisibility = useCallback((e: React.MouseEvent) => {
    stopEvent(e)
    setIsVisible(prev => !prev)
  }, [])

  const twCSS = (): string => {
    const defaultClasses =
      'flex flex-1 space-x-3 items-center py-4 pl-6 rounded text-txt-light-2 group-hover:text-white dark:text-gray-200'
    const marginLeft = treeMarginLeftClasses[level]
    const activeClasses = isActive && 'text-white'

    return cx(defaultClasses, marginLeft, activeClasses)
  }

  const newParentIds = [...(parentIds || []), id]

  return (
    <li key={id}>
      <div
        ref={dropRef}
        key={id}
        className={cx(
          'rounded relative group flex items-center justify-between',
          onClick && 'cursor-pointer hover:bg-primary dark:hover:bg-primary',
          isActive && 'bg-primary',
          !!level && 'bg-gray-100 dark:bg-black',
          { 'bg-primary-light dark:bg-gray-800': canDrop, 'bg-primary': isDraggable }
        )}
        onClick={() => onClick?.(id)}>
        {level ? (
          <span className="absolute top-0 left-9 -ml-px flex items-center h-full">
            <span
              className={cx(
                'block bg-gray-200 dark:bg-gray-700 dark:group-hover:bg-gray-200 w-px',
                isLast && !children?.length ? 'h-1/2 self-start' : 'h-full'
              )}
            />
            {Array(level)
              .fill('')
              .map((_, index) => (
                <span
                  key={index}
                  className={cx(
                    'block bg-gray-200 dark:bg-gray-700 dark:group-hover:bg-gray-200',
                    children?.length || level === 1 ? 'w-5' : 'w-8',
                    'h-px'
                  )}
                />
              ))}
          </span>
        ) : null}
        <div className={twCSS()}>
          {children?.length ? (
            <Icon
              className={cx(
                'text-txt-light flex items-center justify-center bg-gray-50 dark:bg-transparent rounded ring-1 ring-gray-300 transition hover:bg-gray-300 w-6 h-6',
                isActive && 'bg-gray-300 dark:bg-black'
              )}
              size="md"
              name={isVisible ? 'minus' : 'chevron-down'}
              onClick={onToggleChildrenVisibility}
            />
          ) : null}

          <span
            className={cx(
              'text-sm',
              (isActive || isDraggable) && 'text-white',
              (children?.length || level > 0) && 'ml-3'
            )}>
            {title}
          </span>

          {tooltip && (
            <Tooltip className="relative pl-0" icon="question-circle" side="right" {...tooltip}>
              <Icon className="text-primary" name="question-circle" type="regular" />
            </Tooltip>
          )}
        </div>

        <div className="py-4 pr-6 flex space-y-0 flex-row space-x-2 sm:space-x-4">
          {endRender}
          <Icon
            ref={drag}
            className={cx(
              'cursor-move group-hover:text-white',
              isDraggable || isActive ? 'text-white' : 'text-txt-light'
            )}
            size="lg"
            name="grip-vertical"
            type="solid"
          />
        </div>
      </div>
      {!!(listId && onDrop && !children?.length && level < (maxNestedLevel || 2)) && (
        <NestedDropZone id={id} parentIds={newParentIds} listId={listId} onDrop={onDrop} />
      )}

      {children?.length && isVisible ? (
        <div>
          <ul className="transition-all divide-y divide-light dark:divide-gray-800">
            <Tree
              activeItemId={activeItemId}
              maxNestedLevel={maxNestedLevel}
              listId={listId}
              parentIds={newParentIds}
              level={level + 1}
              data={children}
              onDrop={onDrop}
            />
          </ul>
        </div>
      ) : null}
    </li>
  )
}

const Tree = ({
  activeItemId,
  data = [],
  listId,
  level,
  maxNestedLevel,
  parentIds,
  onDrop,
  ...passingProps
}: ITreeProps) => {
  return (
    <ul className="divide-y divide-light dark:divide-gray-800">
      {data.map((tree, index) => (
        <TreeItem
          key={tree.id}
          activeItemId={activeItemId}
          level={level}
          isLast={data.length === index + 1}
          {...passingProps}
          {...tree}
          listId={listId}
          maxNestedLevel={maxNestedLevel}
          // doesn't work without copying
          parentIds={[...(parentIds || [])]}
          onClick={tree.onClick}
          onDrop={onDrop}
        />
      ))}

      {listId && onDrop && (
        <LastDropZone
          white={!level}
          listId={listId}
          // doesn't work without copying
          parentIds={[...(parentIds || [])]}
          id={`last-${[...(parentIds || [])].pop()}`}
          onDrop={onDrop}
        />
      )}
    </ul>
  )
}

export const DraggableTree = ({
  activeItemId,
  className,
  data = [],
  isLoading,
  maxNestedLevel = 2,
  onSort,
}: IDraggableTreeProps) => {
  const listId = React.useMemo(() => new Date().getTime().toString(), [])

  const onDrop = useCallback(
    (movedKey: string, placeBeforeKey: string, newParentId: string | undefined) => {
      const list: IDraggableTreeItemProps[] = []

      treeFlatterer(data, list)
      const listData: ISortedItem[] = list.map(({ id, parentId }) => ({ id, parentId }))

      const movedItem = listData.find(({ id }) => id === movedKey)

      if (movedItem) {
        movedItem.parentId = newParentId

        const insideParentOrder = listData.filter(({ parentId }) => parentId === newParentId)
        const movedItemInsideParent = insideParentOrder.findIndex(({ id }) => id === movedKey)

        if (~movedItemInsideParent) {
          insideParentOrder.splice(movedItemInsideParent, 1)
        }

        const placeBeforeIndex = insideParentOrder.findIndex(({ id }) => id === placeBeforeKey)

        let sortedItems: ISortedItem[] = []

        if (!~placeBeforeIndex) {
          insideParentOrder.push(movedItem)
        } else {
          sortedItems = insideParentOrder.slice(0, placeBeforeIndex)

          sortedItems.push(movedItem, ...insideParentOrder.slice(placeBeforeIndex))
        }

        onSort(
          (sortedItems.length ? sortedItems : insideParentOrder).map(({ id }) => id),
          movedKey,
          newParentId
        )
      }
    },
    [data, onSort]
  )

  return (
    <Scrollable>
      <div className={cx('relative', className)}>
        <Loader.Wrapper isLoading={isLoading} opacity="opacity-30">
          <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            <ul className="divide-y divide-light dark:divide-gray-800">
              {data.map((tree, index) => (
                <TreeItem
                  key={tree.id}
                  activeItemId={activeItemId}
                  isLast={data.length === index + 1}
                  {...tree}
                  listId={listId}
                  maxNestedLevel={maxNestedLevel}
                  onDrop={onDrop}
                />
              ))}

              <LastDropZone white listId={listId} id="last" onDrop={onDrop} />
            </ul>
          </DndProvider>
        </Loader.Wrapper>
      </div>
    </Scrollable>
  )
}

// #NOTE: copied from .app

import { useReducer, useState } from 'react'

export type ITableCheckboxConfig = {
  type: 'toggle' | 'check' | 'checkAll' | 'uncheck' | 'uncheckAll'
  ids: string[]
}

function reducer(checked: string[], action: ITableCheckboxConfig) {
  const { type, ids } = action

  let newChecked = [...checked]

  switch (type) {
    case 'toggle':
      if (ids.length === 0) {
        return checked
      }

      ids.forEach(id => {
        if (newChecked.includes(id)) {
          newChecked = newChecked.filter(ch => ch !== id)
        } else {
          newChecked.push(id)
        }
      })

      return newChecked
    case 'check':
      return [...checked, ...ids].filter((value, index, self) => self.indexOf(value) === index)
    case 'checkAll':
      return [...checked, ...ids].filter((value, index, self) => self.indexOf(value) === index)
    case 'uncheck':
      return ids ? checked.filter(id => !~ids.indexOf(id)) : checked
    case 'uncheckAll':
      return []
    default:
      throw new Error(`Unsupported action type "${type}"`)
  }
}

export function useCheckboxes(): [string[], (dispatch: ITableCheckboxConfig) => void, boolean] {
  const [checkedAll, setCheckedAll] = useState<boolean>(false)
  const [checked, dispatch] = useReducer(reducer, [])

  const handleDispatch = (action: ITableCheckboxConfig) => {
    setCheckedAll(action.type === 'checkAll')
    dispatch(action)
  }

  return [checked, handleDispatch, checkedAll]
}

// core
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
// components
import { Callout, ExpandableBox, Loader, TKey, Translation } from 'components'
import { IItemOptionProps, ItemOption } from 'components/Item/variants/ItemOption'
import { IListDefaultProps } from './ListDefault'
// libraries
import { AutoSizer, List, ListRowProps, ScrollEventData } from 'react-virtualized'
import { List as ListReactVirtualized } from 'react-virtualized/dist/commonjs/List'

type IItem<T> = T & Omit<IItemOptionProps, 'onClick'>

interface IListVirtualizedProps<T> extends Omit<IListDefaultProps<T>, 'renderItem'> {
  /**
   * ID of currently expanded item within the `List`
   */
  activeItemId?: TKey
  /**
   * Collection of data to loop through
   * For each item an `ItemOption` is rendered to serve as a "trigger" for the `ExpandableBox` beneath it
   *
   * @default []
   */
  items?: IItem<T>[]
  /**
   * Render method that renders custom content for `ExpandableBox` under each item
   * @param item current iteration of an item
   */
  renderItemContent(item: IItem<T>, measure?: () => void): React.ReactNode
  /**
   * Is list loading? (Initial load, not fetching more)
   *
   * @default false
   */
  isLoading?: boolean
  /**
   * Row height
   *
   * @default 60
   */
  rowHeight?: number
  /**
   * Row height when expanded
   *
   * @default 250
   */
  expandedRowHeight?: number
  /**
   * Distance from the bottom of the list at which the `onLoadMore` is called
   *
   * @default 0
   */
  threshold?: number
  /**
   * Total count of records
   */
  totalCount: number
  /**
   * Called when scrolled to the very bottom of the list
   */
  onLoadMore: () => Promise<any>
}

export const ListExpandableVirtualized = <T,>({
  activeItemId,
  className,
  expandedRowHeight = 250,
  isLoading = false,
  items = [],
  rowHeight = 60,
  threshold = 0,
  totalCount,
  onLoadMore,
  renderItemContent,
}: IListVirtualizedProps<T>) => {
  const [listItems, setListItems] = useState<(IItem<T> | { id: 'loading' })[]>([])
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const listRef = useRef<RefObject<List>>()

  const [expandedItemId, setExpandedItemId] = useState<TKey | null>(activeItemId)

  // @ts-ignore
  const isActive = useCallback((item?: T) => expandedItemId === item?.id, [expandedItemId])

  const onToggleExpand = useCallback((val: TKey) => {
    setExpandedItemId(prev => (val === prev ? null : val))
  }, [])

  useEffect(() => {
    setListItems(items)
    setIsLoadingMore(false)
  }, [items])

  useEffect(() => {
    //@ts-ignore
    listRef.current?.recomputeRowHeights()
  }, [expandedItemId])

  useEffect(() => {
    if (isLoadingMore) setListItems(prevListItems => [...prevListItems, { id: 'loading' }])
  }, [isLoadingMore])

  const _renderItem = useCallback(
    (item: ListRowProps): React.ReactNode => (
      <div key={item.key} className="w-full flex flex-col" style={{ ...item.style }}>
        {item.index === items.length && isLoadingMore ? (
          <Loader.Fullscreen />
        ) : (
          <>
            <ItemOption
              className="flex-grow-0"
              {...items[item.index]}
              key={items[item.index]?.id}
              icon={isActive(items[item.index]) ? 'chevron-up' : 'chevron-down'}
              isActive={isActive(items[item.index])}
              onClick={onToggleExpand}
            />

            <ExpandableBox className="flex-1 overflow-auto" expanded={isActive(items[item.index])}>
              {renderItemContent(items[item.index])}
            </ExpandableBox>
          </>
        )}
      </div>
    ),
    [items, isLoadingMore, isActive, onToggleExpand, renderItemContent]
  )

  const _getRowHeight = ({ index }: { index: number }) => {
    return items[index]?.id === expandedItemId ? expandedRowHeight : rowHeight
  }

  const onScroll = useCallback(
    (e: ScrollEventData) => {
      if (
        e.scrollTop >= e.scrollHeight - e.clientHeight - threshold! &&
        items.length !== totalCount
      ) {
        setIsLoadingMore(true)
        onLoadMore().finally(() => setIsLoadingMore(false))
      }
    },
    [items.length, totalCount]
  )

  return isLoading ? (
    <Loader.Fullscreen />
  ) : listItems.length ? (
    <AutoSizer>
      {({ height, width }) => (
        <ListReactVirtualized
          //@ts-ignore
          ref={listRef}
          className={className}
          height={height}
          rowCount={listItems.length}
          rowHeight={_getRowHeight}
          rowRenderer={_renderItem}
          width={width}
          onScroll={onScroll}
        />
      )}
    </AutoSizer>
  ) : (
    <Callout icon="empty-set" title={<Translation keyValue="general.label.no_records" />} />
  )
}

// core
import React from 'react'
// components
import { Button, IDefaultWrapperProps } from 'components'
import { IItemVariantProps, ItemDefault } from './ItemDefault'
// libraries
import cx from 'classnames'

interface IItemDescriptionTitleProps extends IDefaultWrapperProps {}

export const ItemDescriptionTitle = ({ children }: IItemDescriptionTitleProps) => (
  <dt className="flex space-x-4 text-sm font-medium text-gray-500 dark:text-gray-300">
    {children}
  </dt>
)

//

interface IItemDescriptionValueProps extends IDefaultWrapperProps {}

export const ItemDescriptionValue = ({ children }: IItemDescriptionValueProps) => (
  <dd className="mt-1 text-sm text-gray-900 dark:text-white">{children}</dd>
)

//

interface IItemDescriptionProps extends IItemVariantProps {
  /**
   * Whether component should take all available width
   */
  isFullWidth?: boolean
  /**
   * Element to render as a title
   */
  title: React.ReactNode
  /**
   * Element to render as a value / description
   *
   * @default 'N/A'
   */
  value: React.ReactNode
  /**
   * If provided, it renders a `Button.Edit` icon on hover
   */
  onEdit?(): void
}

export const ItemDescription = ({
  isFullWidth,
  title,
  value = 'N/A',
  onEdit,
}: IItemDescriptionProps) => {
  return (
    <ItemDefault>
      <div className={cx('group', isFullWidth ? 'sm:col-span-2' : 'sm:col-span-1')}>
        <ItemDescriptionTitle>
          {title}

          {onEdit && (
            <Button.Edit
              isIconOnly
              className="invisible group-hover:visible"
              size="small"
              onClick={onEdit}
            />
          )}
        </ItemDescriptionTitle>

        <ItemDescriptionValue>{value}</ItemDescriptionValue>
      </div>
    </ItemDefault>
  )
}

import { gql } from '@apollo/client'

export const NotificationsMutations = {
  MARK_NOTIFICATION_SEEN: gql`
    mutation MarkNotificationSeen($all: Boolean!, $notificationIds: [ID!]!, $seen: Boolean!) {
      markNotificationsAsSeen(all: $all, notificationIds: $notificationIds, seen: $seen) {
        id
        title
        seen
      }
    }
  `,

  DELETE_NOTIFICATIONS: gql`
    mutation DeleteNotifications($all: Boolean!, $notificationIds: [ID!]!) {
      deleteNotifications(all: $all, notificationIds: $notificationIds)
    }
  `,
}

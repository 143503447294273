// core
import { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { EARC, ERoutes } from './constants'
import { IObject } from './interfaces'
import _ from 'lodash'
import { useHistory, useParams } from 'react-router'
import { IURLParamsBlogs } from 'pages/Blog/BlogPage'
import { IURLParamsUser } from 'pages/ProfileManagement/ProfileManagementPage'
import { IURLParamsScheduler } from 'pages/Scheduler/SchedulerPage'
import { EWindowWidth, IViewport, TBreakpoints } from 'utils'
import { IURLParamsTower } from 'pages/Tower/TowerPage'

/**
 * A custom hook for loging errors into the console
 * @param origin Where the error originated from (name of the file / method)
 * @param errors list of errors
 * @example
 * // Instead of always writing this:
 * if (query_a_error || query_b_error || query_c_error) {
 *  console.error(query_a_error, query_b_error, query_c_error)
 * }
 *
 * // You can now use this hook:
 * useConsoleErrors('LoginPage', query_a_error, query_b_error, query_c_error)
 */
export function useConsoleErrors(origin: string, ...errors: any[]) {
  useEffect(() => {
    if (errors.length) {
      //   console.group(origin)
      errors.forEach(e => e && console.error(`Error occured at ${origin}:\n`, e))
      //   console.groupEnd()
    }
  }, [origin, errors])
}

//  ==============================
//          R O U T I N G
//  ==============================

type TVoid = () => void
type TMandatoryParams<P> = (params: P) => void
type TOptionalParams<P> = (params?: P) => void

export interface IRouterState {
  backURL: string
}

interface IRedirectConfig {
  actionScheduler: TMandatoryParams<IURLParamsScheduler>
  /**
   * Redirects user to page with provided URL
   *
   * ! NOTE ! - DO NOT USE THIS, this is only for dynamic routes such as side menu items
   */
  anyPage: (URL: string) => void
  /**
   * Redirects the user to the previous page
   */
  back: (URL?: string) => void
  blogs: (
    /**
     * Blogs route can be skipped, so history does not contain `/blogs` even tho it was not visited
     *
     * Disclaimer: use this only if navigating to some of the nested routes..
     */
    skip?: boolean
  ) => {
    detail: TMandatoryParams<IURLParamsBlogs>
    preview: TMandatoryParams<IURLParamsBlogs>
  }
  dashboard: TVoid
  tower: TMandatoryParams<IURLParamsTower>
  users: (
    /**
     * Users route can be skipped, so history does not contain `/users` even tho it was not visited
     *
     * Disclaimer: use this only if navigating to some of the nested routes..
     */
    skip?: boolean
  ) => {
    create: TVoid
    detail: TMandatoryParams<IURLParamsUser>
  }
}

export const useRedirect = <T, F = any>(): [T, () => IRedirectConfig] => {
  const history = useHistory()
  const params = useParams() as T

  const goTo = <T = IObject<string>>(URL: ERoutes, params?: T) => {
    let _URL = String(URL)

    // Replace ":variables" from URL with provided params data

    for (const key in params) {
      // @ts-ignore
      _URL = _URL.replace(key, params[key])
    }

    // Remove ":" from URLs
    _URL = _URL.replace(/:/g, '')

    history.push(_URL, { backURL: history.location.pathname } as IRouterState)
  }

  return [
    params,
    (): IRedirectConfig => ({
      actionScheduler: (p: IURLParamsScheduler) => {
        const query: string[] = []

        Object.keys(p.cols).forEach(k =>
          query.push(`${k}ColContent=${p.cols[k as keyof IURLParamsScheduler['cols']]}`)
        )

        Object.keys(p.params).forEach(k => {
          const val = p.params[k as keyof IURLParamsScheduler['params']]

          if (val) query.push(`${k}=${val}`)
        })

        return goTo(`${ERoutes.ACTION_SCHEDULER}?${query.join('&')}` as ERoutes)
      },

      anyPage: url => goTo(url as ERoutes),

      back: url => (url ? history.replace(url) : history.goBack()),

      blogs: (skip?: boolean) => {
        if (!skip) {
          goTo(ERoutes.BLOGS)
        }

        return {
          detail: (params: IURLParamsBlogs) => goTo(ERoutes.BLOGS_DETAIL, params),
          preview: (params: IURLParamsBlogs) => goTo(ERoutes.BLOGS_PREVIEW, params),
        }
      },

      dashboard: () => goTo(ERoutes.DASHBOARD),

      tower: (params: IURLParamsTower) => goTo(ERoutes.TOWER, params),

      users: (skip?: boolean) => {
        if (!skip) {
          goTo(ERoutes.USERS)
        }

        return {
          create: () => goTo(ERoutes.USERS_CREATE),
          detail: (params: IURLParamsUser) => goTo(ERoutes.USERS_DETAIL, params),
        }
      },
    }),
  ]
}

//  ==============================
//          S E A R C H
//  ==============================

type State = {
  isHidden?: boolean
  /**
   * Current search value
   */
  value?: string | null
}

export type SearchContextOptions = State & {
  /**
   * Set search value
   */
  setValue: (value: string | null) => void
  setHidden: (hidden: boolean) => void
  /**
   * Reset search to initial values
   */
  reset: () => any
}

const defaultOptions: SearchContextOptions = {
  isHidden: false,
  value: null,
  reset: () => {
    // do nothing
  },
  setHidden: _ => {
    // do nothing
  },
  setValue: () => {
    // do nothing
  },
}

export const SearchContext = createContext<SearchContextOptions>(defaultOptions)

function reducer(
  state: State,
  { type, value }: { type: 'value'; value: string | null } | { type: 'reset'; value: undefined }
) {
  switch (type) {
    case 'value':
      return { ...state, value: value ? value.toString() : null }
    case 'reset':
      return {
        ...state,
        value: undefined, // set to undefined to let component initialize value form local storage
      }
    default:
      throw new Error()
  }
}

export function useSearchProvider(): SearchContextOptions {
  const [searchApi, dispatch] = useReducer(reducer, {
    value: undefined,
  })

  const [isHidden, setHidden] = useState<boolean>(true)

  const contextOptions = useMemo(
    () => ({
      ...searchApi,
      isHidden,
      reset: () => {
        dispatch({ type: 'reset', value: undefined })
        setHidden(false)
      },
      setHidden,
      setValue: (value: string | null) => dispatch({ type: 'value', value }),
    }),
    [searchApi, dispatch, isHidden, setHidden]
  )

  return contextOptions
}

export const useSearch = (EARC: EARC): string | null => {
  const didMount = useRef(false)

  const localStorageKey = `search-${EARC}`

  const search = useContext(SearchContext)

  // must run before getting value from local storage
  useEffect(() => {
    search.reset()

    // load value from local storage
    const value = localStorage.getItem(localStorageKey)
    search.setValue(value || null)

    return () => search.setHidden(true)
  }, [EARC])

  // store value to local storage
  useEffect(() => {
    // do not run on initial mount
    if (didMount.current) {
      // set on change
      if (search.value) {
        localStorage.setItem(localStorageKey, search.value)
      }
      // remove item
      else {
        localStorage.removeItem(localStorageKey)
      }
    } else {
      didMount.current = true
    }
  }, [search.value])

  return search.value || null
}

// ==============================
//          R E S I Z E
// ==============================
/**
 * A custom hook for getting width of window intime on resize
 * @returns windowWidth as number
 * @usage hook can be used by comparing returned windowWidth with breakpoints from 'EWindowWidth'
 */
export function useWindowWidth(): IViewport {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    function onResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', onResize)

    // initial window update
    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [])

  const breakpointNames = Object.keys(EWindowWidth).filter(key =>
    isNaN(key as any)
  ) as TBreakpoints[]

  return {
    windowWidth,
    isLargerThan: breakpointNames.reduce(
      (arr, val) => ({
        ...arr,
        [val]: windowWidth > EWindowWidth[val],
      }),
      {}
    ) as IViewport['isLargerThan'],
    isSmallerThan: breakpointNames.reduce(
      (arr, val) => ({
        ...arr,
        [val]: windowWidth < EWindowWidth[val],
      }),
      {}
    ) as IViewport['isSmallerThan'],
  }
}

export const PageTitleContext = createContext<{ title?: string }>({ title: undefined })

// core
import React from 'react'
// components
import { getTranslation, Icon, TIconName } from 'components'
// libraries
import cx from 'classnames'
import { Slide, toast, ToastContainer, ToastOptions, ToastProps } from 'react-toastify'
// utils
import { IObject, TranslationKeys } from 'utils'

// styles
import 'react-toastify/dist/ReactToastify.css'

const CloseButton = ({ closeToast }: IObject<any>) => {
  return (
    <Icon
      className="text-gray-500"
      name="times"
      size="sm"
      type="regular"
      onClick={() => closeToast}
    />
  )
}

export const Toast: React.FC<Partial<ToastProps>> = (props: Partial<ToastProps>) => {
  return (
    <ToastContainer
      hideProgressBar
      newestOnTop
      closeButton={CloseButton}
      toastClassName="bg-white rounded-lg p-4"
      transition={Slide}
      {...props}
    />
  )
}

interface IToastProps {
  /**
   * Option to display icon on the left side of the header - prop will be passed as name prop to the Icon component
   *
   * @default 'info-circle'
   */
  icon?: TIconName
  /**
   * Translation key for additional text with extra information to display below the `title`
   */
  message?: TranslationKeys
  /**
   * Variables for the `message` translation key
   */
  messageVariables?: IObject
  /**
   * Translation key for the main text to display
   */
  title: TranslationKeys
  /**
   * Variables for the `title` translation key
   */
  titleVariables?: IObject
  /**
   * Default toast props
   */
  toastProps?: ToastOptions
}

const ToastContent = ({
  icon = 'info-circle',
  message,
  messageVariables,
  title,
  titleVariables,
  toastProps,
}: IToastProps) => {
  const iconColor = cx(
    toastProps?.type === 'error' && 'text-danger',
    toastProps?.type === 'success' && 'text-success',
    toastProps?.type === 'warning' && 'text-yellow-500'
  )

  return (
    <div className="flex items-start">
      <div className={cx(iconColor, 'flex-shrink-0 text-gray-400')}>
        <Icon name={icon} />
      </div>

      <div className="ml-3 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900">{getTranslation(title, titleVariables)}</p>
        {message && (
          <p className="mt-1 text-sm text-gray-500">{getTranslation(message, messageVariables)}</p>
        )}
      </div>
    </div>
  )
}

/**
 * Unified method for displaying the global Toast, if not specified, defaults to `info` toast
 * @param message Additional text with extra information to display below the `title`
 * @param icon Will be rendered on the right side of the title
 * @param title The main text to display
 * @param toastConfig Configuration for the Toast
 */
export const showToast = (
  title: TranslationKeys,
  titleVariables?: IObject,
  message?: TranslationKeys,
  messageVariables?: IObject,
  icon?: TIconName,
  toastConfig?: ToastOptions
) =>
  toast(
    <ToastContent
      title={title}
      titleVariables={titleVariables}
      icon={icon}
      message={message}
      messageVariables={messageVariables}
    />,
    {
      autoClose: 2500,
      toastId: 'default',
      type: 'info',
      ...toastConfig,
    }
  )

/**
 * Specific method for displaying ERROR Toast only
 */
export const showToastErr = (
  title: TranslationKeys,
  titleVariables?: IObject,
  message?: TranslationKeys,
  messageVariables?: IObject,
  toastConfig?: ToastOptions
) => {
  toast(
    <ToastContent
      title={title}
      titleVariables={titleVariables}
      icon="exclamation-circle"
      message={message}
      messageVariables={messageVariables}
    />,
    {
      toastId: 'error',
      type: 'error',
      ...toastConfig,
    }
  )
}

/**
 * Specific method for displaying SUCCESS Toast only
 */
export const showToastOk = (
  title: TranslationKeys,
  titleVariables?: IObject,
  message?: TranslationKeys,
  messageVariables?: IObject,
  toastConfig?: ToastOptions
) => {
  toast(
    <ToastContent
      title={title}
      titleVariables={titleVariables}
      icon="check-circle"
      message={message}
      messageVariables={messageVariables}
    />,
    {
      autoClose: 2500,
      toastId: 'ok',
      type: 'success',
      ...toastConfig,
    }
  )
}

/**
 * Specific method for displaying WARNING Toast only
 */
export const showToastWarn = (
  title: TranslationKeys,
  titleVariables?: IObject,
  message?: TranslationKeys,
  messageVariables?: IObject,
  toastConfig?: ToastOptions
) => {
  toast(
    <ToastContent
      title={title}
      titleVariables={titleVariables}
      icon="exclamation-triangle"
      message={message}
      messageVariables={messageVariables}
    />,
    {
      autoClose: 4000,
      toastId: 'warning',
      type: 'warning',
      ...toastConfig,
    }
  )
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Action codes
 */
export enum ActionCode {
  FLUSH_TRAYS = "FLUSH_TRAYS",
  SET_CAMERA_CAPTURE_INTERVAL = "SET_CAMERA_CAPTURE_INTERVAL",
  SET_FERTILIZER_PUMP_STATUS = "SET_FERTILIZER_PUMP_STATUS",
  SET_LIGHT_STATUS = "SET_LIGHT_STATUS",
  SET_SOLENOID_STATUS = "SET_SOLENOID_STATUS",
  SET_SPRINKLER_SYSTEM_STATUS = "SET_SPRINKLER_SYSTEM_STATUS",
  SET_TRAY_STATUS = "SET_TRAY_STATUS",
}

/**
 * days of the week
 */
export enum ActionDayOfWeek {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

/**
 * Action trigger ops
 */
export enum ActionTriggerOperator {
  GTE = "GTE",
  LTE = "LTE",
}

/**
 * Action trigger types
 */
export enum ActionTriggerValueType {
  AIR_TEMPERATURE = "AIR_TEMPERATURE",
  CO2_PPM = "CO2_PPM",
  PLANT_TEMPERATURE = "PLANT_TEMPERATURE",
  TIME = "TIME",
  WATER_PH_LEVEL = "WATER_PH_LEVEL",
  WATER_TEMPERATURE = "WATER_TEMPERATURE",
}

/**
 * Actions for who
 */
export enum ActionsTargetEnum {
  SYS_ACCESS_GROUP = "SYS_ACCESS_GROUP",
  SYS_USER = "SYS_USER",
}

/**
 * Activity log actions
 */
export enum ActivityLogActions {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

/**
 * Order by keys for blogs query
 */
export enum BlogOrderByKeys {
  PUBLISHED_AT = "PUBLISHED_AT",
  TITLE = "TITLE",
}

/**
 * Device statuses
 */
export enum DeviceActiveStatus {
  OFF = "OFF",
  ON = "ON",
}

/**
 * Device status mode, whether the `status` change was triggered automaticaly by scheduled actions or manually
 */
export enum DeviceStatusMode {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

/**
 * Device types
 */
export enum DeviceType {
  FLUSHING_MECHANISM = "FLUSHING_MECHANISM",
  LED_LIGHTS = "LED_LIGHTS",
  PERISTALTIC_PUMP_FOR_FERTILIZERS = "PERISTALTIC_PUMP_FOR_FERTILIZERS",
  SOLENOID_VALVE = "SOLENOID_VALVE",
  SPRINKLER_SYSTEM = "SPRINKLER_SYSTEM",
  TAP_WATER_VALVE = "TAP_WATER_VALVE",
  WATER_PUMP_FOR_TRAYS = "WATER_PUMP_FOR_TRAYS",
}

/**
 * Filter category join operations
 */
export enum FilterCategoryOps {
  AND = "AND",
  OR = "OR",
}

/**
 * Filter operations
 */
export enum FilterOps {
  EQ = "EQ",
  GTE = "GTE",
  LTE = "LTE",
}

/**
 * Possible orderBy directions.
 */
export enum OrderByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/**
 * Sensor data source
 */
export enum SensorDataSourceType {
  TOWER = "TOWER",
  TRAY = "TRAY",
}

/**
 * All sensor data types
 */
export enum SensorDataType {
  AMBIENT_AIR_CO2_PPM = "AMBIENT_AIR_CO2_PPM",
  AMBIENT_AIR_HUMIDITY = "AMBIENT_AIR_HUMIDITY",
  AMBIENT_AIR_TEMPERATURE = "AMBIENT_AIR_TEMPERATURE",
  PLANT_TEMPERATURE = "PLANT_TEMPERATURE",
  WATER_LEVEL = "WATER_LEVEL",
  WATER_PH_LEVEL = "WATER_PH_LEVEL",
  WATER_TEMPERATURE = "WATER_TEMPERATURE",
}

/**
 * Possible datatypes of contact type
 */
export enum SysContactTypeTypes {
  EMAIL = "EMAIL",
  LINK = "LINK",
  PHONE = "PHONE",
  TEXT = "TEXT",
}

/**
 * Available heights of widgets
 */
export enum SysDashboardWidgetHeights {
  EXTRA_LARGE = "EXTRA_LARGE",
  FULLSCREEN = "FULLSCREEN",
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
}

/**
 * Device types that can have specific widget placement
 */
export enum SysDashboardWidgetPlacementDeviceTypes {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
  TABLET = "TABLET",
}

/**
 * Order by keys for translations query
 */
export enum SysTranslationOrderByKeys {
  CREATED_AT = "CREATED_AT",
  KEY = "KEY",
}

/**
 * Order by keys for users query
 */
export enum SysUsersOrderByKeys {
  CREATED_AT = "CREATED_AT",
  EMAIL = "EMAIL",
  FULLNAME = "FULLNAME",
  NAME_FIRST = "NAME_FIRST",
  NAME_LAST = "NAME_LAST",
}

/**
 * Type of template
 */
export enum TemplateType {
  EMAIL = "EMAIL",
  STATIC_PAGE = "STATIC_PAGE",
  SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION",
}

/**
 * Channels
 */
export enum TowerThresholdBreachedNotificationSettingChannel {
  EMAIL = "EMAIL",
  PUSH = "PUSH",
  SMS = "SMS",
  SYSTEM = "SYSTEM",
}

/**
 * Object for creating records
 */
export interface ActionInputCreate {
  code: ActionCode;
  duration?: number | null;
  actionValue: string;
  towerId: string;
  trayId?: string | null;
  daysOfWeek: ActionDayOfWeek[];
  validSince?: any | null;
  validUntil?: any | null;
  trigger: ActionTriggerInput;
  name?: string | null;
}

/**
 * Object for updating records
 */
export interface ActionInputUpdate {
  code?: ActionCode | null;
  duration?: number | null;
  actionValue?: string | null;
  towerId: string;
  trayId?: string | null;
  daysOfWeek?: ActionDayOfWeek[] | null;
  validSince?: any | null;
  validUntil?: any | null;
  trigger: ActionTriggerInput;
  name?: string | null;
}

/**
 * Object for manipulating ActionTrigger
 */
export interface ActionTriggerInput {
  valueType: ActionTriggerValueType;
  thresholdValue?: string | null;
  operator?: ActionTriggerOperator | null;
}

/**
 * Object for creating blog
 */
export interface BlogInputCreate {
  title: string;
  publishedAt?: any | null;
  canBePublished?: boolean | null;
  content?: string | null;
  sysLanguageId: string;
  images?: any[] | null;
  videos?: any[] | null;
}

/**
 * Object for updating blog
 */
export interface BlogInputUpdate {
  title?: string | null;
  publishedAt?: any | null;
  canBePublished?: boolean | null;
  content?: string | null;
  sysLanguageId?: string | null;
  images?: any[] | null;
  videos?: any[] | null;
}

/**
 * OrderBy config for blogs query
 */
export interface BlogsOrderBy {
  key: BlogOrderByKeys;
  direction: OrderByDirection;
}

/**
 * Data input for creating menu items
 */
export interface CreateSysMenuItemInput {
  clonesSysMenuItemId?: string | null;
  description?: string | null;
  iconName?: string | null;
  moduleTitle?: string | null;
  moduleInfo?: UpdateSysMenuModuleInfoInput[] | null;
  isVisible?: boolean | null;
  parentSysMenuItemId?: string | null;
  sysAccessRightActionKey?: string | null;
  title?: string | null;
  url?: string | null;
}

/**
 * Date filter input
 */
export interface DateFilter {
  op: FilterOps;
  value?: any | null;
}

/**
 * Date filter category
 */
export interface DateFilterCategory {
  filters: DateFilter[];
  op: FilterCategoryOps;
}

/**
 * Object for updating records
 */
export interface SensorInputUpdate {
  name?: string | null;
  hardwareId?: string | null;
  thresholdMin?: number | null;
  thresholdMax?: number | null;
}

/**
 * Access group creation data
 */
export interface SysAccessGroupInputCreate {
  title: string;
  description?: string | null;
  superiorSysAccessGroupId?: string | null;
}

/**
 * Access group update data
 */
export interface SysAccessGroupInputUpdate {
  title?: string | null;
  description?: string | null;
  superiorSysAccessGroupId?: string | null;
}

/**
 * Category creation input
 */
export interface SysAccessRightCategoryInputCreate {
  alias: string;
  title: string;
  description?: string | null;
  actions: string[];
}

/**
 * Category update input
 */
export interface SysAccessRightCategoryInputUpdate {
  alias?: string | null;
  title?: string | null;
  description?: string | null;
  actions?: string[] | null;
}

/**
 * Create data
 */
export interface SysContactTypeInputCreate {
  title: string;
  type: SysContactTypeTypes;
}

/**
 * Update data
 */
export interface SysContactTypeInputUpdate {
  title?: string | null;
  type?: SysContactTypeTypes | null;
}

/**
 * Input data for creating dashboard widget
 */
export interface SysDashboardWidgetInputCreate {
  key: string;
  sysAccessRightActionKey?: string | null;
  title: string;
}

/**
 * Input data for updating dashboard widget
 */
export interface SysDashboardWidgetInputUpdate {
  key?: string | null;
  sysAccessRightActionKey?: string | null;
  title?: string | null;
}

/**
 * Object for creating new option
 */
export interface SysLanguageInputCreate {
  title: string;
  codeShort: string;
  isDefault: boolean;
  production: boolean;
}

/**
 * Object for updating option
 */
export interface SysLanguageInputUpdate {
  codeShort?: string | null;
  title?: string | null;
  isDefault?: boolean | null;
  production: boolean;
}

/**
 * Object for creating records
 */
export interface SysSettingInputCreate {
  title: string;
  key: string;
}

/**
 * Object for updating content of the setting
 */
export interface SysSettingInputUpdate {
  title?: string | null;
  key?: string | null;
}

/**
 * Object for creating setting's variable
 */
export interface SysSettingVariableInputCreate {
  title: string;
  key: string;
  value: string;
}

/**
 * Object for updating setting's variable
 */
export interface SysSettingVariableInputUpdate {
  title?: string | null;
  key?: string | null;
  value?: string | null;
}

/**
 * Object for updating template's content
 */
export interface SysTemplateContentInputUpdate {
  sysLanguageId: string;
  subject: string;
  content: string;
  updatedAt?: any | null;
}

/**
 * Object for creating records
 */
export interface SysTemplateInputCreate {
  title: string;
  description?: string | null;
  key: string;
  type: TemplateType;
}

/**
 * Object for updating content of the template
 */
export interface SysTemplateInputUpdate {
  title?: string | null;
  description?: string | null;
  key?: string | null;
  type?: TemplateType | null;
}

/**
 * Object for creating records
 */
export interface SysTemplateTagInputCreate {
  tag: string;
  description: string;
  sysTemplateId?: string | null;
}

/**
 * Object for updating record
 */
export interface SysTemplateTagInputUpdate {
  tag?: string | null;
  description?: string | null;
  sysTemplateId?: string | null;
}

/**
 * Translation input for a key
 */
export interface SysTranslationInput {
  sysLanguageId: string;
  translation: string;
}

/**
 * OrderBy config for sysUsers query
 */
export interface SysTranslationsOrderBy {
  key: SysTranslationOrderByKeys;
  direction: OrderByDirection;
}

/**
 * Contact data input
 */
export interface SysUserContactInput {
  contactTypeId: string;
  title: string;
  value: string;
}

/**
 * Update input for user obj.
 */
export interface SysUserInputUpdate {
  bio?: string | null;
  cellPhone?: any | null;
  contacts?: SysUserContactInput[] | null;
  education?: string | null;
  name?: SysUserNameInputUpdate | null;
  school?: string | null;
  workPosition?: string | null;
  profilePictureFile?: any | null;
  sysLanguageId?: string | null;
}

/**
 * Input type for updating user name
 */
export interface SysUserNameInputUpdate {
  first?: string | null;
  last?: string | null;
}

/**
 * Data input for assigning widget
 */
export interface SysUserSysDashboardWidgetInputCreate {
  deviceType?: SysDashboardWidgetPlacementDeviceTypes | null;
  options?: string | null;
  rowHeight?: SysDashboardWidgetHeights | null;
  rowIndex: number;
  startsAtIndex: number;
  sysDashboardWidgetId: string;
  width: number;
}

/**
 * Data input for updating widget
 */
export interface SysUserSysDashboardWidgetInputUpdate {
  deviceType?: SysDashboardWidgetPlacementDeviceTypes | null;
  options?: string | null;
  rowHeight?: SysDashboardWidgetHeights | null;
  rowIndex?: number | null;
  startsAtIndex?: number | null;
  sysDashboardWidgetId?: string | null;
  width?: number | null;
}

/**
 * OrderBy config for sysUsers query
 */
export interface SysUsersOrderBy {
  key: SysUsersOrderByKeys;
  direction: OrderByDirection;
}

/**
 * Variables for sending templates
 */
export interface TemplateVariableInput {
  name: string;
  value: string;
}

/**
 * Object for updating records
 */
export interface TowerInputUpdate {
  name?: string | null;
  macAddress?: string | null;
}

/**
 * Data input for updating menu items
 */
export interface UpdateSysMenuItemInput {
  clonesSysMenuItemId?: string | null;
  description?: string | null;
  iconName?: string | null;
  isVisible?: boolean | null;
  moduleTitle?: string | null;
  moduleInfo?: UpdateSysMenuModuleInfoInput[] | null;
  parentSysMenuItemId?: string | null;
  sysAccessRightActionKey?: string | null;
  title?: string | null;
  url?: string | null;
}

/**
 * Update of Menu Item description
 */
export interface UpdateSysMenuModuleInfoInput {
  content: string;
  sysLanguageId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

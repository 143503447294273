// .core
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
// components
import { Icon, Translation } from 'components'
import { FilePreview, IInputFileProps } from './InputFile'
// libraries
import cx from 'classnames'
import { Field, FieldProps, useFormikContext } from 'formik'
import mimeTypes from 'mime-types'
import prettyBytes from 'pretty-bytes'
import { useDropzone } from 'react-dropzone'
// utils
import { convertToBytes } from 'utils'

interface IInputFileInlineProps extends Omit<IInputFileProps, 'trigger' | 'formikName'> {
  /**
   * `name` for Formik
   */
  name: string
}

export const InputFileInline = ({
  accept,
  className,
  enableReinitialize,
  id,
  initialFiles,
  isDisabled,
  maxSize = convertToBytes(64, 'MB'), // 64MB
  multiple,
  name,
  onChange,
}: IInputFileInlineProps) => {
  const { setFieldValue } = useFormikContext()

  const [files, setFiles] = useState<File[]>(
    (initialFiles ? (multiple ? initialFiles : [initialFiles]) : []) as File[]
  )

  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false)
  const [fileUploadError, setFileUploadError] = useState<boolean>(false)

  useEffect(() => {
    const newFiles = ((multiple ? initialFiles : [initialFiles]) as (File | undefined)[]).map(
      file => file?.name
    )
    const oldFiles = files.map(({ name }) => name)

    if (enableReinitialize && JSON.stringify(newFiles) !== JSON.stringify(oldFiles)) {
      const fileValue = (initialFiles ? (multiple ? initialFiles : [initialFiles]) : []) as File[]

      setFiles(fileValue)
    }
  }, [initialFiles])

  const { getRootProps: getDropContainerProps } = useDropzone({
    accept: accept?.split(',').map(mediaType => mediaType.trim()),
    maxSize,
    onDragEnter: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false),
    onDrop: files => {
      _onChange(undefined, files)
      setIsDraggingOver(false)
    },
  })

  const acceptedFileExtensions = useMemo(
    () =>
      accept
        ?.split(',')
        .map(type => mimeTypes.extension(type.trim().toLowerCase()))
        .filter(Boolean)
        .join(', ')
        .toUpperCase(),
    [accept]
  )

  const commonInputProps = useMemo(
    () => ({
      accept,
      className: 'sr-only',
      disabled: isDisabled,
      id: name,
      multiple,
      size: maxSize,
      type: 'file',
    }),
    [isDisabled, id, name, multiple, maxSize]
  )

  const _onChange = useCallback(
    (e?: ChangeEvent<HTMLInputElement>, newFiles: File[] = []) => {
      setFileUploadError(false)
      if (e?.target.files?.length || newFiles.length) {
        const newFilesArray = [
          ...files,
          ...newFiles,
          // @ts-ignore
          ...(e?.target.files || []),
        ]

        // resets value - in case user would like to (for some strange reason) upload the same file once again, it would let him to do so..
        if (e?.target) {
          e.target.value = ''
        }
        if (name) setFieldValue(name, multiple ? newFilesArray : newFilesArray[0])
        setFiles(newFilesArray)
      } else {
        setFileUploadError(true)
      }
    },
    [files, multiple, Boolean(onChange)]
  )

  const onFileDelete = useCallback(
    (index: number) => {
      const newFiles = [...files]
      newFiles.splice(index, 1)

      setFiles(newFiles)
    },
    [files, Boolean(onChange)]
  )

  const onResetFiles = useCallback(() => {
    setFiles([])
    setFieldValue(name, multiple ? [] : undefined)
  }, [])

  return (
    <>
      <div
        className={cx(
          'flex flex-col sm:flex-row transition-all duration-300 flex-grow overflow-hidden',
          files.length ? 'gap-4' : 'gap-0'
        )}>
        {multiple || files.length === 0 ? (
          <div
            {...getDropContainerProps()}
            className={cx(
              'min-h-64 max-h-64 min-w-0 flex justify-center p-6 border-2 border-gray-300 rounded-md items-center transition-all duration-300 mx-3 sm:mx-0',
              isDraggingOver ? 'bg-gray-100 border-solid' : 'bg-white border-dashed',
              className
            )}>
            <div className="space-y-1 text-center content-center flex flex-col items-center">
              <Icon className="text-gray-600" name="cloud-upload" size="3x" type="duotone" />

              <div className="text-sm text-gray-600">
                <label
                  className="relative cursor-pointer rounded-md font-medium text-primary hover:text-primary-hover focus-within:outline-none focus-within:text-primary-hover"
                  htmlFor={commonInputProps.id}>
                  <span>
                    <Translation keyValue="general.action.select_file" />
                  </span>
                  {name ? (
                    <Field name={name}>
                      {(fieldProps: FieldProps) => (
                        <input
                          {...commonInputProps}
                          name={fieldProps.field.name}
                          onBlur={fieldProps.field.onBlur}
                          onChange={_onChange}
                        />
                      )}
                    </Field>
                  ) : (
                    <input {...commonInputProps} name={name} onChange={_onChange} />
                  )}
                </label>
                <Translation
                  className="pl-1 hidden sm:inline lowercase"
                  keyValue="general.action.or_drag_and_drop"
                />
              </div>

              {Boolean(acceptedFileExtensions || maxSize) && (
                <p className="text-xs text-gray-500 flex items-center flex-col">
                  {acceptedFileExtensions}

                  {(maxSize || 0) > 0 && (
                    <Translation
                      keyValue="general.label.up_to[upTo]"
                      variables={{
                        upTo: prettyBytes(maxSize as number),
                      }}
                    />
                  )}
                </p>
              )}
            </div>
          </div>
        ) : (
          <FilePreview
            className="w-full md:min-w-0"
            file={files[0]}
            size="lg"
            onRemoveFile={onResetFiles}
          />
        )}

        {multiple && (
          <ul
            className={cx(
              'overflow-y-auto items-start content-start ease-in-out transform sm:transition-all duration-300 grid gap-2 max-h-auto sm:max-h-64 px-3 sm:pl-0',
              files.length > 0 ? 'scale-x-100 w-full sm:w-72' : 'scale-x-0 w-0'
            )}>
            {files.map((file, index) => (
              <li
                key={`${file.name}_${index}`}
                className="col-span-1 flex shadow-sm rounded-md h-16 w-auto overflow-x-hidden">
                <div
                  className={cx(
                    'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md overflow-x-hidden',
                    'border border-r-0 border-gray-200 rounded-l-md truncate bg-gray-50'
                  )}>
                  <FilePreview
                    className="md:min-w-0"
                    file={file}
                    size="sm"
                    onRemoveFile={onResetFiles}
                  />
                </div>
                <div className="flex-1 flex items-center justify-between border border-l-0 border-gray-200 bg-white rounded-r-md truncate">
                  <div className="flex-1 px-4 py-2 text-sm truncate">
                    <span className="text-gray-900 font-medium overflow-ellipsis whitespace-nowrap">
                      {file.name}
                    </span>
                    <p className="text-gray-500">
                      {prettyBytes(file.size, { maximumFractionDigits: 1 })}
                    </p>
                  </div>
                  <div className="flex-shrink-0 pr-2">
                    <button
                      className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500"
                      onClick={() => onFileDelete(index)}>
                      <span className="sr-only">
                        <Translation keyValue="general.action.delete" />
                      </span>
                      <Icon name="times" type="light" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {fileUploadError && (
        <p className="mt-5 text-center text-danger text-sm">
          <Translation keyValue="general.label.toast_invalid_size_or_type" />
        </p>
      )}
    </>
  )
}

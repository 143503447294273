// core
import React, { useMemo } from 'react'
// components
import { IDefaultProps } from 'components'
// libraries
import cx from 'classnames'

interface IBadgeProps extends IDefaultProps {
  /**
   * Badge color
   *
   * @default 'primary'
   */
  color?: 'primary' | 'danger' | 'success' | 'warning' | 'grey'
  /**
   * Text inside badge
   */
  label: string | number
  /**
   * Function to be called after click on badge
   */
  onClick?: () => void
}

export const Badge = ({ className, color = 'primary', label, onClick }: IBadgeProps) => {
  const defaultClasses = 'ml-2 w-4 h-4 px-1 rounded-full text-xs font-medium inline-block'
  const badgeColor = useMemo(() => {
    switch (color) {
      case 'danger':
        return 'text-danger bg-red-100'
      case 'grey':
        return 'text-gray-900 bg-gray-100'
      case 'success':
        return 'text-success bg-green-100'
      case 'warning':
        return 'text-warning bg-yellow-100'
      case 'primary':
      default:
        return 'text-primary bg-primary-light'
    }
  }, [color])

  const twCSS = (): string => {
    return cx(defaultClasses, badgeColor, className)
  }

  return (
    <span className={twCSS()} onClick={onClick}>
      {label}
    </span>
  )
}

import { gql } from '@apollo/client'

export const SettingsFragments = {
  SETTING: gql`
    fragment SettingFragment on SysSetting {
      id
      title
      key
      variables {
        id
        title
        key
        value
        updatedAt
        # updatedBy
      }
      createdAt
      updatedAt
    }
  `,
}

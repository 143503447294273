// core
import React from 'react'
// libraries
import { Redirect } from 'react-router'
import { Route, Switch, useLocation } from 'react-router-dom'
// routes
import { AuthRoutes } from 'routes/AuthRoutes'
// layouts
import { Content } from './Content/Content'
// styles
import css from './Layout.module.scss'

export function LayoutAuth() {
  const { pathname, search } = useLocation()

  /**
   * An array of valid `Route` components for unauthenticated users
   */
  const routes: React.ReactNode[] = AuthRoutes.map(({ component, path }) =>
    path && component ? <Route key={path} exact component={component} path={path} /> : null
  )

  /**
   * A `Redirect` component for returning users back to `/login` if they manually changed the URL and it's invalid
   */
  const renderRedirect = () => (
    <Redirect
      to={{
        pathname: '/login',
        state: { prevPath: pathname + search },
      }}
    />
  )

  return (
    <div className={css.wLayoutAuth}>
      <Content>
        <Switch>
          {routes}

          <Route key="redirect" exact={false} path="/" render={renderRedirect} />
        </Switch>
      </Content>
    </div>
  )
}

// core
import React from 'react'
// components
import { Icon, TIconName, TKey } from 'components'
import { IItemVariantProps, ItemDefault } from './ItemDefault'
// libraries
import cx from 'classnames'
// utils
import { stopEvent } from 'utils'

export interface IItemOptionProps extends IItemVariantProps {
  /**
   * Icon rendered on the right side of the `Item`
   *
   * @default 'chevron-right'
   */
  icon?: TIconName
  /**
   * Whether the item is selected, changes CSS classes
   *
   * @default false
   */
  isActive?: boolean
  /**
   * Text to display as the item content
   */
  title: string
  /**
   * Event called when user clicks the item
   */
  onClick(id: TKey): void
}

export const ItemOption = ({
  className,
  icon = 'chevron-right',
  id,
  isActive,
  title,
  onClick,
}: IItemOptionProps) => {
  const _onClick = (e: React.MouseEvent) => {
    stopEvent(e)
    onClick(id)
  }

  const twCSS = cx(isActive ? 'cardItemSelected' : 'cardItem', 'flex-1 min-w-0', className)

  return (
    <ItemDefault>
      <div className={twCSS} onClick={_onClick}>
        <div className="min-w-0 flex w-full items-center text-sm">
          <div className="min-w-0 truncate">{title}</div>
        </div>

        <Icon className="leading-tight ml-2" name={icon} size="xs" type="regular" />
      </div>
    </ItemDefault>
  )
}

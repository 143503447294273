// core
import { gql } from '@apollo/client'
import { TranslationFragments } from './TranslationFragments'

export const TranslationMutations = {
  CREATE_TRANSLATION: gql`
    mutation CreateTranslation($key: String!, $translations: [SysTranslationInput!]) {
      createSysTranslationKey(key: $key, translations: $translations) {
        ...TranslationFragment
      }
    }

    ${TranslationFragments.TRANSLATION}
  `,

  UPDATE_TRANSLATION: gql`
    mutation UpdateTranslation($id: ID!, $key: String, $data: [SysTranslationInput!]) {
      updateSysTranslation(id: $id, key: $key, data: $data) {
        ...TranslationFragment
      }
    }

    ${TranslationFragments.TRANSLATION}
  `,

  DELETE_TRANSLATION: gql`
    mutation DeleteTranslation($ids: [ID!]!) {
      deleteSysTranslations(ids: $ids) {
        ...TranslationFragment
      }
    }
    ${TranslationFragments.TRANSLATION}
  `,
}

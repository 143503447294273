// core
import React, { ReactNode, useCallback, useContext, useRef, useState } from 'react'
// API
import { UpdateSysMenuItemInput } from 'api/global-types'
import { LanguageQueries } from 'api/Languages/LanguageQueries'
import { GetLanguages } from 'api/Languages/types/GetLanguages'
import { SideMenuMutations as MenuMutations } from 'api/SideMenu/SideMenuMutations'
import { SideMenuQueries as MenuQueries } from 'api/SideMenu/SideMenuQueries'
import { GetSysMenuItems, GetSysMenuItemsVariables } from 'api/SideMenu/types/GetSysMenuItems'
import { UpdateMenuItem, UpdateMenuItemVariables } from 'api/SideMenu/types/UpdateMenuItem'
// components
import {
  Backdrop,
  Button,
  ContextMenu,
  getTranslation,
  Icon,
  IDefaultProps,
  Input,
  showToastErr,
  showToastOk,
  Tab,
  Tabs,
  Translation,
} from 'components'
import { IContextMenuItemProps } from 'components/ContextMenu/ContextMenu'
// libraries
import { useMutation, useQuery } from '@apollo/client'
import cx from 'classnames'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
// utils
import {
  ERoutes,
  EStorageKeys,
  PageTitleContext,
  parseAPIErrors,
  TranslationKeys,
  useConsoleErrors,
} from 'utils'

interface IPageHeaderProps extends IDefaultProps {
  /**
   * URL to be redirected if user clicks on the "back" icon
   *
   * @note If `null` is provided, the icon won't be rendered
   *
   * @default '/' (dashboard)
   */
  backUrl?: ERoutes | string | null
  /**
   * Additional content displayed before context menu icon
   */
  contentRight?: ReactNode
  /**
   * Width of the context menu - if no "options" provided icon will not be displayed neither is this prop useless
   *
   * @default '200'
   */
  contextMenuWidth?: number
  /**
   * Items in right context menu - if no items provided icon will not be displayed
   */
  options?: IContextMenuItemProps[]
  /**
   * Page subtitle - will be displayed after page title and separated with `-`
   */
  pageSubtitle?: string
  /**
   * Page title - if not provided, `title` prop will be used instead
   */
  pageTitle?: string
  /**
   * Translation key for the title of the page
   */
  title: TranslationKeys
}

export const PageHeader = ({
  backUrl = ERoutes.DASHBOARD,
  className,
  contentRight,
  contextMenuWidth,
  options,
  pageSubtitle,
  pageTitle,
  title,
}: IPageHeaderProps) => {
  const formRef = useRef<FormikProps<{ content: string }>>(null)
  const sysLanguageId = localStorage.getItem(EStorageKeys.DEFAULT_LANG_ID) || ''
  const { title: _title } = useContext(PageTitleContext)
  const history = useHistory()
  const [isInfoOpened, setIsInfoOpened] = useState<boolean>(false)
  const [isManageMode, setIsManageMode] = useState<boolean>(false)
  const [activeSysLanguageId, setActiveSysLanguageId] = useState<string>(sysLanguageId)

  const menuItemsVariables: GetSysMenuItemsVariables = { showAll: false }

  const { data, loading, error } = useQuery<GetSysMenuItems, GetSysMenuItemsVariables>(
    MenuQueries.GET_SIDEMENU_ITEMS,
    { variables: menuItemsVariables }
  )

  const { data: dataLanguages, loading: loadingLanguages, error: errorLanguages } = useQuery<
    GetLanguages
  >(LanguageQueries.GET_LANGUAGES)

  const refetchQueriesAfterMutations = () => [
    {
      query: MenuQueries.GET_SIDEMENU_ITEMS,
      variables: menuItemsVariables,
    },
  ]

  const [
    updateMenuItem,
    { loading: loadingUpdateMenuItem, error: errorUpdateMenuItem },
  ] = useMutation<UpdateMenuItem, UpdateMenuItemVariables>(MenuMutations.UPDATE_ITEM, {
    refetchQueries: refetchQueriesAfterMutations,
  })

  const menuItem = data?.sysMenuItems.find(({ title: moduleTitle }) => moduleTitle === title)
  const menuItemId = menuItem?.id
  const getMenuItemContent = useCallback(
    (languageId: string) =>
      menuItem?.moduleInfo.find(({ sysLanguageId }) => sysLanguageId === languageId)?.content || '',
    [menuItem?.moduleInfo]
  )

  /**
   * Event called when user submits edit form in the 2nd column
   * @param values Validated formik values
   * @param helpers Formik helper methods
   */
  const onUpdateMenuItem = async (
    values: { content: string },
    helpers?: FormikHelpers<{ content: string }>
  ) => {
    if (menuItemId) {
      const moduleInfo =
        menuItem?.moduleInfo.map(info => ({
          content: info.content || '',
          sysLanguageId: info.sysLanguageId,
        })) || []

      moduleInfo.push({ content: values.content, sysLanguageId: activeSysLanguageId })

      const updateData: UpdateSysMenuItemInput = {
        moduleInfo: moduleInfo,
      }

      return updateMenuItem({
        variables: { id: menuItemId, data: updateData },
      })
        .then(() => {
          showToastOk('sys_menu.label.toast_update_module_info')
          onIsManageModeToggle()
        })
        .catch(parseAPIErrors)
        .finally(() => helpers?.setSubmitting(false))
    }

    return showToastErr('sys_menu.label.toast_update_module_info_error')
  }

  const onInfoOpenedToggle = useCallback(() => setIsInfoOpened(val => !val), [])
  const onIsManageModeToggle = useCallback(() => setIsManageMode(val => !val), [])

  const _pageTitle = `${pageTitle || getTranslation(title as TranslationKeys)} ${
    pageSubtitle ? '- ' + pageSubtitle : ''
  } ${_title}`

  const _onClick = useCallback(() => {
    if (backUrl) {
      history.replace(backUrl)
    }
  }, [backUrl, history])

  const onSubmit = useCallback(() => {
    formRef.current?.handleSubmit()
  }, [])

  useConsoleErrors('PageHeader', error, errorUpdateMenuItem)

  return (
    <>
      <Helmet>
        <title>{_pageTitle}</title>
      </Helmet>

      <div
        className={cx(
          'flex justify-between items-center w-full pb-2 pl-2 text-black border-b border-gray-200 dark:text-white z-30',
          className
        )}>
        <div className="flex items-center space-x-4 text-xl font-medium">
          {backUrl !== null && (
            <Icon
              className="text-current"
              name="chevron-left"
              size="xs"
              type="regular"
              onClick={_onClick}
            />
          )}

          <Translation keyValue={title} />
        </div>

        {contentRight}

        <div className="flex items-center">
          {isInfoOpened ? (
            <div className="relative flex items-center space-x-3 mr-3 z-25">
              {isManageMode || !getMenuItemContent(sysLanguageId) ? (
                // custom submit button because it is not inside `Formik`
                <Button
                  color="success"
                  icon="save"
                  isLoading={formRef.current?.isSubmitting}
                  label={getTranslation('general.action.save')}
                  size="small"
                  onClick={onSubmit}
                />
              ) : (
                <Button.Edit isIconOnly size="small" onClick={onIsManageModeToggle} />
              )}

              <Button.Close
                isIconOnly
                size="small"
                onClick={isManageMode ? onIsManageModeToggle : onInfoOpenedToggle}
              />
            </div>
          ) : (
            <Icon
              className="icon cursor-pointer ml-2 mr-3 text-gray-500"
              name="info-circle"
              type="regular"
              onClick={onInfoOpenedToggle}
            />
          )}

          {Boolean(options?.length) && (
            <ContextMenu
              classNameIcon="text-black -ml-1"
              width={contextMenuWidth}
              header={<Translation keyValue="general.label.action" />}
              iconProps={{
                name: 'ellipsis-v',
                className: 'text-black dark:text-white px-4',
                type: 'regular',
              }}
              items={options || []}
            />
          )}
        </div>
      </div>

      <Backdrop visible={isInfoOpened} zIndex="z-20" onClick={onInfoOpenedToggle} />

      <div
        className={cx(
          'relative transform transition-all duration-300 h-max w-full origin-top z-25',
          isInfoOpened ? 'max-h-max min-h-24 scale-y-100' : 'max-h-0 scale-y-0'
        )}>
        {!isManageMode && getMenuItemContent(sysLanguageId) ? (
          <div className="px-2 pt-5 w-full h-max text-xs text-gray-700">
            {getMenuItemContent(sysLanguageId)}
          </div>
        ) : (
          <Tabs activeTab={activeSysLanguageId}>
            {(dataLanguages?.sysLanguages || []).map(({ id, title }) => (
              <Tab
                key={`tab-${id}`}
                id={id}
                label={title}
                onClick={() => setActiveSysLanguageId(id)}>
                <div className="p-4 w-full">
                  <Formik
                    enableReinitialize
                    validateOnChange
                    innerRef={formRef}
                    initialValues={{
                      content: getMenuItemContent(id),
                    }}
                    onSubmit={onUpdateMenuItem}>
                    <Form>
                      <Input.TextArea name="content" />
                    </Form>
                  </Formik>
                </div>
              </Tab>
            ))}
          </Tabs>
        )}
      </div>
    </>
  )
}

export const isKeyUsed = (key: string) =>
  [
    'general.back',
    'general.ok',
    'actions.confirm',
    'customer.name',
    'admin.name',
    'auth.user_not_found',
    'actions.deny',
    'auth.forgotten_password',
    'general.support',
    'auth.login',
    'general.label.developed_by_ambitas',
    'brand.new.key',
    'auth.invalid_credentials',
    'general.email',
    'general.password',
    'general.label.all_rights_reserved',
    'actions.next',
    'general.email_address',
    'auth.forgotten_password_link',
    'auth.login_message',
    'auth.password_message',
    'auth.forgotten_password_message',
    'auth.forgotten_password_sent_message',
    'auth.support_email_sent_message',
    'actions.back',
    'actions.send_email',
    'general.action.save',
    'general.email_address[email]',
    'auth.forgotten_password_email_message',
    'auth.forgotten_password_support_message',
    'auth.forgotten_password_support_title',
    'auth.resend_email',
    'auth.set_new_password',
    'auth.set_new_password_message',
    'auth.set_new_password_description',
    'auth.invalid_secret_description',
    'auth.invalid_secret',
    'general.lowercase',
    'general.uppercase',
    'general.special',
    'general.characters',
    'general.new_password',
    'general.confirm_password',
    'actions.close',
    'actions.edit',
    'actions.delete',
    'actions.confirmation',
    'general.no_records',
    'general.manage',
    'general.modules',
    'general.users',
    'access_rights.label.groups',
    'actions.add',
    'sys_access_rights.groups',
    'general.none',
    'general.profile',
    'general.search',
    'access_rights.name_of_group',
    'access_rights.superior_group',
    'access_rights.number_of_users',
    'general.label.last_three_pages',
    'general.label.search_something',
    'access_rights.actions.add_group',
    'general.actions.create',
    'general.label.description',
    'general.phone',
    'access_rights.label.access_rights',
    'general.work_position',
    'general.select_value',
    'access_rights.label.access_group_name',
    'access_rights.label.change_user_access_group_message',
    'access_rights.label.edit_access_rights',
    'access_rights.label.edit_access_rights',
    'access_rights.label.edit_access_rights',
    'access_rights.label.name_of_group',
    'general.label.enter_value',
    'general.label.manage',
    'access_rights.label.superior_group',
    'general.label.none',
    'access_rights.label.number_of_users',
    'general.label.action',
    'general.label.all',
    'general.label.read',
    'general.label.new',
    'general.action.mark_all_seen',
    'general.action.dismiss_all',
    'general.label.history',
    'general.label.notifications',
    'general.label.users',
    'general.label.no_new_messages',
    'general.label.no_new_messages_sub',
    'general.label.no_read_messages',
    'general.label.no_read_messages_sub',
    'general.action.mark_unseen',
    'general.action.mark_seen',
    'general.action.dismiss',
    'general.label.no_previous_pages',
    'general.label.no_previous_pages_sub',
    'general.label.last_visited_pages',
    'general.label.action',
    'general.label.managa_account',
    'general.label.language_settings',
    'auth.action.logout',
    'auth.label.logout_message',
    'general.label.message',
    'general.label.info',
    'translations.label.translation',
    'translations.label.translation_create_placeholder',
    'general.label.name',
    'general.action.close',
    'general.label.no_records',
    'general.label.table_footer[start,end,total]',
    'general.label.key',
    'sys_translations.label.translations',
    'general.action.delete',
    'auth.new_user_set_password',
    'auth.new_user_set_password_description',
    'translations.action.create_new_translation',
    'general.action.edit',
    'general.label.active',
  ].includes(key)

// core
import React from 'react'
// components
import { IDefaultProps } from 'components'
// libraries
import cx from 'classnames'

export interface IMenuIconProps extends IDefaultProps {
  /**
   * Icon will animate on menu open/close
   */
  isMenuOpened: boolean
  /**
   * In case menu is defaultly opened and cannot be closed, icon should be invisible (on screens wider than 1536px)
   *
   * @default 'true'
   */
  isVisible?: boolean
  onClick?: () => void
}

/**
 * Custom created "icon" with animation for opening/closing left sidemenu
 *
 * Does NOT use deafult Icon component
 */
export const MenuIcon = ({
  className,
  isMenuOpened,
  isVisible = true,
  onClick,
}: IMenuIconProps) => {
  return (
    <div
      className={cx(
        !isVisible && 'hidden',
        'group absolute top-4 left-4 space-y-1 m-1 h-5 w-5 cursor-pointer z-45',
        className
      )}
      onClick={onClick}>
      <span
        className={cx(
          isMenuOpened ? 'ml-0' : 'ml-2.5',
          'block w-1/2 h-0.5 bg-current rounded transition-all duration-300'
        )}
      />
      <span className="block w-full h-0.5 bg-current rounded transition-all duration-300" />
      <span
        className={cx(
          isMenuOpened ? 'ml-2.5' : 'ml-1',
          'block w-1/2 h-0.5 bg-current rounded transition-all duration-300'
        )}
      />
    </div>
  )
}

/** README !
 *
 * Global collection of all regexes used throughout the project
 *
 * @Rules keep them in alphabetical order
 *
 */

export const gRegex: { [key: string]: RegExp } = {
  url: /^mailto:([^\\?]+)|^(tel:([^\\?]+))|^((https?):\/\/)?(www.)?[a-zA-Z0-9-.]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_@#&=?]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  hex: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
  //   hex: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
  name: /[^a-zA-Z\d.,:'-\s]/g,
  phone: /[^()\d-]/g,
}

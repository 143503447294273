// core
import React, { lazy, memo } from 'react'
// API
import { SideMenuItem } from 'api/SideMenu/types/SideMenuItem'
// libraries
import { Route, Switch } from 'react-router-dom'
// routes
import { IRoutes, LoggedRoutes } from 'routes/index'

const HomePage = lazy(async () => import('pages/Home/HomePage'))
const CreateUser = lazy(async () => import('pages/CreateUser/CreateUserPage'))
const ProfileManagement = lazy(async () => import('pages/ProfileManagement/ProfileManagementPage'))
const BlogPage = lazy(async () => import('pages/Blog/BlogPage'))
const BlogPagePreview = lazy(async () => import('pages/Blog/BlogPagePreview'))
// DEV pages for testing / trying things out
const DevFileUploaderPage = lazy(async () => import('pages/Dev/DevFileUploaderPage'))
const DevChartPage = lazy(async () => import('pages/Dev/DevChart'))
const DevDialogInline = lazy(async () => import('pages/Dev/DevDialogInline'))
const DevInputSelect = lazy(async () => import('pages/Dev/DevInputSelect'))
const DevInputNumber = lazy(async () => import('pages/Dev/DevInputNumber'))
const DevPresentationPage = lazy(async () => import('pages/Dev/PresentationPage'))
const DevTablePage = lazy(async () => import('pages/Dev/DevTablePage'))

interface ISideMenuItem extends SideMenuItem {
  childSysMenuItems?: SideMenuItem[]
}

export interface IRouterProps {
  /**
   * List of routes
   */
  routes: ISideMenuItem[]
}

export const Router = memo(function Router({ routes }: IRouterProps) {
  const renderRoutes = (items: ISideMenuItem[]): any => {
    return items
      .map(item => {
        if (item.childSysMenuItems && item.childSysMenuItems.length > 0) {
          return renderRoutes(item.childSysMenuItems)
        }

        if (item.url) {
          const itemUrl = `/${item.url}`
          const route: IRoutes | undefined = LoggedRoutes.find(r => r.path === itemUrl)

          return route ? (
            <Route
              key={itemUrl}
              exact
              path={itemUrl}
              render={props => {
                const Component = route.component

                // @ts-ignore
                return <Component {...props} />
              }}
            />
          ) : null
        }

        return null
      })
      .reduce((routes, item) => routes.concat(Array.isArray(item) ? item : [item]), [])
  }

  return (
    <Switch>
      {renderRoutes(routes)}

      <Route exact component={HomePage} path="/" />
      <Route exact component={CreateUser} path="/users/create" />
      <Route exact component={ProfileManagement} path="/users/:userId" />
      <Route exact component={BlogPagePreview} path="/blogs/preview/:blogId" />
      <Route exact component={BlogPage} path="/blogs/:blogId" />

      {/* DEV PAGES */}
      <Route exact component={DevChartPage} path="/dev/chart" />
      <Route exact component={DevDialogInline} path="/dev/dialog-inline" />
      <Route exact component={DevFileUploaderPage} path="/dev/file-uploader" />
      <Route exact component={DevInputSelect} path="/dev/input-select" />
      <Route exact component={DevInputNumber} path="/dev/input-number" />
      <Route exact component={DevPresentationPage} path="/dev/presentation" />
      <Route exact component={DevTablePage} path="/dev/table" />

      {/* #TODO: find another way - this causes any valid URL to revert back to home page */}
      {/* <Route
        key="redirect"
        exact={false}
        path="/"
        render={() => <Redirect to={{ pathname: '/', state: { prevPath: pathname + search } }} />}
      /> */}
    </Switch>
  )
})

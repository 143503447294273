// core
import React, { ReactNode, useCallback, useState } from 'react'
// components
import { Dialog, Icon, IDefaultProps, IIconProps, Loader, TKey, Translation } from 'components'
// libraries
import cx from 'classnames'
// utils
import { stopEvent } from 'utils'

export interface IContextMenuProps extends IDefaultProps {
  /**
   * Additional content - will be hidden until click on a menu item which should open this content
   */
  children?: ReactNode
  /**
   * Classes passed to context menu icon
   */
  classNameIcon?: string
  /**
   * Which direction should the Context menu extend towards
   * @default 'left'
   */
  direction?: 'left' | 'right'
  /**
   * Header text
   */
  header?: ReactNode
  /**
   * Icon props - trough this icon can be menu opened, if null icon will be invisible, default icon is ellipsis-v (tree dots)
   */
  iconProps?: IIconProps | null
  /**
   * Whether the menu is opened or not
   */
  isOpen?: boolean
  /**
   * Whether to use dark mode theme
   */
  isDark?: boolean
  /**
   * Whether can be context menu opened
   */
  isDisabled?: boolean
  /**
   * Items to render in menu
   */
  items: IContextMenuItemProps[]
  /**
   * requested x position of Dialog
   */
  requestedX?: number
  /**
   * requested y position of Dialog
   */
  requestedY?: number
  /**
   * Type of interaction, whether the ContextMennu opens via mouse hover or by clicking
   * @default 'hover'
   */
  type?: 'click' | 'hover'
  /**
   * Width of element
   * @default 200
   */
  width?: number
  /**
   * Event called when click on context menu icon
   */
  onClick?: () => void
  /**
   * Callback to close menu
   */
  onClose?: () => void
}

export const ContextMenu = ({
  children,
  className,
  classNameIcon,
  direction = 'left',
  header,
  iconProps,
  id,
  isDark = false,
  isDisabled = false,
  isOpen,
  items,
  requestedX,
  requestedY,
  type = 'hover',
  width = 200,
  onClick,
  onClose,
}: IContextMenuProps) => {
  // in this case icon, will be invisible but will be still there so context menu can be opened
  const isIconHidden = iconProps === null
  const isTypeClick = type === 'click'

  const [isShowMenu, setIsShowMenu] = useState<boolean>(false)

  const onHover = useCallback(() => {
    if (!isTypeClick) {
      setIsShowMenu(true)
    }
  }, [isTypeClick])

  const onBlur = useCallback(() => {
    if (!isTypeClick) {
      setIsShowMenu(false)
    }
  }, [isTypeClick])

  const _onClick = useCallback(() => {
    onClick?.()
    setIsShowMenu(prev => !prev)
  }, [onClick])

  return (
    <div
      className={cx(isIconHidden ? 'absolute top-0 left-0' : 'relative', classNameIcon)}
      onBlur={onBlur}
      onFocus={onHover}
      onMouseEnter={onHover}
      onMouseLeave={onBlur}>
      <Icon
        className={cx(
          'text-gray-500 dark:text-gray-200',
          isIconHidden && 'absolute -top-1 text-2xl opacity-0',
          isDisabled && 'cursor-not-allowed'
        )}
        name={isIconHidden ? 'square-full' : 'ellipsis-v'}
        type="regular"
        onClick={isTypeClick && !isDisabled ? _onClick : undefined}
        {...iconProps}
      />

      <Dialog.Inline
        useCard
        className={className}
        direction="bottom"
        isOpen={isOpen ?? isShowMenu}
        isDark={isDark}
        requestedX={requestedX}
        requestedY={requestedY}
        side={direction}
        useBackdrop={!!onClose}
        width={width}
        onBlur={onClose}>
        <div className="pt-2">
          <div className="flex items-center text-xs text-txt-light px-3 py-1 cursor-default">
            <span className="block rounded w-0.5 h-3 bg-current mr-2" />
            <p className="text-left truncate whitespace-normal">{header}</p>
          </div>

          {items.length ? (
            <ul
              className={cx(
                isDark ? 'text-gray-200 divide-gray-600' : 'text-black',
                'relative divide-y dark:divide-gray-600 dark:text-gray-200'
              )}>
              {items.map((item, index) => (
                <ContextMenuItem key={index} parentId={id} onBlur={onBlur} {...item} />
              ))}
            </ul>
          ) : (
            <div className="py-10 text-gray-600 text-center text-sm italic">
              <Translation keyValue="general.label.no_records" />
            </div>
          )}
          {children}
        </div>
      </Dialog.Inline>
    </div>
  )
}

//  ==========  =====================  ==========

//       P A R T I A L   C O M P O N E N T S

//  ==========  =====================  ==========
export interface IContextMenuItemProps extends IDefaultProps {
  /**
   * Label of item to be displayed in the menu
   */
  children?: ReactNode
  /**
   * Whether to render a delete item with confirmation - red bg and trash icon
   */
  isDeleteItem?: boolean
  /**
   * Whether the ContextMenuItem is disabled or not
   *
   * @default false
   */
  isDisabled?: boolean
  /*
   * Context menu item loading - currently supports only simple context menu item, not the confirmation ones
   */
  isLoading?: boolean
  /**
   * ! DO NOT USE THIS PROP ! I WILL KICK YOUR HEAD OFF IF YOU DO !
   *
   * explenation:
   * if `isDeleteItem` or `isSubmitItem` are `true` then the `ContexMenuItem` renders `ConfirmationMenuOption`
   * which then in turn renders `ContexMenuItem` item again but instead of a `li` it renders `ul` with the main item
   * and the sub item - the confirmation; now, the `ContexMenuItem` needs to have `absolute` position
   * so the "slide-in-out" animation can play and the `ContexMenuItem` needs to have `relative` position so the Loader will
   * render properly, since Tailwind is such a piece of shit and the `absolute` wont override the `relative` class
   * i was forced to commit war crimes and make this cursed gipsy prop which sole purpose is to toggle between the 2 classes
   * God have mercy on my soul
   */
  _isNested?: boolean
  /**
   * Whether to render a submit item with confirmation - green bg and check icon
   */
  isSubmitItem?: boolean
  /**
   * ID of the parent element containing context menu
   */
  parentId?: TKey
  /**
   * Whether click should close contextMenu on item click
   *
   * @default false
   */
  shouldClickBlur?: boolean
  /**
   * Whether native click event should be stopped - solves issues
   *
   * @default false
   */
  stopsEvent?: boolean
  /**
   * Callback to hide contextMenu on item click
   * @note inside component prop
   */
  onBlur?(): void
  /**
   * Callback to run on item click
   */
  onClick?(id?: TKey, e?: React.MouseEvent<Element, MouseEvent>): void
}

const ContextMenuItem = ({
  id,
  className,
  children,
  isDeleteItem = false,
  isDisabled = false,
  isLoading = false,
  _isNested = false,
  isSubmitItem = false,
  parentId,
  shouldClickBlur = false,
  stopsEvent,
  onBlur,
  onClick,
}: IContextMenuItemProps) => {
  const _onClick = useCallback(
    (_, e: React.MouseEvent<Element, MouseEvent>) => {
      if (isDisabled) return

      if (stopsEvent) {
        stopEvent(e)
      }

      if (shouldClickBlur) {
        onBlur?.()
      }

      onClick?.(parentId, e)
    },
    [isDisabled, parentId, stopsEvent, shouldClickBlur, onBlur, onClick]
  )

  return isDeleteItem || isSubmitItem ? (
    <ConfirmationMenuOption
      className={className}
      isDeleteItem={isDeleteItem}
      parentId={parentId}
      onClick={_onClick}>
      {children}
    </ConfirmationMenuOption>
  ) : (
    <li
      key={id}
      className={cx(
        'block px-5 py-3 text-left text-sm transition last:rounded-b-md',
        !_isNested && 'relative',
        isDisabled && 'disabled',
        !isDisabled && onClick && 'hover:bg-primary hover:text-white cursor-pointer',
        className
      )}
      role="menuitem"
      onClick={e => _onClick(undefined, e)}>
      <Loader.Wrapper isLoading={isLoading} opacity="opacity-50">
        {children}
      </Loader.Wrapper>
    </li>
  )
}

const ConfirmationMenuOption = ({ children, isDeleteItem, onClick }: IContextMenuItemProps) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false)

  const onToggleConfirming = useCallback((id, e) => {
    e.stopPropagation()
    setIsConfirming(val => !val)
  }, [])

  return (
    <ul className="relative overflow-hidden">
      <ContextMenuItem
        _isNested
        className={cx(
          isDeleteItem && 'rounded-b-md',
          isConfirming && !isDeleteItem && 'bg-success-hover'
        )}
        onClick={onToggleConfirming}>
        {children}
      </ContextMenuItem>

      <ContextMenuItem
        _isNested
        className={cx(
          'flex justify-between items-center absolute top-0 left-0 w-full transform duration-200 text-white cursor-default',
          isConfirming ? 'translate-x-0 ease-in' : 'translate-x-full ease-out',
          isDeleteItem ? 'bg-danger' : 'bg-success-hover'
        )}>
        <Translation keyValue="general.action.confirmation" />

        <li className="flex items-center space-x-3">
          <Icon
            className={cx(
              'icon flex items-center justify-center w-5 h-5 text-center bg-white rounded-full',
              isDeleteItem ? 'text-danger' : 'text-success-hover'
            )}
            name={isDeleteItem ? 'trash-alt' : 'check'}
            size="xs"
            type="regular"
            onClick={e => {
              onClick?.(undefined, e)
              onToggleConfirming(undefined, e)
            }}
          />

          <Icon
            className="icon"
            name="times"
            type="regular"
            onClick={e => onToggleConfirming(undefined, e)}
          />
        </li>
      </ContextMenuItem>
    </ul>
  )
}

// libraries
import { ContentBlock, ContentState } from 'draft-js'
// partials
import { Media } from './WysiwygEditor'

export enum WysiwygEntities {
  ACTION = 'ACTION',
  LINK = 'LINK',
  TAG = 'TAG',
}

export const findEntities = (name: WysiwygEntities) => (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()

    return entityKey !== null && contentState.getEntity(entityKey).getType() === name
  }, callback)
}

export function mediaBlockRenderer(block: ContentBlock) {
  if (block.getType() === 'atomic') {
    return {
      component: Media,
      editable: false,
    }
  }
  return null
}

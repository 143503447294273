// core
import React, { useCallback, useContext, useMemo, useState } from 'react'
// components
import { IDefaultWrapperProps, TKey } from 'components'
import { Icon } from 'components/Icon/Icon'
import { IItemCategoryProps } from 'components/Item/variants/ItemCategory'
import { List } from 'components/List/List'
import { Loader } from 'components/Loader/Loader'
import { ITabProps, Tab, Tabs } from 'components/Tabs/Tabs'
import { useDetailPanel } from './useDetailPanel'
// libraries
import cx from 'classnames'
// layouts
import { ViewportContext } from 'layouts'

const defaultTab = 'basicInfo'

type TTab<Tab> = Tab | typeof defaultTab

export interface IDetailPanelCategory<Category, Tab>
  extends Omit<IItemCategoryProps, 'id' | 'onClick'> {
  /**
   * ID of a category from left side panel, used in URL params
   */
  id: Category
  /**
   * Config of props for tabs within a category
   *
   * Every category has 1 tab by default - 'basicInfo' - it's needed for URL params, but this doesn't render the <Tab> component,
   * tabs are rendered only when there are at least 2
   *
   * @default ['basicInfo']
   */
  tabs?: Omit<ITabProps<TTab<Tab>>, 'children'>[]
}

interface IRenderProps<Category, Tab> {
  /**
   * ID of currently active category
   */
  category: Category
  /**
   * ID of currently active tab
   */
  tab: TTab<Tab>
}

interface IDetailPanelProps<Category, Tab> extends IDefaultWrapperProps {
  /**
   * Config of props for categories for the left side panel
   */
  categories: IDetailPanelCategory<Category, Tab>[]
  /**
   * Render props
   * @param renderProps props that are forwarded via children
   */
  children(renderProps: IRenderProps<Category, Tab>): JSX.Element
  /**
   * ID of the category selected by default
   * (this cannot be extracted as a value from generic `Category` type)
   */
  defaultCategory: Category
  /**
   * Whether the data for the component is being fetched
   */
  isLoading?: boolean
}

export const DetailPanel = <Category, Tab>({
  categories,
  children,
  defaultCategory,
  isLoading,
}: IDetailPanelProps<Category, Tab>) => {
  // ==================== State ====================
  const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false)

  // ==================== Hooks ====================
  const { isSmallerThan, isLargerThan } = useContext(ViewportContext)
  const [detailPanel, setDetailPanel] = useDetailPanel<
    Category,
    Tab | typeof defaultTab,
    {
      /* IScreenParams - later, if needed, this can be customized too */
    }
  >(defaultCategory, defaultTab, undefined, undefined, undefined)

  const activeCategoryTitle = useMemo(
    () => categories.find(cat => cat.id === detailPanel.category)?.title || '',
    [categories, detailPanel.category]
  )

  // ==================== Events ====================
  const onSelectCategory = useCallback(
    (id: TKey) => {
      setDetailPanel({
        category: (id as unknown) as Category,
        tab: /* columns.second ||  */ defaultTab,
      })

      // If on small devices - hide the side panel after selection
      if (isSmallerThan.sm) {
        setIsSidePanelOpen(false)
      }
    },
    [setDetailPanel, setIsSidePanelOpen, isSmallerThan.sm]
  )

  const onToggleSidePanel = useCallback(() => {
    setIsSidePanelOpen(val => !val)
  }, [setIsSidePanelOpen])

  return (
    <Loader.Wrapper isLoading={isLoading}>
      <main className="min-w-full h-full sm:pb-10">
        <div className="h-full w-full pb-0 sm:pb-5 pt-0 px-0 sm:px-4 md:px-0">
          {/* sm:pb-14 md:pb-20 */}
          <div className="relative h-full w-full rounded-md">
            <div className="h-full flex flex-row overflow-hidden md:mt-5 shadow-xl dark:shadow-none rounded-md">
              <nav
                className={cx(
                  isLargerThan.sm || isSidePanelOpen ? 'translate-x-0' : '-translate-x-full',
                  'border-r fixed inset-0 md:left-0 top-32 md:top-0 md:bottom-10 w-10/12 max-w-sm md:max-w-64 lg:max-w-80 overflow-auto rounded-r-md shadow-2xl dark:shadow-none md:rounded-r-none md:rounded-l-md md:shadow-none flex-shrink-0 md:w-80 xl:w-96 bg-white dark:bg-dark border-gray-200 dark:border-gray-800 md:relative md:flex md:flex-col z-15',
                  'transform ease-in-out transition-all duration-300'
                )}>
                <List.Category
                  activeItemId={((detailPanel.category || defaultCategory) as unknown) as TKey}
                  items={categories.map(cat => ({
                    ...cat,
                    id: (cat.id as unknown) as TKey,
                  }))}
                  onClick={onSelectCategory}
                />
              </nav>

              <div className="flex space-x-4 absolute md:hidden top-4 py-3 px-6 text-xl bg-white dark:bg-dark dark:text-white w-full rounded-t-md z-10">
                <Icon name="bars" onClick={onToggleSidePanel} />

                <p className="font-semibold min-w-max">{activeCategoryTitle}</p>
              </div>

              <div className="relative mt-14 md:mt-0 md:rounded-r-md flex flex-col w-full bg-white dark:bg-dark overflow-auto">
                {categories.map((cat, catIndex) =>
                  cat.id === detailPanel.category && cat.tabs && cat.tabs.length > 1 ? (
                    <Tabs<TTab<Tab>>
                      activeTab={detailPanel.tab || defaultTab}
                      onChangeTab={val => setDetailPanel({ tab: val })}>
                      {cat.tabs.map((tab, index) => (
                        <Tab<TTab<Tab>> {...tab} key={`tab_${index}_${tab.id}`}>
                          {children({
                            category: detailPanel.category || defaultCategory,
                            tab: detailPanel.tab || defaultTab,
                          }) //.props.children[catIndex]
                          }
                        </Tab>
                      ))}
                    </Tabs>
                  ) : (
                    children({
                      category: detailPanel.category || defaultCategory,
                      tab: detailPanel.tab || defaultTab,
                    }).props.children[catIndex] //   using this will render a content for the specific category since we're looping though all of them
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </Loader.Wrapper>
  )
}

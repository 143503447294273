// core
import React from 'react'
// components
import { getTranslation } from 'components'
import { ButtonDefault, IButtonDefaultProps } from './ButtonDefault'

interface IButtonEditProps extends IButtonDefaultProps {}

export const ButtonEdit = ({ className, label, ...otherProps }: IButtonEditProps) => (
  <ButtonDefault
    className={className}
    color="warning"
    icon="pencil-alt"
    label={label || getTranslation('general.action.edit')}
    {...otherProps}
  />
)

// core
import React from 'react'
// components
import { IItemDefaultProps, ItemDefault } from './variants/ItemDefault'
import {
  ItemDescription,
  ItemDescriptionTitle,
  ItemDescriptionValue,
} from './variants/ItemDescription'
import { ItemDetailed } from './variants/ItemDetailed'
import { ItemOption } from './variants/ItemOption'
import { ItemUser } from './variants/ItemUser'

export class Item extends React.Component<IItemDefaultProps> {
  static Description = ItemDescription
  static DescriptionTitle = ItemDescriptionTitle
  static DescriptionValue = ItemDescriptionValue
  static Detailed = ItemDetailed
  static Option = ItemOption
  static User = ItemUser

  render() {
    return <ItemDefault {...this.props} />
  }
}

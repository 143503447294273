// core
import React from 'react'
// components
import { MessageDefault, IMessageDefaultProps } from './MessageDefault'
// libraries
import cx from 'classnames'

interface IMessageWarningProps extends IMessageDefaultProps {}

export const MessageWarning = ({ className, ...otherProps }: IMessageWarningProps) => {
  return (
    <MessageDefault
      className={cx('bg-warning divide-yellow-300', className)}
      icon="exclamation-circle"
      {...otherProps}
    />
  )
}

// core
import React, { MouseEvent, useCallback } from 'react'
// components
import { IDefaultProps } from 'components'
// libraries
import cx from 'classnames'
// utils
import { runCallback, TZIndexValues } from 'utils'

export interface IBackdropProps extends IDefaultProps {
  /**
   * Whether backdrop should be visible. There is css animation for showing/hiding backdrop.
   */
  visible: boolean
  /**
   * Css z-index value
   *
   * @default 'z-50'
   */
  zIndex?: TZIndexValues
  /**
   * Callback to run on backdrop click
   */
  onClick?: () => void
}

export const Backdrop = ({ visible, zIndex = 'z-50', onClick, className }: IBackdropProps) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      runCallback(onClick)
    },
    [onClick]
  )

  const twCSS = (): string => {
    return cx(visible && zIndex !== 'z-0' ? 'opacity-100' : 'opacity-0 hidden', zIndex)
  }

  return (
    <div
      className={cx(
        twCSS(),
        'fixed inset-0 backdrop-bg transform transition-opacity duration-500',
        className
      )}
      onClick={visible ? handleClick : undefined}
    />
  )
}

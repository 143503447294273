// core
import { gql } from '@apollo/client'

export const TranslationFragments = {
  TRANSLATION: gql`
    fragment TranslationFragment on SysTranslation {
      id
      key
      translations {
        sysLanguageId
        translation
        # updatedAt
      }
      # createdAt
    }
  `,
}

// core
import React from 'react'
// components
import { IDefaultProps } from 'components'
import { Button } from 'components/Button/Button'
// libraries
import cx from 'classnames'

interface IInputSwitchProps extends IDefaultProps {
  /**
   * Color scheme of the input, change them based on the background the Input is on
   *
   * @example
   * 'gray'  // default input color
   * 'white' // white input bg
   * 'black' // black input bg, used in create/edit forms
   *
   * @default 'gray'
   */
  colorScheme?: 'gray' | 'white' | 'black'
  /**
   * Whether the toggle button is disabled
   */
  isDisabled?: boolean
  /**
   * Text to show next to the toggle button
   */
  label?: string
  /**
   * Value of the toggle button (on / off)
   */
  value: boolean
  /**
   * Event called when toggle button is clicked
   *
   * @param value the toggled value (opposite of what was provided to the component)
   * @default undefined
   */
  onClick?(value: boolean): void
}

export const InputSwitch = ({
  className,
  colorScheme = 'gray',
  isDisabled,
  label,
  value,
  onClick,
}: IInputSwitchProps) => (
  <div
    className={cx(
      'block',
      isDisabled && 'cursor-not-allowed',
      'flex items-center py-2 px-0',
      !label && 'justify-center',
      className
    )}>
    {/* LABEL */}
    {!!label && (
      <div
        className={cx(
          'min-w-0 flex w-full items-center dark:text-gray-200 text-sm',
          colorScheme === 'black' ? 'text-gray-200' : 'text-txt-dark',
          label && 'mr-2'
        )}>
        <div className="min-w-0 truncate">{label}</div>
      </div>
    )}

    {/* TOGGLE BUTTON */}
    <Button.Wrapper
      noStyles
      isDisabled={isDisabled}
      className={cx(
        'group relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none',
        value ? 'bg-primary' : 'bg-gray-300',
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
      )}
      onClick={() => onClick?.(!value)}>
      <span
        aria-hidden="true"
        className={cx(
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white transform transition ease-in-out duration-200 group-hover:scale-90',
          value ? 'translate-x-5' : 'translate-x-0'
        )}
      />
    </Button.Wrapper>
  </div>
)

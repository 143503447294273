// .core
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
// components
import {
  Button,
  ColumnHeader,
  Dialog,
  getTranslation,
  Icon,
  IDefaultProps,
  Translation,
} from 'components'
import { IButtonDefaultProps } from 'components/Button/variants/ButtonDefault'
import { IInputDefaultProps } from './InputDefault'
// libraries
import cx from 'classnames'
import { Field, FieldProps, useFormikContext } from 'formik'
import mimeTypes from 'mime-types'
import prettyBytes from 'pretty-bytes'
import { useDropzone } from 'react-dropzone'
// utils
import { convertToBytes, EFileAcceptTypes } from 'utils'

interface IInputFileCommonProps extends Omit<IInputDefaultProps<File>, 'onChange'> {
  /**
   * Define MIME types that will be accepted by this input
   *
   * @default undefined
   */
  accept?: EFileAcceptTypes | string
  /**
   * Whether when initial files change, state also changes
   *
   * @default undefined
   */
  enableReinitialize?: boolean
  /**
   * `name` for Formik - component has to be used within `Formik` when this prop is provided
   *
   * @default undefined
   */
  formikName?: string
  /**
   * Maximum size in bytes
   *
   * @default 64000000 (64MB -> maximum file size on API)
   */
  maxSize?: number
  /**
   * Whether this field is required
   * Does not allow submitting without selected file(s)
   * TODO: would be nice if it would be taken from Yup validation schema..
   *
   * @default false
   */
  required?: boolean
  /**
   * ColumnHeader title
   *
   * @default
   * ```<Translation keyValue={multiple ? 'general.action.select_files' : 'general.action.select_file'} />```
   */
  title?: ReactNode
  /**
   * Custom trigger node
   *
   * @default
   * ```<Button allowEventPropagation iconLeft="file-plus" label={getTranslation( multiple ? 'general.action.select_files' : 'general.action.select_file' )} />```
   */
  trigger?: ReactNode
}

type IInputFileMultipleDependantProps =
  | {
      /**
       * initial value of this file input
       *
       * @default []
       */
      initialFiles?: File[]
      /**
       * Allows multiple files
       */
      multiple: true
      /**
       * Custom onChange callback with `files` argument
       */
      onChange?: (files: File[]) => void
      /**
       * Custom onSubmit callback with `files` argument
       */
      onSubmit?: (files: File[]) => void
    }
  | {
      /**
       * initial value of this file input
       */
      initialFiles?: File
      /**
       * Does not allow multiple files
       */
      multiple?: false
      /**
       * Custom onChange callback with `file` argument
       */
      onChange?: (file: File) => void
      /**
       * Custom onSubmit callback with `file` argument
       */
      onSubmit?: (file: File) => void
    }

export type IInputFileProps = IInputFileCommonProps & IInputFileMultipleDependantProps

interface IFilePreviewProps extends IDefaultProps {
  file: File
  size: 'sm' | 'lg'
  onRemoveFile?: () => void
}

export const InputFile = ({
  accept,
  className,
  enableReinitialize,
  formikName,
  id,
  isDisabled,
  initialFiles,
  maxSize = convertToBytes(64, 'MB'), // 64MB
  multiple,
  name,
  required,
  title = (
    <Translation
      keyValue={multiple ? 'general.action.select_files' : 'general.action.select_file'}
    />
  ),
  trigger = (
    <Button
      allowEventPropagation
      icon="file-plus"
      label={getTranslation(
        multiple ? 'general.action.select_files' : 'general.action.select_file'
      )}
    />
  ),
  onChange,
  onSubmit,
}: IInputFileProps) => {
  const [files, setFiles] = useState<File[]>(
    (initialFiles ? (multiple ? initialFiles : [initialFiles]) : []) as File[]
  )
  const [submittedFiles, setSubmittedFiles] = useState<File[]>(
    (initialFiles ? (multiple ? initialFiles : [initialFiles]) : []) as File[]
  )
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false)
  const [fileUploadError, setFileUploadError] = useState<boolean>(false)

  useEffect(() => {
    if (
      enableReinitialize &&
      JSON.stringify(multiple ? initialFiles : [initialFiles]) !== JSON.stringify(files)
    ) {
      const fileValue = (initialFiles ? (multiple ? initialFiles : [initialFiles]) : []) as File[]
      setFiles(fileValue)
      setSubmittedFiles(fileValue)
    }
  }, [initialFiles])

  const { getRootProps: getDropContainerProps } = useDropzone({
    accept: accept?.split(',').map(mediaType => mediaType.trim()),
    maxSize,
    onDragEnter: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false),
    onDrop: files => {
      _onChange(undefined, files)
      setIsDraggingOver(false)
    },
  })

  const acceptedFileExtensions = useMemo(
    () =>
      accept
        ?.split(',')
        .map(type => mimeTypes.extension(type.trim().toLowerCase()))
        .filter(Boolean)
        .join(', ')
        .toUpperCase(),
    [accept]
  )

  const commonInputProps = useMemo(
    () => ({
      accept,
      className: 'sr-only',
      disabled: isDisabled,
      id: id?.toString() || name || `file-upload-${new Date().getTime()}`,
      multiple,
      size: maxSize,
      type: 'file',
    }),
    [isDisabled, id, name, multiple, maxSize]
  )

  const _onChange = useCallback(
    (e?: ChangeEvent<HTMLInputElement>, newFiles: File[] = []) => {
      setFileUploadError(false)
      if (e?.target.files?.length || newFiles.length) {
        const newFilesArray = [
          ...files,
          ...newFiles,
          // @ts-ignore
          ...(e?.target.files || []),
        ]

        // resets value - in case user would like to (for some strange reason) upload the same file once again, it would let him to do so..
        if (e?.target) {
          e.target.value = ''
        }

        onChange?.((multiple ? newFilesArray : newFilesArray[0]) as File & File[])
        setFiles(newFilesArray)
      } else {
        setFileUploadError(true)
      }
    },
    [files, multiple, Boolean(onChange)]
  )

  const onFileDelete = useCallback(
    (index: number) => {
      const newFiles = [...files]
      newFiles.splice(index, 1)

      setFiles(newFiles)
    },
    [files, Boolean(onChange)]
  )

  const onResetFiles = useCallback(() => {
    setFiles([])
  }, [])

  return (
    <Dialog.RP
      className={cx('px-4 sm:max-w-max', className)}
      isSmFullHeight={multiple}
      isTriggerDisabled={isDisabled}
      trigger={trigger}
      zIndex={50}
      onToggle={() => setFileUploadError(false)}>
      {({ onToggle }) => (
        <>
          <ColumnHeader>{title}</ColumnHeader>
          <div
            className={cx(
              'flex flex-col sm:flex-row transition-all duration-300 flex-grow overflow-hidden',
              files.length ? 'gap-4' : 'gap-0'
            )}>
            {multiple || files.length === 0 ? (
              <div
                {...getDropContainerProps()}
                className={cx(
                  'min-h-64 max-h-64 w-auto sm:w-80 flex justify-center p-6 border-2 border-gray-300 rounded-md items-center transition-all duration-300 mx-3 sm:mx-0',
                  isDraggingOver ? 'bg-gray-100 border-solid' : 'bg-white border-dashed'
                )}>
                <div className="space-y-1 text-center content-center flex flex-col items-center">
                  <Icon className="text-gray-600" name="cloud-upload" size="3x" type="duotone" />

                  <div className="flex text-sm text-gray-600">
                    <label
                      className="relative cursor-pointer rounded-md font-medium text-primary hover:text-primary-hover focus-within:outline-none focus-within:text-primary-hover"
                      htmlFor={commonInputProps.id}>
                      <span>
                        <Translation keyValue="general.action.select_file" />
                      </span>
                      {formikName ? (
                        <Field name={formikName}>
                          {(fieldProps: FieldProps) => (
                            <input
                              {...commonInputProps}
                              name={fieldProps.field.name}
                              onBlur={fieldProps.field.onBlur}
                              onChange={_onChange}
                            />
                          )}
                        </Field>
                      ) : (
                        <input {...commonInputProps} name={name} onChange={_onChange} />
                      )}
                    </label>
                    <Translation
                      className="pl-1 hidden sm:inline lowercase"
                      keyValue="general.action.or_drag_and_drop"
                    />
                  </div>

                  {Boolean(acceptedFileExtensions || maxSize) && (
                    <p className="text-xs text-gray-500 flex items-center flex-col">
                      {acceptedFileExtensions}

                      {(maxSize || 0) > 0 && (
                        <Translation
                          keyValue="general.label.up_to[upTo]"
                          variables={{
                            upTo: prettyBytes(maxSize as number),
                          }}
                        />
                      )}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <FilePreview file={files[0]} size="lg" onRemoveFile={onResetFiles} />
            )}

            {multiple && (
              <ul
                className={cx(
                  'overflow-y-auto items-start content-start ease-in-out transform sm:transition-all duration-300 grid gap-2 max-h-auto sm:max-h-64 px-3 sm:pl-0',
                  files.length > 0 ? 'scale-x-100 w-full sm:w-72' : 'scale-x-0 w-0'
                )}>
                {files.map((file, index) => (
                  <li
                    key={`${file.name}_${index}`}
                    className="col-span-1 flex shadow-sm rounded-md h-16 w-auto overflow-x-hidden">
                    <div
                      className={cx(
                        'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md overflow-x-hidden',
                        'border border-r-0 border-gray-200 rounded-l-md truncate bg-gray-50'
                      )}>
                      <FilePreview file={file} size="sm" />
                    </div>
                    <div className="flex-1 flex items-center justify-between border border-l-0 border-gray-200 bg-white rounded-r-md truncate">
                      <div className="flex-1 px-4 py-2 text-sm truncate">
                        <span className="text-gray-900 font-medium overflow-ellipsis whitespace-nowrap">
                          {file.name}
                        </span>
                        <p className="text-gray-500">
                          {prettyBytes(file.size, { maximumFractionDigits: 1 })}
                        </p>
                      </div>
                      <div className="flex-shrink-0 pr-2">
                        <button
                          className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500"
                          onClick={() => onFileDelete(index)}>
                          <span className="sr-only">
                            <Translation keyValue="general.action.delete" />
                          </span>
                          <Icon name="times" type="light" />
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {fileUploadError && (
            <p className="mt-5 text-center text-danger text-sm">
              <Translation keyValue="general.label.toast_invalid_size_or_type" />
            </p>
          )}

          <div className="flex flex-row mt-5 justify-center sm:justify-end sm:mr-3">
            <SubmitButton
              files={files}
              formikName={formikName}
              multiple={multiple}
              required={!!required}
              onSubmit={(f: File | File[]) => {
                onSubmit?.(f as File & File[])
                setSubmittedFiles(f instanceof Array ? f : [f].filter(Boolean))
                setTimeout(() => {
                  onToggle(false)()
                }, 0)
              }}
            />
            <Button.Close
              className="ml-4"
              label={getTranslation('general.action.cancel')}
              onClick={onToggle(false)}
            />
          </div>
        </>
      )}
    </Dialog.RP>
  )
}

export const FilePreview = ({ className, file, size, onRemoveFile }: IFilePreviewProps) => {
  const documentTypes = useRef<string[]>(
    EFileAcceptTypes.DOCUMENT.split(',').map(type => type.trim())
  ).current
  const imageTypes = useRef<string[]>(EFileAcceptTypes.IMAGE.split(',').map(type => type.trim()))
    .current
  const videoTypes = useRef<string[]>(EFileAcceptTypes.VIDEO.split(',').map(type => type.trim()))
    .current

  const isLarge = size === 'lg'
  const iconSize = isLarge ? '5x' : '3x'
  const isImage = imageTypes.includes(file.type)
  const iconName = documentTypes.includes(file.type)
    ? 'file-alt'
    : videoTypes.includes(file.type)
    ? 'file-video'
    : 'file'

  return (
    <div
      className={cx(
        'w-full max-w-full',
        isLarge
          ? 'h-64 sm:min-w-80 rounded border-dashed overflow-hidden border-gray-300 border-2'
          : 'h-full',
        className
      )}>
      {isImage ? (
        isLarge ? (
          <div className="w-full h-full relative rounded overflow-hidden">
            <img
              className="w-full h-full object-cover object-center rounded"
              //@ts-ignore
              src={file.fileUrl || URL.createObjectURL(file)}
            />
            <div className="absolute z-10 top-4 right-4 bg-gray-500 bg-opacity-50 backdrop-filter backdrop-blur rounded-full w-8 h-8 justify-center items-center content-center flex cursor-pointer hover:bg-gray-900">
              <Icon
                className="text-white"
                name="times"
                size="lg"
                type="light"
                onClick={onRemoveFile}
              />
            </div>
          </div>
        ) : (
          <img
            className="w-full h-full object-cover object-center rounded"
            //@ts-ignore
            src={file.fileUrl || URL.createObjectURL(file)}
          />
        )
      ) : (
        <div className="w-full text-center flex flex-col h-full self-center justify-center items-center content-center">
          <Icon className="text-gray-700" name={iconName} size={iconSize} type="light" />

          {isLarge && (
            <div className="flex content-center justify-center flex-col items-center px-4 py-2 text-sm truncate w-full overflow-hidden">
              <span className="text-gray-900 font-medium overflow-ellipsis whitespace-nowrap w-full overflow-hidden">
                {file.name}
              </span>
              <p className="text-gray-500">
                {file.size ? prettyBytes(file.size, { maximumFractionDigits: 1 }) : null}
              </p>
              <div
                className="flex flex-row justify-center items-center content-center cursor-pointer text-gray-500 hover:text-gray-900 mt-1"
                onClick={onRemoveFile}>
                <Icon className="pr-1" name="times" type="light" />
                <Translation className="text-center" keyValue="general.action.remove" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

interface ISubmitButtonCommonProps {
  files: File[]
  formikName?: string
  required: boolean
}

type ISubmitButtonMultipleDependantProps =
  | {
      multiple: true
      onSubmit?: (files: File[]) => void
    }
  | {
      multiple?: false
      onSubmit?: (file: File) => void
    }

type ISubmitButtonProps = ISubmitButtonCommonProps & ISubmitButtonMultipleDependantProps

const SubmitButton = ({ files, formikName, multiple, required, onSubmit }: ISubmitButtonProps) => {
  const commonButtonProps: IButtonDefaultProps = {
    color: 'success',
    icon: 'save',
    isDisabled: required && files.length === 0,
    label: getTranslation(
      files.length > 1 ? 'general.action.submit_files[count]' : 'general.action.submit_file',
      {
        count: files.length,
      }
    ),
  }

  return formikName
    ? (function SubmitButtonWithFormik() {
        const { setFieldValue } = useFormikContext()

        return (
          <Button
            {...commonButtonProps}
            onClick={() => {
              setFieldValue(formikName, multiple ? files : files[0] || null)
              onSubmit?.((multiple ? files : files[0]) as File & File[])
            }}
          />
        )
      })()
    : (() => (
        <Button
          {...commonButtonProps}
          onClick={() => {
            onSubmit?.((multiple ? files : files[0]) as File & File[])
          }}
        />
      ))()
}

import { gql } from '@apollo/client'

export const TranslationQueries = {
  GET_TRANSLATIONS: gql`
    query GetTranslations(
      $after: String
      $first: Int
      $orderBy: [SysTranslationsOrderBy!]
      $search: String
      $showOnlyNotTranslated: [ID!]
    ) {
      sysTranslations(
        after: $after
        first: $first
        orderBy: $orderBy
        search: $search
        showOnlyNotTranslated: $showOnlyNotTranslated
      ) {
        edges {
          cursor
          node {
            id
            description
            key

            translations {
              sysLanguageId
              translation
            }
          }
        }

        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }

        totalCount
      }
    }
  `,

  GET_TRANSLATIONS_IN_LANGUAGE: gql`
    query GetTranslationsInLanguage(
      $sysLanguageId: ID
      $fallbackSysLanguageCodeShort: String!
      $updatedAt: String
    ) {
      sysTranslationsInLanguage(
        sysLanguageId: $sysLanguageId
        fallbackSysLanguageCodeShort: $fallbackSysLanguageCodeShort
        updatedAt: $updatedAt
      ) {
        key
        translation
      }
    }
  `,
}

// core
import React, { useCallback } from 'react'
// components
import { IDefaultWrapperProps } from 'components'
import { IButtonDefaultProps, TButtonColor } from './ButtonDefault'
// libraries
import cx from 'classnames'
// utils
import { IObject, stopEvent } from 'utils'

/**
 * Uses the same props as `ButtonDefault` with the addition of `children`
 */
interface IButtonWrapperProps extends IDefaultWrapperProps, IButtonDefaultProps {}

const buttonColorClassMap: { [key in TButtonColor]: IObject<string> } = {
  danger: { default: 'bg-danger', hover: 'hover:bg-danger-hover' },
  grey: { default: 'bg-grey', hover: 'hover:bg-grey-hover' },
  primary: { default: 'bg-primary', hover: 'hover:bg-primary-hover' },
  success: { default: 'bg-success', hover: 'hover:bg-success-hover' },
  secondary: { default: 'bg-secondary', hover: 'hover:bg-secondary-hover' },
  warning: { default: 'bg-warning', hover: 'hover:bg-warning-hover' },
}

/**
 * Wrapper component that wraps its children in `<button>` element.
 * Uses same props as `ButtonDefault`
 */
export const ButtonWrapper = ({
  allowEventPropagation = false,
  className,
  children,
  color = 'primary',
  isDisabled = false,
  isLoading = false,
  label,
  noStyles,
  size = 'medium',
  type = 'button',
  onClick,
}: IButtonWrapperProps) => {
  /**
   * Local `onClick` handler
   */
  const _onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (isDisabled || isLoading) return

      if (!allowEventPropagation) stopEvent(event)
      onClick?.()
    },
    [isDisabled || isLoading, onClick]
  )

  /**
   * Concats all Tailwind classes
   * @returns string
   */
  const twCSS = (): string => {
    const defaultClasses =
      'inline-flex items-center rounded transition text-sm text-white font-medium'
    const bgColor = buttonColorClassMap[color].default
    const _isDisabled = `${
      isDisabled ? 'cursor-not-allowed opacity-50' : buttonColorClassMap[color].hover
    }`
    const _isLoading = isLoading && 'cursor-wait'

    const sizeSmall = size === 'small' ? (label ? 'h-5' : 'h-5 w-5') : ''
    const sizeMedium = size === 'medium' ? (label ? 'h-7 py-1' : 'h-7 w-7') : ''
    const sizeLarge = size === 'large' ? (label ? 'h-9 py-2' : 'h-9 w-9') : ''

    return cx(
      'focus:outline-none',
      !noStyles && [
        defaultClasses,
        bgColor,
        sizeSmall,
        sizeMedium,
        sizeLarge,
        _isDisabled,
        _isLoading,
      ],
      className
    )
  }
  return (
    <button className={twCSS()} disabled={isDisabled || isLoading} type={type} onClick={_onClick}>
      {children}
    </button>
  )
}

// core
import React from 'react'
// components
import { Avatar, Icon, TKey } from 'components'
import { IItemVariantProps, ItemDefault } from './ItemDefault'
// libraries
import cx from 'classnames'
// utils
import { stopEvent } from 'utils'

export interface IItemUserProps extends IItemVariantProps {
  /**
   * URL to the user's avatar file location
   *
   * If not provided renders the default icon for avatars
   *
   * @default undefined
   */
  avatarUrl?: string
  /**
   * Email of a user
   */
  email?: string
  /**
   * Whether the current item is selected within the list, changes CSS
   */
  isActive?: boolean
  /**
   * Full name of a user
   */
  name?: string
  /**
   * Event called when user clicks on the item
   * @param id ID of the user
   */
  onClick?(id: TKey): void
}

export const ItemUser = ({
  avatarUrl,
  className,
  email,
  id,
  name,
  isActive,
  onClick,
}: IItemUserProps) => {
  const _onClick = (e: React.MouseEvent) => {
    stopEvent(e)
    onClick?.(id)
  }

  const twCSS = cx(
    'group block rounded transition',
    isActive ? 'bg-primary text-white' : onClick ? 'hover:bg-primary hover:text-white' : null,
    className
  )

  return (
    <ItemDefault>
      <div className={twCSS} onClick={_onClick}>
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0 group-hover:text-white">
              <Avatar size="large" url={avatarUrl} />
            </div>

            {/* #TODO: does not support dark theme */}
            <div
              className={cx(
                'min-w-0 flex-1 px-4',
                onClick && 'group-hover:text-white',
                isActive ? 'text-white' : 'text-txt-light-2'
              )}>
              <p className="text-sm font-medium truncate">{name}</p>
              <p className="flex items-center text-xs truncate">{email}</p>
            </div>
          </div>

          {/* #TODO: does not support dark theme */}
          <Icon
            className={cx(
              'group-hover:text-white',
              !onClick ? 'hidden' : isActive ? 'text-white' : 'text-txt-light-2'
            )}
            name="chevron-right"
            size="xs"
            type="regular"
          />
        </div>
      </div>
    </ItemDefault>
  )
}

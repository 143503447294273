// core
import React from 'react'
// components
import { Button } from 'components/Button/Button'
import { IItemVariantProps, ItemDefault } from './ItemDefault'
// libraries
import cx from 'classnames'

export interface IItemDetailedProps extends IItemVariantProps {
  /**
   * Upper text to display
   *
   * @default '''
   */
  title?: string
  /**
   * Lower text to display
   *
   * @default ''
   */
  value?: string | number | null
  /**
   * If specified, renders an edit icon button on the right of `title` and calls provided method when clicked
   *
   * @default undefined
   */
  onEdit?(): void
}

export const ItemDetailed = ({ className, value, title, onEdit }: IItemDetailedProps) => {
  const twCSS = cx('block text-sm', className)

  return (
    <ItemDefault>
      <div className={twCSS}>
        <div className="py-4 px-6 space-y-1">
          <div className="flex justify-between items-center min-w-0 text-gray-500 dark:text-gray-400">
            <span>{title}</span>
            {onEdit && <Button.Edit isIconOnly size="small" onClick={onEdit} />}
          </div>
          <p className="break-all dark:text-white">{value}</p>
        </div>
      </div>
    </ItemDefault>
  )
}

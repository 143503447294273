import { gql } from '@apollo/client'

export const AuthMutations = {
  CONTACT_SUPPORT: gql`
    mutation ContactSupport($email: String!, $message: String!) {
      contactSupport(email: $email, message: $message)
    }
  `,

  LOGIN: gql`
    mutation Login($email: String!, $password: String!) {
      login(email: $email, password: $password)
    }
  `,

  REFRESH_TOKEN: gql`
    mutation RefreshToken {
      refreshToken
    }
  `,

  REQUEST_PASSWORD_RESET: gql`
    mutation RequestPasswordReset($id: ID, $email: String) {
      requestPasswordReset(id: $id, email: $email)
    }
  `,

  SET_NEW_PASSWORD: gql`
    mutation SetNewPassword($email: String!, $newPassword: String!, $secret: String) {
      setNewPassword(email: $email, newPassword: $newPassword, secret: $secret)
    }
  `,
}

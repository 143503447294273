// core
import React from 'react'
import { IRoutes } from './index'
// pages
const LoginPage = React.lazy(() => import('pages/Auth/LoginPage'))
const SetNewPasswordPage = React.lazy(() => import('pages/Auth/SetNewPasswordPage'))
// const NewUserSetPassword = React.lazy(() => import('pages/Auth/NewUserSetPassword'))
// const MailPathResolver = React.lazy(() => import('pages/MailPathResolver/MailPathResolver'))

const routes: IRoutes[] = [
  { component: LoginPage, path: '/login' },
  { component: SetNewPasswordPage, path: '/set-new-password' },
  // { component: NewUserSetPassword, path: '/new-user-set-password', },
  // { component: MailPathResolver, path: '/mail/:hash', },
]

export const AuthRoutes = routes

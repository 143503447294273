// core
import React, { useState } from 'react'
// components
import { getTranslation, Icon } from 'components'
import { Loader } from 'components/Loader/Loader'
import { IButtonDefaultProps } from './ButtonDefault'
import { ButtonWrapper } from './ButtonWrapper'
// libraries
import cx from 'classnames'

type TState = 'initial' | 'confirmation' | null

interface IButtonDeleteCommonProps extends Omit<IButtonDefaultProps, 'isLoading' | 'isIconOnly'> {
  /**
   * @default 'general.action.delete'
   */
  label?: string
  /**
   * The confirmation question - text to display after clicking on delete once
   *
   * @default 'general.action.confirmation'
   */
  labelConfirmation?: string
  /**
   * Event called when the trash icon (in confirmation mode) is clicked
   *
   * Excepts Promise since an API call should be made
   *
   * @default undefined
   */
  onClick?(): Promise<any>
}

type IButtonDeleteDependantProps =
  | {
      /**
       * Whether confirmation is vertical
       */
      verticalConfirmation?: boolean
      /**
       * Whether to render only icon without the label
       *
       * @default false
       */
      isIconOnly: true
    }
  | {
      /**
       * Whether confirmation is vertical
       */
      verticalConfirmation?: undefined
      /**
       * Whether to render only icon without the label
       *
       * @default false
       */
      isIconOnly?: false
    }

type IButtonDeleteProps = IButtonDeleteCommonProps & IButtonDeleteDependantProps

export const ButtonDelete = ({
  className,
  isIconOnly,
  label = getTranslation('general.action.delete'),
  labelConfirmation = getTranslation('general.action.confirmation'),
  verticalConfirmation,
  onClick,
  ...otherProps
}: IButtonDeleteProps) => {
  // Current state of the button
  const [state, setState] = useState<TState>(null)
  // Whether the delete icon was clicked and a callback is being executed
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
   * Event called when either the `trash` or `x` icon is clicked
   * @param iconType which of the two icons was clicked
   */
  const onClickIcon = (iconType: 'close' | 'delete') => async (e: React.MouseEvent) => {
    // Allows the icons to be clickable since the whole thing is wrapped in `ButtonWrapper`
    e.preventDefault()
    e.stopPropagation()

    // Prevents button spamming
    if (isLoading) return

    // Execute component's `onClick` callback and set loading state
    if (iconType === 'delete') {
      setIsLoading(true)
      await onClick?.()
      setIsLoading(false)
    }

    setState('initial')
  }

  /**
   * Concats all Tailwind classes
   * @returns string
   */
  const twCSS = (): string => {
    const iconRelatedClasses = isIconOnly ? 'justify-center px-2' : 'w-max px-3'
    const stateBasedClasses =
      state === 'confirmation'
        ? verticalConfirmation
          ? 'space-y-4 cursor-default sm:h-auto'
          : 'space-x-4 cursor-default'
        : 'space-x-2'

    return cx(iconRelatedClasses, stateBasedClasses, className)
  }

  const loadingCursor = isLoading ? 'cursor-wait' : ''

  return (
    <ButtonWrapper
      className={twCSS()}
      color="danger"
      isLoading={isLoading}
      label={isIconOnly ? undefined : label}
      onClick={() => setState('confirmation')}
      {...otherProps}>
      {state === 'initial' || state === null ? (
        <>
          <Icon name="trash-alt" type="regular" />

          {!isIconOnly && <span>{label}</span>}
        </>
      ) : (
        <>
          {!isIconOnly && <span className="w-max">{labelConfirmation}</span>}

          <span
            className={cx(
              'flex items-center',
              verticalConfirmation ? 'flex-col space-y-3 justify-center py-2' : 'space-x-3'
            )}>
            <div className="flex items-center p-1 bg-white rounded-full text-danger">
              {isLoading ? (
                <Loader className="text-danger" />
              ) : (
                <Icon
                  className={loadingCursor}
                  name="trash-alt"
                  size="xs"
                  type="regular"
                  onClick={onClickIcon('delete')}
                />
              )}
            </div>

            <Icon
              className={cx(loadingCursor)}
              name="times"
              type="regular"
              onClick={onClickIcon('close')}
            />
          </span>
        </>
      )}
    </ButtonWrapper>
  )
}

// core
import React, { useCallback, useState } from 'react'
// components
import { TKey } from 'components'
import { ExpandableBox as Expandable } from 'components/ExpandableBox/ExpandableBox'
import { IItemVariantProps } from 'components/Item/variants/ItemDefault'
import { ItemOption } from 'components/Item/variants/ItemOption'
import { IListVariantProps, ListDefault } from './ListDefault'

interface IListExpandableProps<T> extends IListVariantProps<T> {
  /**
   * ID of currently expanded item within the `List`
   */
  activeItemId?: TKey
  /**
   * Collection of data to loop through
   * For each item an `ItemOption` is rendered to serve as a "trigger" for the `Expandable` beneath it
   *
   * @default []
   */
  items?: (T & IItemVariantProps)[]
  /**
   * Render method that renders custom content for `Expandable` under each item
   * @param item current iteration of an item
   */
  renderItemContent(item: T & IItemVariantProps): React.ReactNode
}

export const ListExpandable = <T,>({
  activeItemId,
  className,
  classNameItem,
  items = [],
  renderItemContent,
  ...otherProps
}: IListExpandableProps<T>) => {
  const [expandedItemId, setExpandedItemId] = useState<TKey | null>(activeItemId)

  // @ts-ignore
  const isActive = (item: T) => expandedItemId === item.id

  const onToggleExpand = useCallback((val: TKey) => {
    setExpandedItemId(prev => (val === prev ? null : val))
  }, [])

  return (
    <ListDefault<T>
      className={className}
      items={items}
      renderItem={item => (
        <>
          {/* @ts-ignore */}
          <ItemOption
            {...item}
            key={item.id}
            icon={isActive(item) ? 'chevron-up' : 'chevron-down'}
            isActive={isActive(item)}
            onClick={onToggleExpand}
          />

          <Expandable expanded={isActive(item)}>{renderItemContent(item)}</Expandable>
        </>
      )}
      {...otherProps}
    />
  )
}

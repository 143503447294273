// core
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
// components
import { getTranslation, Icon, IDefaultProps } from 'components'
import { IInputDefaultProps } from './InputDefault'
// libraries
import cx from 'classnames'
import { useHistory } from 'react-router-dom'
// utils
import { parseArray, SearchContext } from 'utils'

interface IInputSearchHeaderProps extends IInputDefaultProps<string> {
  /**
   * Array of items in which will be looked for a match
   */
  items?: IItemSearchProps[]
}

export const InputSearchHeader = ({
  className,
  classNameInput,
  items = [],
}: IInputSearchHeaderProps) => {
  const searchApi = useContext(SearchContext)

  const contentRef = useRef<HTMLInputElement>(null)
  const hiddenRef = useRef<HTMLSpanElement>(null)
  const timeout = useRef<NodeJS.Timeout>()

  const [fieldValue, setFieldValue] = useState<string>('')

  if (contentRef.current && hiddenRef.current) {
    const input = contentRef.current
    const hiddenSpan = hiddenRef.current

    // size of the input will resize based on the length of input value
    // if input has no value, it's width is set to 120px (for placeholder to be visible)
    // after a value is provided, input will shrink/grow based on value's length
    const resize = () => (
      setFieldValue(contentRef.current?.value || ''),
      (hiddenSpan.textContent = input.value),
      (input.style.width = `${(hiddenSpan.offsetWidth || 120) + 5}px`)
    )

    contentRef.current.addEventListener('input', resize)
  }

  useEffect(() => {
    setFieldValue(searchApi.value || '')
  }, [searchApi.value])

  const onSubmit = useCallback(() => {
    const value = fieldValue.trim() || ''
    searchApi.setValue(value || null)
    contentRef.current?.focus()
  }, [searchApi, fieldValue])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.value)

      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        onSubmit()
      }, 300)
    },
    [timeout, fieldValue]
  )

  return (
    <>
      {!searchApi.isHidden && (
        <div className="group absolute top-3 inset-x-0 flex justify-center 2xl:w-full">
          <div
            className={cx(
              'flex w-max max-w-1/2 md:max-w-3/5 lg:max-w-3/4 2xl:max-w-10/12',
              className
            )}>
            <div className="input-search dark:bg-dark">
              <Icon name="search" />

              <span ref={hiddenRef} className="absolute z-0 invisible" />

              <input
                ref={contentRef}
                autoComplete="off"
                className={cx(
                  'block group h-full pl-3 pr-1 py-1 text-sm border-transparent text-black dark:placeholder-txt-light dark:focus:text-white placeholder-txt-light italic focus:outline-none focus:ring-0 focus:border-transparent',
                  classNameInput
                )}
                id="search_field"
                placeholder={getTranslation('general.label.search_something')}
                style={{ width: 120 }}
                type="search"
                value={fieldValue}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      )}

      {!!(fieldValue.length && items.length) && (
        <ListSearch items={items} searchTerm={fieldValue} />
      )}
    </>
  )
}

interface IListSearchProps extends IDefaultProps {
  /**
   * Array of items in which will be looked for a match - passed from parent component
   */
  items?: IItemSearchProps[]
  /**
   * Searched value
   */
  searchTerm: string
}

const ListSearch = ({ className, items = [], searchTerm = '' }: IListSearchProps) => {
  const twCSS = (): string => {
    const defaultClasses =
      'fixed top-20 -mt-4 left-1/2 transform -translate-x-1/2 2xl:ml-40 bg-white rounded-md w-80 h-auto max-h-48 divide-y divide-gray-200 overflow-auto shadow-2xl'

    return cx(defaultClasses, className)
  }
  return (
    <div className={twCSS()}>
      {parseArray(items)
        .searchBy('title', searchTerm)
        .map((item, index) => (
          <ItemSearch key={index} {...item} />
        ))}
    </div>
  )
}

interface IItemSearchProps {
  /**
   * Item title
   */
  title: string
  /**
   * Subtitle of the item - category or module in which the item is placed
   */
  subtitle?: string
  /**
   * URL to redirect after click on item
   */
  url?: string
}

const ItemSearch = ({ title, subtitle, url }: IItemSearchProps) => {
  const history = useHistory()

  const _onClick = useCallback(() => url && history.replace(`/${url}`), [url])

  return (
    <li
      className={cx(
        'group flex justify-between items-center p-4 text-gray-500',
        url && 'hover:bg-primary hover:text-white cursor-pointer'
      )}
      onClick={_onClick}>
      <span className="text-xs text-current font-semibold">{title}</span>
      <span className={cx('text-xxs text-primary', url && 'group-hover:text-white')}>
        {subtitle}
      </span>
    </li>
  )
}

// .core
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// components
import { getTranslation, Icon, Tooltip } from 'components'
import { IInputDefaultProps } from './InputDefault'
// libraries
import cx from 'classnames'
import { useField, useFormikContext } from 'formik'
import { get } from 'lodash'
import Select, {
  ActionMeta,
  components as SelectComponents,
  ControlProps,
  OptionProps,
  OptionsType,
  Props as RegularProps,
  SingleValueProps,
  ValueType,
} from 'react-select'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
// utils
import { IObject } from 'utils'

export interface IValue {
  id: string
  title: string
}

// Options for custom icon select should be of this type to handle displaying icon preview
export interface IIconValue extends IValue {
  type?: string
}

interface IInputSelectCommonProps extends Omit<IInputDefaultProps<any>, 'onChange' | 'name'> {
  /**
   * Default value of input
   */
  // defaultValue?: IValue[] | IValue
  /**
   * Whether the input allows to choose multiple values
   *
   * @default 'false'
   */
  isMulti?: boolean
  /**
   * Whether is input searchable - you can type in it
   *
   * @default 'true'
   */
  isSearchable?: boolean
  /**
   * Whether is input small
   *
   * @default 'false'
   */
  isSmall?: boolean
  /**
   * Possible select options rendered in menu
   *
   * @default '[]'
   */
  options?: OptionsType<IValue | IIconValue>
  /**
   * Not required - input is in formik
   *
   * @note ReactSelect lib doesnt support `undefined` as a value, or rather the only way how to remove value from the select is to set it to `null`
   */
  value?: IValue | IIconValue | IValue[] | null
  onChange?: (value: IValue | IValue[], actionMeta: ActionMeta<IValue>) => void
  onCreateOption?: (value: string) => void
}

type IInputSelectFormikDependantProps =
  | {
      /**
       * Name of the input necessary for setting input value when input is in formik
       */
      name: string
    }
  | {
      /**
       * Name of the input not necessary for setting input value - input is not in formik
       */
      name?: string
    }

type IInputSelectProps = IInputSelectCommonProps & IInputSelectFormikDependantProps

export const InputSelect = ({
  className,
  colorScheme = 'gray',
  isDisabled = false,
  isMulti = false,
  isSearchable = true,
  isSmall = false,
  label,
  isFocused,
  isFocusedHighlight,
  name,
  options = [],
  placeholder = getTranslation('general.action.select_value'),
  tooltip,
  value,
  onChange,
  onCreateOption,
}: IInputSelectProps) => {
  const [error, setError] = useState<any>(null)
  const { Control, Option, SingleValue } = SelectComponents

  const CustomControl = (props: ControlProps<any>) => (
    <div className="relative h-full">
      {error ? (
        <div className="p-1 absolute inset-y-0 flex items-center right-12 z-5 mb-4">
          <Tooltip className="flex" color="danger" message={error}>
            <Icon className="text-danger" name="exclamation-circle" size="lg" />
          </Tooltip>
        </div>
      ) : tooltip ? (
        <div className="p-1 absolute inset-y-0 flex items-center right-12 z-5 mb-4 mr-4">
          <Tooltip className="flex" {...tooltip}>
            <Icon className="text-primary" name="exclamation-circle" size="lg" />
          </Tooltip>
        </div>
      ) : null}

      <Control {...props} />
    </div>
  )

  const CustomOption = (props: OptionProps<any>) => (
    <Option
      className={cx(
        'flex justify-between items-center transition',
        props.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        colorScheme === 'black' && 'text-white'
      )}
      {...props}>
      <div>
        {'type' in options[0] && <Icon className="mr-3" name={props.data.title} />}
        {props.data.title}
      </div>
      {props.isSelected && <Icon className="text-success" name="check" type="regular" />}
    </Option>
  )

  const CustomSingleValue = (props: SingleValueProps<any>) => {
    return (
      <SingleValue
        className={cx(
          (colorScheme === 'black' || colorScheme === 'table') && 'text-white',
          'text-sm'
        )}
        {...props}>
        {'type' in options[0] && <Icon className="mr-3" name={props.data.title} />}
        {props.data.title}
      </SingleValue>
    )
  }

  /**
   * Retrieves a prop from `IValue` structure to use as a `title` for options
   */
  const getOptionLabel = useCallback((option: IValue): string => option.title, [])

  /**
   * Retrieves a prop from `IValue` structure to use as an `value` for options
   */
  const getOptionValue = useCallback((option: IValue): string => option.id, [])

  const _onChange = (value: ValueType<IValue | IValue[]>, actionMeta: ActionMeta<IValue>) => {
    if (isDisabled) return

    onChange?.(isMulti ? (value as IValue[]) : (value as IValue), actionMeta)
  }

  const backgroundColor =
    colorScheme === 'black'
      ? '#000000'
      : colorScheme === 'gray'
      ? '#F4F4F5'
      : colorScheme === 'table'
      ? '#252525'
      : '#FFFFFF'

  const customStyles = useMemo(
    () => ({
      control: (base: React.CSSProperties) => ({
        ...base,
        display: 'flex',
        paddingRight: '10px',
        background:
          colorScheme === 'black'
            ? '#000000'
            : colorScheme === 'gray'
            ? '#F4F4F5'
            : colorScheme === 'table'
            ? '#252525'
            : '#FFFFFF',
        border: '0',
        outline: 'none',
        boxShadow: 'none',
        pointerEvents: 'auto' as const,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        minHeight: isSmall ? undefined : 'fit-content',
        height: '100%',
      }),
      container: () => ({
        height: '100%',
      }),
      clearIndicator: () => ({
        paddinTop: '0px',
        paddingRight: '3px',
        color: colorScheme === 'black' ? '#71717A' : '#6B7280',
        transform: 'translateY(-10px)',
      }),
      option: (base: React.CSSProperties, { isFocused }: IObject<any>) => ({
        ...base,
        backgroundColor: isFocused ? '#3B82F6' : 'transparent',
        color:
          isFocused || colorScheme === 'black' || colorScheme === 'table' ? '#FFFFFF' : '#000000',
      }),
      dropdownIndicator: (_: React.CSSProperties, state: IObject<any>) => ({
        padding: isSmall ? '4px' : '8px',
        paddinTop: '0px',
        color: colorScheme === 'black' ? '#71717A' : '#6B7280',
        transition: 'all .4s ease',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        opacity: isDisabled ? 0.5 : 1,
      }),
      valueContainer: (base: React.CSSProperties) => ({
        ...base,
        marginTop: 0,
        paddingtop: 0,
        paddingLeft: '10px',
        overflow: 'scroll',
      }),
      placeholder: (base: React.CSSProperties) => ({
        ...base,
        fontStyle: 'italic',
        fontSize: '14px',
        color: error ? '#EF4444' : colorScheme === 'black' ? '#FFFFFF' : base.color,
        opacity: isDisabled ? 0.5 : 1,
      }),
      multiValue: (base: React.CSSProperties) => ({
        ...base,
        background: '#DBEAFE',
        color: '#3B82F6',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
      }),
      multiValueRemove: () => ({
        color: '#3B82F6',
        padding: '0 4px',
        paddingBottom: 2.5,
      }),
      multiValueLabel: (base: React.CSSProperties) => ({
        ...base,
        color: '#3B82F6',
      }),
      input: (base: React.CSSProperties) => ({
        ...base,
        color: colorScheme === 'black' || colorScheme === 'table' ? '#FFFFFF' : base.color,
      }),
      menu: (base: React.CSSProperties) => ({
        ...base,
        background: backgroundColor,
        border: 'none',
        fontSize: '13px',
        marginTop: 0,
        zIndex: 50,
      }),
      singleValue: (base: React.CSSProperties) => ({
        ...base,
        overflow: 'unset',
        textOverflow: 'clip',
      }),
    }),
    [colorScheme, error, isDisabled, backgroundColor]
  )

  const SelectComponent = (props: RegularProps<IValue> | CreatableProps<IValue>) =>
    onCreateOption ? <CreatableSelect {...props} /> : <Select {...props} />

  const SelectInput = ({
    name,
    value,
    onChange,
  }: {
    name?: string
    value?: IValue | IValue[] | null
    onChange?: (value: ValueType<IValue | IValue[]>, actionMeta: ActionMeta<IValue>) => void
  }) => (
    <div
      className={cx(
        'relative rounded pt-5 shadow-lg',
        colorScheme === 'black' ? 'shadow-white-shadow' : 'dark:shadow-white-shadow',
        error ? 'border border-danger' : undefined,
        isFocusedHighlight && 'animate-highlight',
        className
      )}
      style={{ backgroundColor }}>
      <label
        className={cx(
          'absolute left-0 top-1.5 ml-3 text-xxs2 z-5',
          colorScheme === 'black' ? 'text-white' : 'text-gray-700',
          error && 'text-danger',
          isDisabled && 'opacity-50'
        )}>
        {label}
      </label>

      <SelectComponent
        autoFocus={isFocused || isFocusedHighlight}
        components={{
          Control: CustomControl,
          DropdownIndicator: () => (
            <div className="mr-0.5 mb-3" style={{ transform: 'translateY(-3px)' }}>
              <Icon
                className="icon text-txt-light-2 cursor-pointer mr-4"
                name="chevron-down"
                size="sm"
                type="solid"
              />
            </div>
          ),
          IndicatorSeparator: () => null,
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        getNewOptionData={(inputValue: string) => ({
          id: inputValue,
          title: getTranslation('general.action.create[value]', { value: inputValue }),
        })}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuPortalTarget={document.getElementById('select-menu')}
        name={name}
        options={options}
        placeholder={placeholder}
        styles={customStyles}
        value={value}
        onCreateOption={onCreateOption}
        onChange={onChange}
      />
    </div>
  )

  const InputSelectFormik = () => {
    const _name = name || 'default-input-name'
    const { errors, setFieldValue, validateForm } = useFormikContext()
    const [field] = useField<string>(_name)
    const error = get(errors, _name)

    // "Refresh" the value of the local error state
    // #NOTE: Formik validates entire form on every value change, w/o this, the select didnt get validated with the rest of the form, only after its value was changed
    useEffect(() => {
      setError(error)
    }, [error, field.value])

    const _onChange = (value: ValueType<IValue | IValue[]>, actionMeta: ActionMeta<IValue>) => {
      if (isDisabled) return

      onChange?.(isMulti ? (value as IValue[]) : (value as IValue), actionMeta)

      setFieldValue(
        _name,
        isMulti ? ((value || []) as IValue[]).map((item: IValue) => item.id) : (value as IValue).id,
        true
      )

      // #NOTE: there were several problems here:
      // 1. if u have 2 selects and 2nd changes value when 1st changes, the 2nd select wouldnt get validated - allowing to call a mutation with invalid data - especially bad if the BE doesnt validate (like in this project's case createSysUser uses SysUserInputUpdate)
      // 2. `validateField(name)` didnt work, regadless of the timeout
      // 3. doesnt work without the `setTimeout` or rather causes even more bugs
      // 4. its better to do this here then on every-single form that has 2 selects depending on each other
      setTimeout(() => {
        validateForm()
      }, 0)
    }

    const inputValue = useMemo(() => {
      return isMulti
        ? options.filter(option => ((field.value || []) as string[]).includes(option.id))
        : options.find(option => option.id === field.value)
    }, [options, field.value])

    return SelectInput({ name: name, value: inputValue, onChange: _onChange })
  }

  return name ? InputSelectFormik() : SelectInput({ value: value, onChange: _onChange })
}

// core
import React from 'react'
// components
import { IDefaultProps, IDefaultWrapperProps } from 'components'

/**
 * ! FOR ITEM VARIANTS ONLY !
 *
 * This interface contains the very basic properties each `Item` in a `List` must contain
 * and is shared among all other items but exclude `children` prop as each of them
 * has a specific, custom-defined children - since they are variants
 *
 * #NOTE: initially i wanted to use only `IItemDefaultProps` but TS throws error for all Item variants that
 * the `children` prop is mandatory but missing, that is due to every Item variant not being a wrapper itself
 * but rather being wrapped in `ItemDefault`
 */
export interface IItemVariantProps extends IDefaultProps {}

/**
 * ! FOR `ItemDefault` ONLY
 *
 * ! DO NOT USE THIS WITH ANY ITEM VARIANTS, USE `IItemVariantProps` INSTEAD
 *
 * This props interface is used only for `ItemDefault`
 */
export interface IItemDefaultProps extends IDefaultWrapperProps {}

export const ItemDefault = ({ children }: IItemDefaultProps) => <>{children}</>

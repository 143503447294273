// core
import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react'
// components
import { IDefaultProps } from 'components'

export const ExpandableBoxContext = createContext<() => void>(() => {
  // do nothing
})

export interface IExpandableBoxProps extends IDefaultProps {
  /**
   * Whether box should be expanded
   */
  expanded: boolean
  /**
   * Use also fade animation
   */
  fade?: boolean
  /**
   * Animate height of children inside
   */
  children?: any
}

export function ExpandableBox({ expanded, fade, children, className }: IExpandableBoxProps) {
  const contentRef = useRef<HTMLDivElement>(null)
  const refreshParent = useContext(ExpandableBoxContext)

  const refresh = useCallback(() => {
    const childHeight = contentRef.current?.children[0].scrollHeight || 0
    const duration = contentRef.current ? 0.2 + contentRef.current.scrollHeight / 5000 : 0.3

    if (contentRef.current) {
      const contentElement = contentRef.current

      contentElement.style.maxHeight = expanded ? `${childHeight}px` : '0'
      contentElement.style.overflow = 'hidden'

      let transition = expanded ? `max-height .3s ease-in` : `max-height .3s ease-out`

      if (fade) {
        contentElement.style.opacity = expanded ? '1' : '0'
        transition += expanded
          ? `, opacity ${duration}s linear ${0.2 * duration}s`
          : `, opacity ${0.8 * duration}s linear`
      }

      contentElement.style.transition = transition
    }

    setTimeout(refreshParent, 350)
  }, [contentRef, expanded, fade, refreshParent])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div ref={contentRef} className={className} data-testid="root">
      <ExpandableBoxContext.Provider value={refresh}>{children}</ExpandableBoxContext.Provider>
    </div>
  )
}

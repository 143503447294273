// core
import { useContext } from 'react'
import { LoggedInUserContext } from 'index'
// API
import { AccessRightsQueries } from 'api/AccessRights/AccessRightsQueries'
import {
  GetAllAccessRightCategories,
  GetAllAccessRightCategoriesVariables,
} from 'api/AccessRights/types/GetAllAccessRightCategories'
import { getClient } from 'api/Api'
import { AuthMutations } from 'api/Auth/AuthMutations'
import { ActionsTargetEnum } from 'api/global-types'
import { SettingsQueries } from 'api/Settings/SettingsQueries'
import { TranslationQueries } from 'api/Translations/TranslationQueries'
// libraries
import { useQuery } from '@apollo/client'
import * as jwt from 'jsonwebtoken'
// utils
import { EARC, EStorageKeys } from 'utils'

export const parsePayload = (token: string) => {
  return jwt.decode(token)
}

/**
 * Save token to local storage
 */
export const setNewToken = (token: string) => {
  localStorage.setItem('token', token)
}

/**
 * Login user and store token and email
 */
export const login = async (email: string, password: string): Promise<string> => {
  return (
    getClient()
      .mutate({ mutation: AuthMutations.LOGIN, variables: { email, password } })
      // @ts-ignore      #TODO
      .then(({ data }: { data: { login: string } }) => {
        const token = data.login

        // store data
        setNewToken(token)
        localStorage.setItem('lastAuthEmail', email)

        return token
      })
  )
}

/**
 * Refresh token and store it
 */
export const refreshToken = async () => {
  return (
    getClient()
      .mutate({ mutation: AuthMutations.REFRESH_TOKEN })
      //   @ts-ignore       #TODO
      .then(({ data }: { data: { refreshToken: string } }) => {
        const token = data.refreshToken

        setNewToken(token)

        return token
      })
  )
}

/**
 * Remove token and email from local storage
 */
export const cleanLocalStorage = (rememberEmail = false) => {
  localStorage.removeItem('token')

  if (!rememberEmail) {
    localStorage.removeItem('lastAuthEmail')
  }

  Object.keys(localStorage)
    .filter(
      key => key.startsWith('sort-') || key.startsWith('filter-') || key.startsWith('search-')
    )
    .forEach(key => localStorage.removeItem(key))
}

/**
 * Remove token and all data from local storage
 */
export const logout = async (rememberEmail = false): Promise<any> => {
  localStorage.setItem(EStorageKeys.LAST_LOGIN, new Date().toDateString())

  const disableNotificationsPromise =
    //   localStorage.getItem('token')
    //     ? disableNotifications()
    //     :
    Promise.resolve()

  return disableNotificationsPromise.then(async () => {
    cleanLocalStorage(rememberEmail)

    const client = getClient()

    if (!client) {
      return Promise.resolve()
    }

    let settings: any
    let translations: any

    try {
      settings = client.readQuery({ query: SettingsQueries.GET_ALL_SETTINGS })
      translations = client.readQuery({ query: TranslationQueries.GET_TRANSLATIONS })
    } catch {
      // do nothing
    }

    return client.clearStore().then(() => {
      client.writeQuery({ data: settings, query: SettingsQueries.GET_ALL_SETTINGS })
      client.writeQuery({ data: translations, query: TranslationQueries.GET_TRANSLATIONS })
      client.reFetchObservableQueries()
    })
  })
}

/**
 * Custom hook that retrieves permission based on provided category and action
 * @param category Access Right Category (former module)
 * @param action action to ask permissions for
 * @returns [boolean, boolean]
 */
export function usePermission(category: EARC, action: string): [boolean, boolean] {
  const { loggedInUser } = useContext(LoggedInUserContext)

  const { data, loading, error } = useQuery<
    GetAllAccessRightCategories,
    GetAllAccessRightCategoriesVariables
  >(AccessRightsQueries.GET_ALL_ACCESS_RIGHT_CATEGORIES, {
    skip: !loggedInUser?.id,
    variables: {
      forType: ActionsTargetEnum.SYS_USER,
      forId: loggedInUser?.id || '',
    },
  })

  const categories = data?.sysAccessRightCategories

  if (!loggedInUser || !categories || error) {
    return [false, true]
  }

  const _category = categories.find(({ alias }) => alias === category)
  const _action = _category?.extendedActions.find(({ alias }) => alias === action)
  // #TODO: add the ability to "extract" individual permissions (create, update, delete) from 'manage' perm. ...cuz asking for `create` perm. when only `manage` is specified in DB will return false

  return [Boolean(_action?.allowed), loading]
}

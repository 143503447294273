import { gql } from '@apollo/client'
import { SettingsFragments } from './SettingsFragments'

export const SettingsQueries = {
  GET_ALL_SETTINGS: gql`
    query GetAllSettings {
      sysSettings {
        ...SettingFragment
      }
    }

    ${SettingsFragments.SETTING}
  `,

  GET_SETTING: gql`
    query GetSetting($id: ID, $key: String) {
      sysSetting(id: $id, key: $key) {
        ...SettingFragment
      }
    }

    ${SettingsFragments.SETTING}
  `,
}

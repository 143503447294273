// core
import React, { ReactNode, useEffect, useState } from 'react'
// components
import { Badge, Button, IDefaultWrapperProps, Scrollable } from 'components'
// libraries
import cx from 'classnames'

export interface ITabProps<TabID> extends Omit<IDefaultWrapperProps, 'id'> {
  /**
   * Generic value for Tab's ID - if the Tabs are used within a column, the value should be the same as <Column<TabID> />
   */
  id: TabID
  /**
   * Classes of the tabs will be turned to dark mode
   *
   * @default 'false'
   */
  isDark?: boolean
  /**
   * Label count which will be displayed next to label
   */
  count?: number
  /**
   * Whether the `Tab` is currently active
   *
   * @warning ! DO NOT SPECIFY IN JSX ! This prop is handled in `<Tabs>` via `React.cloneElement`
   *
   * @default undefined
   */
  isActive?: boolean
  /**
   * Whether the `Tab` is disabled
   *
   * @default undefined
   */
  isDisabled?: boolean
  /**
   * Text to display inside the `Tab`
   *
   * @default undefined
   */
  label?: ReactNode
  /**
   * Event called when the `Tab` is changed (after click on `Tab`)
   *
   * @warning ! DO NOT SPECIFY IN JSX ! This prop is handled in `<Tabs>` via `React.cloneElement`
   *
   * @default undefined
   */
  onChangeTab?(tabId: TabID): void
  /**
   * Event called when `Tab` is clicked
   */
  onClick?: () => void
}

/**
 * Wrapper component for displaying a tab - wrapped in styless `<Button.Wrapper>`
 */
export function Tab<TabID = undefined>({
  className,
  count,
  id,
  isActive,
  isDark = false,
  isDisabled,
  label,
  onChangeTab,
  onClick,
}: ITabProps<TabID>) {
  const _onClick = () => {
    if (isDisabled) return

    onChangeTab?.(id)
    onClick?.()
  }

  const twCSS = (): string => {
    const colorTab = isDark ? 'tabDark' : 'tab'
    const colorTabActive = isDark ? 'tabActiveDark' : 'tabActive'
    const tab = isActive ? colorTabActive : colorTab

    return cx(tab, className, 'overflow-hidden')
  }

  return (
    <Button.Wrapper noStyles className={twCSS()} isDisabled={isDisabled} onClick={_onClick}>
      <span className={cx('flex', !count && 'justify-center')}>
        <p className="truncate">{label}</p>

        {/**@ts-ignore */}
        {Boolean(count) && <Badge color={isActive ? 'primary' : 'grey'} label={count} />}
      </span>
    </Button.Wrapper>
  )
}

//

interface ITabsProps<TabIDs> extends IDefaultWrapperProps {
  /**
   * ID of the currently active `Tab` - also used as its `key`
   *
   * @default undefined
   */
  activeTab?: TabIDs
  /**
   * Collection of JSX.Elements where each MUST be wrapped in `<Tab>`
   */
  children: React.ReactElement<ITabProps<TabIDs>>[]
  /**
   * Content to be rendered on the right side of the header tabs
   */
  contentRight?: ReactNode
  /**
   *
   * @default 'false'
   */
  isDark?: boolean
  /**
   * Event called when a `<Tab>` is clicked, returns its ID
   * @param {TabIDs} tabID ID of the `Tab` user clicked on
   *
   * @default undefined
   */
  onChangeTab?(tabId: TabIDs): void
}

/**
 * Wrapper component for rendering tabs - its children MUST be of type `<Tab>`
 */
export function Tabs<TabIDs = undefined>({
  activeTab,
  children,
  className,
  contentRight,
  isDark = false,
  onChangeTab,
}: ITabsProps<TabIDs>) {
  const [currentTab, setCurrentTab] = useState<TabIDs | undefined>(activeTab)

  useEffect(() => {
    if (activeTab !== currentTab) setCurrentTab(activeTab)
  }, [activeTab])

  const _onChangeTab = (tabId: TabIDs) => () => {
    setCurrentTab(tabId)
    onChangeTab?.(tabId)
  }

  return (
    <div className={isDark ? 'tabsDark' : 'tabs'}>
      {/* TABS HEADER */}
      <div className={cx('tabsHeader', className)}>
        <nav className="flex justify-evenly items-center w-full overflow-x-auto" aria-label="Tabs">
          {children.map((tab, index) =>
            React.cloneElement(tab, {
              ...tab.props,
              key: (tab.props.id || `tab-${index}`) as any,
              isActive: tab.props.id === currentTab,
              onChangeTab: _onChangeTab(tab.props.id),
            })
          )}

          {contentRight}
        </nav>
      </div>

      {/* TABS'S CONTENT */}
      <Scrollable>
        {/* #TODO #DELETE className - later once dark mode is fully resolved on FE */}
        <div className="text-black h-full w-full overflow-auto">
          {children[children.findIndex(child => child.props.id === currentTab)]?.props.children}
        </div>
      </Scrollable>
    </div>
  )
}

// core
import React, { Fragment, MouseEvent, ReactNode } from 'react'
// components
import { Icon, IDefaultProps } from 'components'
// libraries
import cx from 'classnames'
// utils
import { runCallback, stopEvent } from 'utils'

export type TBreadcrumbsItemType = {
  /**
   * Title to display
   */
  title: string | ReactNode
  /**
   * Path - if provided it will render link
   */
  path?: string
  /**
   * Callback to run on item click
   */
  onClick?: () => void
}

export interface IBreadcrumbsProps extends IDefaultProps {
  /**
   * List of items
   */
  items: TBreadcrumbsItemType[]
  /**
   * Custom separator
   */
  separator?: ReactNode
}

export const Breadcrumbs = ({ items, separator, className }: IBreadcrumbsProps) => {
  return (
    <div className={cx('flex items-center h-5 text-black text-sm font-light', className)}>
      {items.map((item, key) => {
        return (
          <Fragment key={typeof item.title === 'string' ? item.title : key}>
            {item.path ? (
              <a href={item.path}>{item.title}</a>
            ) : item.onClick ? (
              <span
                className="cursor-pointer"
                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                  stopEvent(e)

                  runCallback(item.onClick)
                }}>
                {item.title}
              </span>
            ) : (
              <div>{item.title}</div>
            )}

            {key < items.length - 1 ? (
              separator ? (
                <div className="mx-1 text-gray-200">{separator}</div>
              ) : (
                <Icon className="mx-1 text-gray-400" name="chevron-right" size="sm" />
              )
            ) : null}
          </Fragment>
        )
      })}
    </div>
  )
}

import { gql } from '@apollo/client'
import { LanguageFragments } from './LanguageFragments'

export const LanguageQueries = {
  GET_LANGUAGES: gql`
    query GetLanguages {
      sysLanguages(production: true) {
        ...LanguageFragment
      }
    }

    ${LanguageFragments.LANGUAGE}
  `,

  GET_LANGUAGE: gql`
    query GetLanguage($id: ID, $codeShort: String) {
      sysLanguage(id: $id, codeShort: $codeShort) {
        ...LanguageFragment
      }
    }

    ${LanguageFragments.LANGUAGE}
  `,
}

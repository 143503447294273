/* #TODOs:
    - check for date if new translations were added
    - possible improvement - sysTranslation(key: String!): SysTranslation query
*/

// core
import React, { memo, useCallback, useContext, useMemo } from 'react'
import { LoggedInUserContext } from 'index'
// API
import { getClient } from 'api/Api'
import { TranslationMutations } from 'api/Translations/TranslationMutations'
import { TranslationQueries } from 'api/Translations/TranslationQueries'
import {
  CreateTranslationKey,
  CreateTranslationKeyVariables,
} from 'api/Translations/types/CreateTranslationKey'
import { GetSysTranslationsInLanguage_sysTranslationsInLanguage as ITranslationNode } from 'api/Translations/types/GetSysTranslationsInLanguage'
import {
  GetTranslationsInLanguage,
  GetTranslationsInLanguageVariables,
} from 'api/Translations/types/GetTranslationsInLanguage'
// components
import { Icon, IDefaultProps } from 'components'
// libraries
import { useMutation, useQuery } from '@apollo/client'
import cx from 'classnames'
import DOMPurify from 'dompurify'
import Handlebars from 'handlebars'
// layouts
import { TranslationContext } from 'layouts'
// utils
import {
  EARC,
  EStorageKeys,
  IObject,
  TranslationKeys,
  useConsoleErrors,
  usePermission,
} from 'utils'

const HandlebarsIntl = require('handlebars-intl')

HandlebarsIntl.registerWith(Handlebars)
DOMPurify.setConfig({ ADD_ATTR: ['target'] })

export type TTranslationVariables = IObject

interface ITranslationProps extends IDefaultProps {
  /**
   * The translation's key
   */
  keyValue: TranslationKeys
  /**
   * Whether to truncate the translation by adding elipsis
   * @default false
   */
  elipsis?: boolean
  /**
   * Collection of variables used by the translation
   * @default undefined
   */
  variables?: TTranslationVariables
}

export const Translation = memo(function Translation({
  className,
  elipsis,
  keyValue,
  variables,
}: ITranslationProps) {
  // ==================== Context ====================
  const { loggedInUser } = useContext(LoggedInUserContext)
  const { openTranslationDialog } = useContext(TranslationContext)

  // ==================== Permissions ====================
  const canTranslate = usePermission(EARC.TRANSLATIONS, 'translate')[0] && Boolean(loggedInUser)

  // ==================== Query ====================
  const { data, error } = useQuery<GetTranslationsInLanguage, GetTranslationsInLanguageVariables>(
    TranslationQueries.GET_TRANSLATIONS_IN_LANGUAGE,
    {
      variables: {
        sysLanguageId: localStorage.getItem(EStorageKeys.DEFAULT_LANG_ID) || '', // The preffered user's language ID
        fallbackSysLanguageCodeShort: navigator.language.slice(0, 2).toUpperCase(), // The current browser language
      },
    }
  )

  // ==================== Mutations ====================
  const [createKeyWithTranslations] = useMutation<
    CreateTranslationKey,
    CreateTranslationKeyVariables
  >(TranslationMutations.CREATE_TRANSLATION)

  // ==================== Variables ====================
  let translation = data?.sysTranslationsInLanguage.find(({ key }) => key === keyValue)?.translation

  // If a translation wasn't found, create ONLY a new key for it
  if (translation === undefined) {
    translation = keyValue

    createKeyWithTranslations({ variables: { key: keyValue } }).catch(() => {
      // do nothing - let it silently fail
    })
  }

  // Due to previous condition, a new key is created with an empty translation
  // This ensures that a the actual key is rendered instead of '' so it's easily spotted in UI and then translated
  if (translation === '') {
    translation = keyValue
  }

  if (translation !== keyValue && variables) {
    translation = replaceVariables(translation, variables)
  }

  // prevent XSS attacks
  const translationWithHtml = useMemo(() => DOMPurify.sanitize(translation as string), [
    translation,
  ])
  // Allow re-define translation value if it doesn't exist yet or was saved as the key value
  const isMissingTranslation = keyValue === translation

  // ==================== Events ====================
  const onOpenTranslationDialog = useCallback(() => {
    openTranslationDialog(keyValue)
  }, [keyValue, openTranslationDialog])

  useConsoleErrors('Translation', error)

  return (
    <span
      className={cx(
        'relative',
        elipsis && 'truncate',
        isMissingTranslation && 'text-danger',
        className
      )}>
      {/* EXCLEMATION ICON - TOGGLES TRANSLATION DIALOG */}
      {isMissingTranslation && canTranslate && (
        <Icon className="mr-3" name="exclamation-circle" onClick={onOpenTranslationDialog} />
      )}

      {/* ACTUAL TRANSLATION */}
      <span dangerouslySetInnerHTML={{ __html: translationWithHtml }} />
    </span>
  )
})

/**
 * Helper function for retrieving translation based on provided key and it's variables
 * @param keyValue key/alias of a translations
 * @param variables set of dynamic variables used within the translation
 */
export function getTranslation(
  keyValue: TranslationKeys,
  variables?: TTranslationVariables,
  languageId?: string
): string {
  const lastTranslationTimestamp = localStorage.getItem(EStorageKeys.TRANSLATIONS_TIMESTAMP)

  const translationNode = getTranslationInLanguage(keyValue, languageId)

  if (!translationNode) {
    // For DEBUG
    // console.warn('NO TRANSLATION FOUND FOR KEY: ', keyValue)
    return keyValue
  }

  return variables
    ? replaceVariables(translationNode.translation, variables)
    : translationNode.translation
}

/**
 * Retrieves a translation node based on provided key and optional language ID
 * @param keyValue key of translation key
 * @param languageId language ID the translation is in - defaults to language is storage
 * @returns `GetSysTranslationsInLanguage_sysTranslationsInLanguage`
 */
const getTranslationInLanguage = (
  keyValue: TranslationKeys,
  languageId?: string
): ITranslationNode | undefined => {
  const defaultLanguageId = localStorage.getItem(EStorageKeys.DEFAULT_LANG_ID)

  const translationsInLanguage = getClient().readQuery<
    GetTranslationsInLanguage,
    GetTranslationsInLanguageVariables
  >({
    query: TranslationQueries.GET_TRANSLATIONS_IN_LANGUAGE,
    variables: {
      sysLanguageId: languageId || defaultLanguageId, // The langauage ID of translation we're looking for OR the default one from the storage
      fallbackSysLanguageCodeShort: navigator.language.slice(0, 2).toUpperCase(), // The current browser language
    },
  })

  if (!translationsInLanguage) {
    // Create JUST a key in DB
    getClient()
      .mutate<CreateTranslationKey, CreateTranslationKeyVariables>({
        mutation: TranslationMutations.CREATE_TRANSLATION,
        variables: { key: keyValue },
      })
      .catch(() => {
        // do nothing - let it silently fail
      })

    return undefined
  }

  // Retrieve the translation based on the provided key
  const translation = translationsInLanguage.sysTranslationsInLanguage.find(
    ({ key }) => key === keyValue
  )

  return translation
}

const replaceVariables = (translation: string, variables: TTranslationVariables) => {
  const template = Handlebars.compile(translation)
  return template(variables, {
    data: {
      intl: {
        formats: {
          date: {
            text: {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            },
          },
        },
        locales: 'sk-SK',
      },
    },
  })
}

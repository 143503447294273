// core
import React, { ReactNode } from 'react'
// components
import { Tag } from 'components'

export interface IDraftJsData {
  children: ReactNode
  contentState: {
    getEntity: (
      key: string
    ) => {
      data: { action: string; title: string; parameter: string }
      getData: () => { url: string }
    }
  }
  entityKey: string
}

export interface IWysiwygActionProps {
  /**
   * data from draftJs
   */
  data: IDraftJsData
}

export const WysiwygAction = ({ data }: IWysiwygActionProps) => {
  const { action, title } = data.contentState.getEntity(data.entityKey).data

  return (
    <Tag
      className="inline-flex align-bottom w-max"
      color="success"
      label={`${title} | ${action}`}
    />
  )
}

export const WysiwygLink = ({ children, contentState, entityKey }: IDraftJsData) => {
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <a
      className="text-primary underline visited:text-primary"
      href={url}
      rel="noopener noreferrer"
      target="_blank">
      {children}
    </a>
  )
}

export interface IWysiwygTagProps {
  /**
   * data from draftJs
   */
  data: IDraftJsData
  /**
   * function to open popup and set entity key
   */
  onSetEntityKey: (key: string) => void
}

export const WysiwygTag = ({ data, onSetEntityKey }: IWysiwygTagProps) => {
  const { parameter } = data.contentState.getEntity(data.entityKey).data

  return (
    <Tag
      className="inline-flex align-middle w-max"
      color="primary"
      label={
        <span>
          {data.children} {parameter ? ` | ${parameter}` : ''}
        </span>
      }
      onClick={() => onSetEntityKey(data.entityKey)}
    />
  )
}

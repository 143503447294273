// core
import React from 'react'
// components
import { Icon, TIconName, TKey } from 'components'
import { IItemVariantProps, ItemDefault } from './ItemDefault'
// libraries
import cx from 'classnames'
// utils
import { stopEvent } from 'utils'

export interface IItemCategoryProps extends IItemVariantProps {
  /**
   * Description of the `Item` - will be displayed under title
   */
  description: string
  /**
   * Icon rendered on the right side of the `Item`
   *
   * @default 'chevron-right'
   */
  icon?: TIconName
  /**
   * Whether the item is active, changes CSS classes
   *
   * @default false
   */
  isActive?: boolean
  /**
   * Text to display as the item content
   */
  title: string
  /**
   * Event called when user clicks the item
   */
  onClick?(id: TKey): void
}

export const ItemCategory = ({
  className,
  description,
  icon = 'chevron-right',
  id,
  isActive,
  title,
  onClick,
}: IItemCategoryProps) => {
  const _onClick = (e: React.MouseEvent) => {
    stopEvent(e)
    onClick?.(id)
  }

  const twCSS = cx(isActive && 'bg-gray-50 dark:bg-black', className)

  return (
    <ItemDefault>
      <div
        className={cx(
          twCSS,
          'flex items-baseline p-6 text-black hover:bg-gray-50 dark:hover:bg-black dark:text-white cursor-pointer'
        )}
        onClick={_onClick}>
        <Icon name={icon} size="xs" />

        <div className="flex flex-col ml-3 text-sm">
          <span className="font-medium text-blue-gray-900">{title}</span>
          <span className="mt-1 text-blue-gray-500">{description}</span>
        </div>
      </div>
    </ItemDefault>
  )
}

// core
import React from 'react'
// components
import { ButtonClose } from './variants/ButtonClose'
import { ButtonDefault, IButtonDefaultProps } from './variants/ButtonDefault'
import { ButtonDelete } from './variants/ButtonDelete'
import { ButtonEdit } from './variants/ButtonEdit'
import { ButtonSubmit } from './variants/ButtonSubmit'
import { ButtonWrapper } from './variants/ButtonWrapper'

export class Button extends React.Component<IButtonDefaultProps> {
  static Close = ButtonClose
  static Delete = ButtonDelete
  static Edit = ButtonEdit
  static Submit = ButtonSubmit
  static Wrapper = ButtonWrapper

  render() {
    return <ButtonDefault {...this.props} />
  }
}
